import React, { createContext, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from 'src/types/user';

type IAuthProvider = {
  children: React.ReactNode;
};

type IAuthContext = {
  user: User | undefined;
  signout: () => void;
  setUser: (user: User) => void;
};

const AuthContext = createContext<IAuthContext | null>(null);

const AuthProvider = ({ children }: IAuthProvider) => {
  const navigate = useNavigate();

  const [user, setUser] = useState<User | undefined>({
    ...JSON.parse(localStorage.getItem('VP_USER') as string),
    rolePermissions: JSON.parse(localStorage.getItem('VP_PERMISSIONS') as string)
  });

  const signout = () => {
    setUser(undefined);
    localStorage.removeItem('VP_PERMISSIONS');
    localStorage.removeItem('VP_USER');
    localStorage.removeItem('VP_TOKEN');
    navigate('/signin', { replace: true });
  };

  const value = useMemo(() => ({ user, setUser, signout }), [user]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => {
  const context = useContext(AuthContext);

  return context;
};
