import styled from 'styled-components';
import { textWeight, FontWeight } from './Text';

type DisplayVariants = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
type IDisplayProps = {
  variant: DisplayVariants;
  mb?: string;
  color?: string;
  weight?: FontWeight;
};

const textVariant = (variant: DisplayVariants): [string, string, boolean] | [string, string] => {
  switch (variant) {
    case (variant = 'xs'):
      return ['1.5rem', '2rem'];
    case (variant = 'sm'):
      return ['1.875rem', '2.375rem'];
    case (variant = 'md'):
      return ['2.25rem', '2.75rem', true];
    case (variant = 'lg'):
      return ['3rem', '3.75rem', true];
    case (variant = 'xl'):
      return ['3.75rem', '4.625rem', true];
    case (variant = '2xl'):
      return ['4.5rem', '5.625rem', true];
    default:
      return ['1rem', '1.5rem'];
  }
};

const Display = styled.p<IDisplayProps>`
  margin-bottom: ${({ mb }) => mb && mb};
  font-weight: ${({ weight }) => weight && textWeight(weight)};
  color: ${({ color }) => (color ? color : `var(--grey-900)`)};
  font-size: ${({ variant }) => textVariant(variant)[0]};
  line-height: ${({ variant }) => textVariant(variant)[1]};
  letter-spacing: ${({ variant }) => textVariant(variant)[2] && '-0.02em'};
`;

export default Display;
