import { CloseIcon } from 'src/assets/svg';
import FlexibleDiv from 'src/components/FlexableDiv';
import StyledOrderCatalogue from './styled';
import { Text } from 'src/components/Typography';
import Button from 'src/components/Button';
import { IOrder, OrderStatus } from 'src/types/conversation';
import { useState } from 'react';
import { updateOrderStatus } from 'src/network/conversations';
import toast from 'src/utils/toasts';
import { ISingleConvo } from 'src/types';

type OrderCatalogueProps = {
  order?: IOrder;
  total?: number;
  dateString?: string;
  onClose: () => void;
  orderHeading: string;
  setSingleConversation: React.Dispatch<React.SetStateAction<ISingleConvo | undefined>>;
  conversationId?: string;
  status?: OrderStatus;
};

const OrderCatalogue = ({
  order,
  onClose,
  dateString,
  total,
  orderHeading,
  setSingleConversation,
  conversationId,
  status
}: OrderCatalogueProps) => {
  const [loading, setLoading] = useState(false);

  const handleUpdateOrder = async (status: 'complete' | 'cancel') => {
    if (order?.orderId && conversationId) {
      setLoading(true);
      try {
        const res = await updateOrderStatus({
          orderId: order?.orderId,
          orderStatus: status,
          messageId: conversationId
        });
        toast('success', `${res?.message}`);
        setSingleConversation((prev) => {
          return prev
            ? {
                ...prev,
                messages: prev?.messages.map((msg) => {
                  if (msg.id === conversationId && msg.catalogueMessage) {
                    const order = JSON.parse(msg.messageBody) as IOrder;
                    const clonedOrder = { ...order };
                    return {
                      ...msg,
                      messageBody: JSON.stringify({ ...clonedOrder, orderStatus: status }),
                      orderStatus: status
                    };
                  }
                  return msg;
                })
              }
            : prev;
        });
        onClose();
      } catch (e) {
        toast('error', `${e}`);
      } finally {
        setLoading(false);
      }
    }
  };
  const handleAcceptOrder = () => handleUpdateOrder('complete');
  const handleCancelOrder = () => handleUpdateOrder('cancel');

  return (
    <StyledOrderCatalogue>
      <FlexibleDiv flexGap="8px" alignItems="center" className="header">
        <div className="close-icon" onClick={onClose}>
          <CloseIcon />
        </div>
        <Text variant="md" className="title">
          Order Summary
        </Text>
      </FlexibleDiv>
      <div className="main">
        <div className="main-inner main-inner-first">
          <FlexibleDiv flexDir="column" flexGap="8px" mb="24px">
            <Text mb="0" variant="md">
              {orderHeading}
            </Text>
            <Text mb="0" variant="md" weight="bold">
              {order?.data.order.length} items
            </Text>
          </FlexibleDiv>
          <FlexibleDiv
            className="product-list"
            flexGap="24px"
            flexDir="column"
            as="ul"
            alignItems="stretch">
            {order?.data.order.map(({ description, id, imageSrc, name, amount, quantity }) => (
              <FlexibleDiv
                justifyContent="space-between"
                flexGap="16px"
                key={id}
                className="product"
                as="li">
                <FlexibleDiv className="product-content-meta" flexGap="8px" alignItems="center">
                  <img src={imageSrc} alt="" />
                  <FlexibleDiv flexDir="column" flexGap="2px">
                    <Text mb="0" variant="sm" color="var(--grey-700)">
                      {name}
                    </Text>
                    <Text mb="0" variant="sm" color="var(--grey-500)">
                      <div dangerouslySetInnerHTML={{ __html: description }} />
                    </Text>
                  </FlexibleDiv>
                </FlexibleDiv>
                <FlexibleDiv
                  className="product-pricing-meta"
                  flexGap="2px"
                  flexDir="column"
                  alignItems="flex-end">
                  <Text mb="0" variant="sm" color="var(--grey-700)">
                    NGN {amount.toLocaleString()}
                  </Text>
                  <Text mb="0" variant="sm" color="var(--grey-500)">
                    Quantity: {quantity}
                  </Text>
                </FlexibleDiv>
              </FlexibleDiv>
            ))}
          </FlexibleDiv>
        </div>
        <div className="main-inner main-inner-second">
          <FlexibleDiv mb="8px" justifyContent="space-between">
            <Text mb="0" variant="sm" color="var(--grey-700)">
              Subtotal
            </Text>
            <Text mb="0" variant="sm" weight="medium" color="var(--grey-700)">
              NGN {total?.toLocaleString()}
            </Text>
          </FlexibleDiv>
          <Text mb="0" variant="sm" color="var(--grey-500)">
            {dateString}
          </Text>
        </div>
        <FlexibleDiv flexGap="12px" alignItems="center">
          <Button
            className="cancel-order order-btn"
            onClick={handleCancelOrder}
            loading={loading}
            disabled={status === 'cancel' || status === 'complete' ? true : loading}>
            Cancel Order
          </Button>
          <Button
            className="accept-order order-btn"
            onClick={handleAcceptOrder}
            loading={loading}
            disabled={status === 'cancel' || status === 'complete' ? true : loading}>
            Accept Order
          </Button>
        </FlexibleDiv>
      </div>
    </StyledOrderCatalogue>
  );
};

export default OrderCatalogue;
