import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Loading = styled.div`
  width: 36px;
  height: 36px;
  margin-top: 12px;
  margin-bottom: 12px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top-color: var(--primary-500);
  animation: ${spin} 0.75s linear infinite;
`;

export default React.memo(Loading);
