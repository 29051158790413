import styled from 'styled-components';

const StyledConvoList = styled.div<{ showCustomerDetails: boolean }>`
  width: ${({ showCustomerDetails }) => (showCustomerDetails ? '368px' : '392px')};
  transition: width 0.35s;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export default StyledConvoList;
