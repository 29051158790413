import styled from 'styled-components';

const ConversationsPageStyles = styled.section`
  display: flex;
  flex-grow: 1;

  .msgs {
    flex-grow: 1;
    height: 100vh;
    display: grid;
    place-content: center;
    letter-spacing: -0.02em;
  }

  .heading {
    font-weight: 500;
    color: var(--grey-700);
    margin-bottom: 8px;
    text-align: center;
  }

  .select-text {
    margin-bottom: 0;
    color: var(--grey-400);
    text-align: center;
  }

  .loading-area {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    border-top: 1px solid var(--grey-200);
    border-right: 1px solid var(--grey-200);
  }
`;

export default ConversationsPageStyles;
