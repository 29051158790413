import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import FacebookLogin from '@greatsumini/react-facebook-login';
import FlexibleDiv from 'src/components/FlexableDiv';
import ActiveBadge from 'src/components/ActiveBadge';
import { Text } from 'src/components/Typography';
import {
  getIntegrationDetails,
  gupshopWhatsApp,
  igIntegration,
  metaIntegration,
  webWidgetIntegration,
  whatsappIntegration,
  WhatsappPayload,
  whatsappRedirectURL
} from 'src/network/integrations';
import toastError from 'src/utils/toastError';
import toast from 'src/utils/toasts';
import { useAuth } from 'src/context/Auth';
import {
  MetaIntegrationDetails,
  WebIntegrationDetails,
  WhatsappIntegrationDetails
} from 'src/types/integrations';
import { Web, IG, Messenger, Telegram, Twitter, Whatsapp } from 'src/assets/svg';
import StyledIntegrations from './styled';
import VPModal from 'src/components/VPModal';
import Button from 'src/components/Button';
import WebIntegrationModal from 'src/components/WebIntegrationModal';
import { Form } from 'antd';
import { FormInput, FormItem } from 'src/components/Form';
import EnterPasswordModal from 'src/components/EnterPasswordModal';
import WhatsAppIntegrationModal from 'src/components/WhatsAppIntegrationModal';

const Integrations = () => {
  const userContext = useAuth();
  let [searchParams, setSearchParams] = useSearchParams();

  const [showWhatsAppModal, setShowWhatsAppModal] = useState(false);
  const [whatsappStep, setWhatsappStep] = useState<1 | 2>(1);
  const [fbBusinessLink, setFBBusinessLink] = useState('');
  const [loading, setLoading] = useState(false);

  const [showPasswordModal, setShowPassWordModal] = useState(false);
  const [showWebIntegrationModal, setShowWebIntegrationModal] = useState(false);

  const [whatsappIntegrationInfo, setWhatsappIntegrationInfo] =
    useState<WhatsappIntegrationDetails>();
  const [fbIntegrationInfo, setFbIntegrationInfo] = useState<MetaIntegrationDetails>();
  const [igIntegrationInfo, setIgIntegrationInfo] = useState<MetaIntegrationDetails>();
  const [webIntegrationInfo, setWebIntegrationInfo] = useState<WebIntegrationDetails>();

  const onIntegrateWhatsApp = async (payload: WhatsappPayload, callback: () => void) => {
    try {
      // const phoneNumberRes = await getWhatsappNumber({
      //   apiKey: values.apiKey
      // });
      const res = await whatsappIntegration(payload);
      toast('success', 'WhatsApp integrated successfully');
    } catch (err) {
      toastError(err);
    }
  };

  const onIntegrateIG = async (accessToken: string, callback: () => void) => {
    if (accessToken) {
      try {
        const res = await igIntegration({ accessToken });
        toast('success', 'Instagram integrated successfully');
        callback();
      } catch (err) {
        toastError(err);
      } finally {
      }
    }
  };

  const onIntegrateFacebook = async (accessToken: string, callback: () => void) => {
    if (accessToken) {
      try {
        const res = await metaIntegration({ accessToken });
        toast('success', 'Facebook integrated successfully');
        callback();
      } catch (err) {
        toastError(err);
      } finally {
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await getIntegrationDetails();

        if (res.data.whatsapp) {
          setWhatsappIntegrationInfo(res.data.whatsapp);
        }
        if (res.data.facebook) {
          setFbIntegrationInfo(res.data.facebook);
        }
        if (res.data.instagram) {
          setIgIntegrationInfo(res.data.instagram);
        }
        if (res.data.web) {
          setWebIntegrationInfo(res.data.web);
        }
      } catch (err) {
        toastError(err);
      }
    })();

    (async () => {
      try {
        const res = await whatsappRedirectURL();
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  // useEffect(() => {
  //   const client = searchParams.get('client');
  //   const channelId = searchParams.get('channels');
  //   if (client && channelId) {
  //     onIntegrateWhatsApp({ client, channelId: channelId.slice(1, -1) }, () => {
  //       setWhatsappIntegrationInfo((prev) => ({ ...prev!, isActive: true }));
  //     });
  //   }
  // }, [searchParams]);

  const submitPassword = async (values: any) => {
    try {
      const { data } = await webWidgetIntegration({ password: values.password });
      setWebIntegrationInfo({
        _id: '',
        businessId: '',
        addedBy: '',
        channel: 'WEB',
        isActive: true,
        apiKey: data.apiKey,
        client: '',
        createdAt: '',
        updatedAt: '',
        __v: 0,
        id: ''
      });
      setShowPassWordModal(false);
      setShowWebIntegrationModal(true);
    } catch (err) {
      // @ts-ignore
      toast('error', err.response.data.message || 'Something went wrong');
    }
  };

  const submitWhatsappAppName = async (values: any) => {
    setLoading(true);
    try {
      const res = await gupshopWhatsApp(values);
      if (res.error) {
        toast('error', res.message);
      } else {
        setFBBusinessLink(res.link || res.data.link);
        setWhatsappStep(2);
      }
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(`<div class="vipi-web-widget" data-vipikey="${webIntegrationInfo?.apiKey}"></div>
<script src="https://vipichat.ams3.cdn.digitaloceanspaces.com/widget/index.js">
</script>`);
    toast('success', 'Copied to clipboard');
  };

  const copyFBBusinessLink = () => {
    navigator.clipboard.writeText(fbBusinessLink);
    toast('success', 'Copied to clipboard');
  };

  return (
    <StyledIntegrations>
      <Text as="h2" variant="lg" weight="medium" mb="4px">
        Integrations
      </Text>
      <Text variant="sm" color="var(--grey-500)" mb="40px">
        Manage your integrations
      </Text>
      <FlexibleDiv wrap="wrap" flexGap="24px">
        {/* WHATSAPP */}
        <div className="integration-channel">
          <div className="main">
            <FlexibleDiv mb="24px" flexGap="12px" alignItems="center">
              <Whatsapp width={48} height={48} />
              <Text as="h3" variant="md" weight="medium" mb="0">
                WhatsApp
              </Text>
              {whatsappIntegrationInfo?.isActive && <ActiveBadge />}
            </FlexibleDiv>
            <Text variant="sm" color="var(--grey-500)" mb="0">
              Streamline software projects, sprints, and bug tracking.
            </Text>
          </div>
          {userContext?.user?.rolePermissions.can_manage_integrations && false ? (
            <div className="integrate active">
              <Text variant="sm" mb="0" weight="medium">
                Channel added
              </Text>
            </div>
          ) : (
            // <button
            //   className="whatsapp-integrate"
            //   onClick={() => {
            //     window.open(
            //       'https://hub.360dialog.com/dashboard/app/5n2lFFPA/permissions',
            //       '_self',
            //       'toolbar=no, menubar=no, width=600, height=900, top=100, left=100'
            //     );
            //   }}>
            //   Integrate
            // </button>
            <button className="whatsapp-integrate" onClick={() => setShowWhatsAppModal(true)}>
              Integrate
            </button>
          )}
        </div>
        {/* INSTAGRAM */}
        <div className="integration-channel">
          <div className="main">
            <FlexibleDiv mb="24px" flexGap="12px" alignItems="center">
              <IG width={48} height={48} />
              <Text as="h3" variant="md" weight="medium" mb="0">
                Instagram
              </Text>
              {igIntegrationInfo?.isActive && <ActiveBadge />}
            </FlexibleDiv>
            <Text variant="sm" color="var(--grey-500)" mb="0">
              Streamline software projects, sprints, and bug tracking.
            </Text>
          </div>
          {userContext?.user?.rolePermissions.can_manage_integrations ? (
            // Integrate Facebook first before integrating Instagram
            // <FacebookLogin
            //   scope={
            //     !fbIntegrationInfo?.isActive
            //       ? 'email pages_show_list pages_messaging pages_read_engagement pages_manage_metadata'
            //       : 'instagram_basic pages_show_list instagram_manage_messages pages_manage_metadata'
            //   }
            //   appId={'383725376718498'}
            //   onSuccess={(response) => {
            //     if (!fbIntegrationInfo?.isActive) {
            //       onIntegrateFacebook(response?.accessToken!, () => {
            //         setFbIntegrationInfo((prev) => ({ ...prev!, isActive: true }));

            //         setTimeout(() => {
            //           if (igRef.current) {
            //             igRef.current.click();
            //           }
            //         }, 1000);
            //       });
            //     } else {
            //       onIntegrateIG(response?.accessToken!, () => {
            //         setIgIntegrationInfo((prev) => ({ ...prev!, isActive: true }));
            //       });
            //     }
            //   }}
            //   onFail={(response) => toast('error', 'Error integrating Instagram')}
            //   children={
            //     <div className="integrate">
            //       <Text variant="sm" mb="0" weight="medium">
            //         {igIntegrationInfo?.isActive ? 'Re-integrate' : 'Integrate'}
            //       </Text>
            //     </div>
            //   }
            // />
            !fbIntegrationInfo?.isActive ? (
              <div className="integrate">
                <Text
                  variant="sm"
                  mb="0"
                  weight="medium"
                  onClick={() => toast('error', 'Please integrate Facebook before Instagram')}>
                  Integrate
                </Text>
              </div>
            ) : (
              <FacebookLogin
                scope="instagram_basic pages_show_list instagram_manage_messages pages_manage_metadata pages_messaging"
                appId={'383725376718498'}
                onSuccess={(response) => {
                  onIntegrateIG(response?.accessToken!, () => {
                    setIgIntegrationInfo((prev) => ({ ...prev!, isActive: true }));
                  });
                }}
                onFail={(response) => toast('error', 'Error integrating Instagram')}
                children={
                  <div className="integrate">
                    <Text variant="sm" mb="0" weight="medium">
                      {igIntegrationInfo?.isActive ? 'Re-integrate' : 'Integrate'}
                    </Text>
                  </div>
                }
              />
            )
          ) : null}
        </div>
        {/* MESSENGER */}
        <div className="integration-channel">
          <div className="main">
            <FlexibleDiv mb="24px" flexGap="12px" alignItems="center">
              <Messenger width={48} height={48} />
              <Text as="h3" variant="md" weight="medium" mb="0">
                Messenger
              </Text>
              {fbIntegrationInfo?.isActive && <ActiveBadge />}
            </FlexibleDiv>
            <Text variant="sm" color="var(--grey-500)" mb="0">
              Streamline software projects, sprints, and bug tracking.
            </Text>
          </div>
          {userContext?.user?.rolePermissions.can_manage_integrations ? (
            <FacebookLogin
              scope="email pages_show_list pages_messaging pages_read_engagement pages_manage_metadata"
              appId={'383725376718498'}
              onSuccess={(response) => {
                onIntegrateFacebook(response?.accessToken!, () => {
                  setFbIntegrationInfo((prev) => ({ ...prev!, isActive: true }));
                });
              }}
              onFail={(response) => toast('error', 'Error integrating Facebook')}
              children={
                <div className="integrate">
                  <Text variant="sm" mb="0" weight="medium">
                    {fbIntegrationInfo?.isActive ? 'Re-integrate' : 'Integrate'}
                  </Text>
                </div>
              }
            />
          ) : null}
        </div>
        {/* WEB */}
        <div className="integration-channel">
          <div className="main">
            <FlexibleDiv mb="24px" flexGap="12px" alignItems="center">
              <Web width={48} height={48} />
              <Text as="h3" variant="md" weight="medium" mb="0">
                Web
              </Text>
              {webIntegrationInfo?.isActive && <ActiveBadge />}
            </FlexibleDiv>
            <Text variant="sm" color="var(--grey-500)" mb="0">
              Streamline software projects, sprints, and bug tracking.
            </Text>
          </div>
          {userContext?.user?.rolePermissions.can_manage_integrations ? (
            <div
              className="integrate"
              onClick={() =>
                webIntegrationInfo?.isActive
                  ? setShowWebIntegrationModal(true)
                  : setShowPassWordModal(true)
              }>
              <Text variant="sm" mb="0" weight="medium">
                {webIntegrationInfo?.isActive ? 'View details' : 'Integrate'}
              </Text>
            </div>
          ) : null}
        </div>
        {/* TWITTER */}
        <div className="integration-channel">
          <div className="main">
            <FlexibleDiv mb="24px" flexGap="12px" alignItems="center">
              <Twitter width={48} height={48} />
              <Text as="h3" variant="md" weight="medium" mb="0">
                Twitter
              </Text>
            </FlexibleDiv>
            <Text variant="sm" color="var(--grey-500)" mb="0">
              Streamline software projects, sprints, and bug tracking.
            </Text>
          </div>
          <div className="integrate disabled">
            <Text variant="sm" mb="0" weight="medium">
              {/* Integrate */}
              Coming Soon
            </Text>
          </div>
        </div>
        {/* TELEGRAM */}
        <div className="integration-channel">
          <div className="main">
            <FlexibleDiv mb="24px" flexGap="12px" alignItems="center">
              <Telegram width={48} height={48} />
              <Text as="h3" variant="md" weight="medium" mb="0">
                Telegram
              </Text>
            </FlexibleDiv>
            <Text variant="sm" color="var(--grey-500)" mb="0">
              Streamline software projects, sprints, and bug tracking.
            </Text>
          </div>
          <div className="integrate disabled">
            <Text variant="sm" mb="0" weight="medium">
              {/* Integrate */}
              Coming Soon
            </Text>
          </div>
        </div>
      </FlexibleDiv>
      <VPModal
        centered
        closable={false}
        className="form-modal"
        visible={showPasswordModal}
        footer={<></>}>
        <EnterPasswordModal>
          <Text variant="lg" weight="medium" mb="4px">
            Confirm access
          </Text>
          <Text variant="md" color="var(--grey-500)" mb="20px">
            For security reasons, please enter your password
          </Text>
          <Form
            name="enterPassword"
            autoComplete="off"
            layout="vertical"
            requiredMark={false}
            onFinish={submitPassword}>
            <FormItem
              mb="20px"
              name="password"
              label={
                <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                  Password
                </Text>
              }
              rules={[{ required: true, message: 'This field is required' }]}>
              <FormInput type="password" />
            </FormItem>
            <FlexibleDiv flexGap="12px">
              <button className="btn close" onClick={() => setShowPassWordModal(false)}>
                Close
              </button>
              <Button htmlType="submit">Continue</Button>
            </FlexibleDiv>
          </Form>
        </EnterPasswordModal>
      </VPModal>
      <VPModal
        centered
        closable={false}
        className="form-modal"
        visible={showWebIntegrationModal}
        footer={<></>}>
        <WebIntegrationModal>
          <Text variant="sm" color="var(--grey-500)">
            Paste the code below towards the end of the HTML {`<body>`} tag.
          </Text>
          <div className="">
            <pre>
              {`<div class="vipi-web-widget" data-vipikey="${webIntegrationInfo?.apiKey}"></div>
<script src="https://vipichat.ams3.cdn.digitaloceanspaces.com/widget/index.js">
</script>`}
            </pre>
          </div>
          <Text variant="xs" color="var(--grey-700)">
            Note: If your website is built using traditional HTML and CSS and you want the web
            widget to be active on all pages, simply repeat this process for each page you wish to
            include it on.
          </Text>
          <FlexibleDiv flexGap="12px">
            <button className="btn close" onClick={() => setShowWebIntegrationModal(false)}>
              Close
            </button>
            <Button onClick={copyToClipboard}>Copy</Button>
          </FlexibleDiv>
        </WebIntegrationModal>
      </VPModal>
      {/* WHATSAPP INTEGRATION MODAL */}
      <VPModal
        centered
        closable={false}
        className="form-modal"
        visible={showWhatsAppModal}
        footer={<></>}>
        <WhatsAppIntegrationModal>
          <div>
            <Text as="span" variant="xs" mb="2px" color="var(--grey-500)">
              STEP {whatsappStep} OF 2
            </Text>
            <Text variant="lg" weight="medium" mb="4px">
              {whatsappStep === 1
                ? 'Integrate WhatsApp'
                : 'Link to the Facebook business registration'}
            </Text>
            <Text variant="sm" color="var(--grey-500)">
              {whatsappStep === 1
                ? 'Setup your WhatsApp business account.'
                : 'Click on the button below to completed your WhatsApp Business Account registration on Facebook and add your WhatsApp Business Phone Number'}
            </Text>
          </div>
          {whatsappStep === 1 && (
            <Form
              name="whatsappAppName"
              autoComplete="off"
              layout="vertical"
              requiredMark={false}
              onFinish={submitWhatsappAppName}>
              <FormItem
                mb="32px"
                name="appName"
                label={
                  <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                    App Name
                  </Text>
                }
                rules={[{ required: true, message: 'This field is required' }]}>
                <FormInput placeholder="Your appname" />
              </FormItem>
              <FlexibleDiv flexGap="12px">
                <button className="btn close" onClick={() => setShowWhatsAppModal(false)}>
                  Cancel
                </button>
                <Button htmlType="submit" loading={loading}>
                  Proceed
                </Button>
              </FlexibleDiv>
            </Form>
          )}
          {whatsappStep === 2 && (
            <>
              <div className="">
                <pre>{fbBusinessLink}</pre>
              </div>
              <FlexibleDiv flexGap="12px">
                <button className="btn close" onClick={() => setShowWhatsAppModal(false)}>
                  Close
                </button>
                <Button onClick={copyFBBusinessLink}>Copy</Button>
              </FlexibleDiv>
            </>
          )}
        </WhatsAppIntegrationModal>
      </VPModal>
    </StyledIntegrations>
  );
};

export default Integrations;
