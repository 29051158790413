import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import { DateTime, Duration, DurationObjectUnits } from 'luxon';
import AuthenticatedLayout from 'src/layout/AuthenticatedLayout';
import FlexibleDiv from 'src/components/FlexableDiv';
import Loading from 'src/components/Loading';
import VPRangePicker from 'src/components/VPRangePicker';
import DisplayPicture from 'src/components/DisplayPicture';
import { Display, Text } from 'src/components/Typography';
import toastError from 'src/utils/toastError';
import { getAllCustomers } from 'src/network/customers';
import { getConversationsTrend, getDashboardAnalytics } from 'src/network/dashboard';
import { ICustomer } from 'src/types/customers';
import { IConversationAnalytics, IChannels, IConvoCountGraph } from 'src/types/dashboard';
import StyledDashboardPage from './styles';
import { IG, Messenger, Web, Whatsapp } from 'src/assets/svg';

const CARDS = [
  'TOTAL CONVERSATIONS',
  'TOTAL MESSAGES',
  'OPENED CONVERSATIONS',
  'CLOSED CONVERSATIONS',
  'AVE. RESPONSE TIME'
];

const CHANNELS: IChannels[] = ['ALL', 'WEB', 'WHATSAPP', 'FACEBOOK', 'INSTAGRAM'];

const DashboardPage = () => {
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState<[string, string]>([
    DateTime.now().minus({ month: 1 }).startOf('day').toISO(),
    DateTime.now().endOf('day').toISO()
  ]);

  const [conversationAnalytics, setConversationAnalytics] = useState<IConversationAnalytics>();
  const [avgTime, setAvgTime] = useState<DurationObjectUnits>({ minutes: 0, seconds: 0 });

  const [graphLoading, setGraphLoading] = useState(true);
  const [activeChannel, setActiveChannel] = useState<IChannels>('ALL');
  const [graphValues, setGraphValues] = useState<IConvoCountGraph[]>();

  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [totalCustomers, setTotalCustomers] = useState(0);

  const ApexChartsOptions: ApexCharts.ApexOptions = {
    chart: {
      type: 'area',
      fontFamily: 'Inter',
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 2
    },
    colors: ['#6F4EFF'],
    fill: {
      gradient: {
        type: 'horizontal',
        shadeIntensity: 0.85,
        opacityFrom: 0,
        opacityTo: 0.5
      }
    },
    grid: {
      borderColor: '#D8DAE6',
      strokeDashArray: 4,
      xaxis: {
        lines: {
          show: true
        }
      }
    },
    xaxis: {
      categories: graphValues?.map((elem) =>
        DateTime.fromFormat(elem._id!, 'dd-MM-yyyy').toFormat('EEE, d MMM')
      ),
      labels: {
        style: {
          colors: 'var(--grey-500)',
          fontSize: '0.75rem'
        }
      }
    },
    yaxis: {
      min: 0,
      forceNiceScale: true,
      labels: {
        style: {
          colors: 'var(--grey-500)',
          fontSize: '0.75rem'
        }
      }
    }
  };

  const series = [
    {
      name: 'Count',
      data: graphValues?.map((elem) => elem.count)!
    }
  ];

  useEffect(() => {
    const getAnalytics = async () => {
      setLoading(true);
      try {
        const [analytics, customers] = await Promise.all([
          getDashboardAnalytics(dateRange),
          getAllCustomers({
            page: 1,
            perPage: 5,
            query: ''
          })
        ]);
        setCustomers(customers.data.data);
        setTotalCustomers(customers.data.total);
        const { topAssignees, ...rest } = analytics;
        setConversationAnalytics({
          ...rest,
          topAssignees: topAssignees
            .filter((elem) => elem._id && elem.conversationAssignedTo.length === 1)
            .slice(0, 5)
        });
        const duration = Duration.fromMillis(rest.averageResponseTime)
          .shiftTo('minutes', 'seconds')
          .toObject();
        setAvgTime(duration);
      } catch (error) {
        toastError(error);
      } finally {
        setLoading(false);
      }
    };
    getAnalytics();
  }, [dateRange]);

  useEffect(() => {
    const getGraphValues = async () => {
      setGraphLoading(true);
      try {
        const graphTrendRes = await getConversationsTrend({
          date: dateRange,
          channel: activeChannel
        });
        setGraphValues(
          graphTrendRes.sort(
            (a, b) =>
              DateTime.fromFormat(a._id!, 'dd-MM-yyyy').toMillis() -
              DateTime.fromFormat(b._id!, 'dd-MM-yyyy').toMillis()
          )
        );
      } catch (error) {
        toastError(error);
      } finally {
        setGraphLoading(false);
      }
    };
    getGraphValues();
  }, [activeChannel, dateRange]);

  return (
    <AuthenticatedLayout>
      <StyledDashboardPage>
        {loading ? (
          <div className="loading-center">
            <Loading />
          </div>
        ) : (
          <>
            <FlexibleDiv justifyContent="space-between" alignItems="center" mb="40px">
              <div>
                <Display as="h1" variant="xs" weight="medium">
                  Dashboard
                </Display>
                <Text variant="md" className="desc">
                  Track your business performance
                </Text>
              </div>
              <VPRangePicker
                value={[
                  dateRange[0] ? DateTime.fromISO(dateRange[0]) : null,
                  dateRange[1] ? DateTime.fromISO(dateRange[1]) : null
                ]}
                ranges={{
                  Today: [DateTime.now().startOf('day'), DateTime.now().endOf('day')],
                  'This Week': [
                    DateTime.now().startOf('week').startOf('day'),
                    DateTime.now().endOf('week').endOf('day')
                  ],
                  'This Month': [
                    DateTime.now().startOf('month').startOf('day'),
                    DateTime.now().endOf('month').endOf('day')
                  ],
                  'This Year': [
                    DateTime.now().startOf('year').startOf('day'),
                    DateTime.now().endOf('year').endOf('day')
                  ]
                }}
                onChange={(dates) => {
                  if (dates === null) {
                    setDateRange(['', '']);
                  } else {
                    // @ts-ignore
                    setDateRange([dates[0].startOf('day').toISO(), dates[1]?.endOf('day').toISO()]);
                  }
                }}
              />
            </FlexibleDiv>
            <FlexibleDiv wrap="wrap" flexGap="16px" alignItems="stretch" mb="40px">
              {CARDS.map((card, i) => (
                <div className="card" key={card}>
                  <h2 className="card-title">{card}</h2>
                  <p className="card-value">
                    {i === 0 && conversationAnalytics?.totalConversations}
                    {i === 1 && conversationAnalytics?.totalMessages}
                    {i === 2 && conversationAnalytics?.openConversations}
                    {i === 3 && conversationAnalytics?.closedConversations}
                    {i === 4 && `${avgTime.minutes}min ${avgTime.seconds?.toFixed(1)}s`}
                  </p>
                </div>
              ))}
            </FlexibleDiv>
            <FlexibleDiv flexGap="24px" mb="21px">
              <section className="chart-section">
                <div className="conversation-channel">
                  <Text as="h2" variant="md" mb="10px">
                    Conversations by channel
                  </Text>
                  <div className="filter">
                    {CHANNELS.map((elem) => (
                      <div
                        key={elem}
                        onClick={() => setActiveChannel(elem)}
                        className={elem === activeChannel ? 'active-tab' : 'tab-item'}>
                        <span>{elem.toLowerCase()}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <ReactApexChart
                    type="area"
                    options={ApexChartsOptions}
                    series={series}
                    height={350}
                  />
                </div>
              </section>
              <section className="top-agents">
                <div className="title">
                  <Text as="h2" variant="md">
                    Top agents by conversations
                  </Text>
                </div>
                <div className="agents-list">
                  {conversationAnalytics?.topAssignees.map((agent, i) => (
                    <div>
                      <DisplayPicture>{`${agent.conversationAssignedTo[0]?.firstname.charAt(
                        0
                      )}${agent.conversationAssignedTo[0]?.lastname.charAt(0)}`}</DisplayPicture>
                      <div>
                        <p>{`${agent.conversationAssignedTo[0]?.firstname} ${agent.conversationAssignedTo[0]?.lastname}`}</p>
                        <span>
                          {agent.conversationsCount} conversation
                          {agent.conversationsCount > 1 ? 's' : ''}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            </FlexibleDiv>
            <FlexibleDiv flexGap="24px" mb="21px">
              <section className="top-agents">
                <div className="title has-pill">
                  <Text as="h2" variant="md">
                    Customers
                  </Text>
                  <Text as="span" variant="xs">
                    {totalCustomers} customer(s)
                  </Text>
                </div>
                <div className="agents-list">
                  {customers.map((customer) => (
                    <div>
                      {customer.channel === 'WHATSAPP' && <Whatsapp width={36} height={36} />}
                      {customer.channel === 'INSTAGRAM' && <IG width={36} height={36} />}
                      {customer.channel === 'FACEBOOK' && <Messenger width={36} height={36} />}
                      {customer.channel === 'WEB' && <Web width={36} height={36} />}
                      <div>
                        <p>{customer.fullname.replace('undefined', '')}</p>
                        <span>{customer.platformIdentifier}</span>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="customers-link">
                  <Text as={Link} to="/customers" variant="sm">
                    See all customers
                  </Text>
                </div>
              </section>
              <section className="chart-section"></section>
            </FlexibleDiv>
          </>
        )}
      </StyledDashboardPage>
    </AuthenticatedLayout>
  );
};

export default DashboardPage;
