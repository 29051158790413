import React from 'react';
import styled from 'styled-components';

type StatusProps = {
  status: string;
};

const TemplateStatus = ({ status }: StatusProps) => {
  return (
    <StyledTemplateStatus status={status}>
      <span className="circle"></span>
      {status}
    </StyledTemplateStatus>
  );
};

export default TemplateStatus;

const StyledTemplateStatus = styled.span<{
  status: string;
}>`
  padding: 2px 8px;
  border-radius: 16px;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  text-transform: capitalize;

  background-color: ${({ status }) =>
    status === 'rejected'
      ? 'var(--error-50)'
      : status === 'approved'
      ? 'var(--success-50)'
      : 'var(--grey-100)'};
  color: ${({ status }) =>
    status === 'rejected'
      ? 'var(--error-700)'
      : status === 'approved'
      ? 'var(--success-700)'
      : 'var(--grey-700)'};

  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${({ status }) =>
      status === 'rejected'
        ? 'var(--error-500)'
        : status === 'approved'
        ? 'var(--success-500)'
        : 'var(--grey-500)'};
  }
`;
