import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form } from 'antd';
import FlexibleDiv from 'src/components/FlexableDiv';
import { FormInput, FormItem } from 'src/components/Form';
import { Text } from 'src/components/Typography';
import Button from 'src/components/Button';
import FormTextArea from 'src/components/Form/TextArea';
import VPSwitch from 'src/components/VPSwitch';
import { Chevron } from 'src/assets/svg';
import StyledAddRoles from './styled';
import { useAuth } from 'src/context/Auth';
import { CreateRolePayload } from 'src/types/roles';
import toast from 'src/utils/toasts';
import toastError from 'src/utils/toastError';
import { createRole } from 'src/network/roles';

const ADD_ROLE_INIT_VALUES = {
  roleName: '',
  description: '',
  conversationPermissions: {
    canView: false,
    canChatShare: false
  },
  assignPermissions: {
    canViewAssigned: false,
    canAssignAgents: false,
    canAssignSelf: false
  },
  customerPermissions: {
    canViewCustomers: false,
    canUpdate: false
  },
  teamPermissions: {
    canViewTeam: false,
    canAddTeam: false,
    canUpdateTeam: false,
    canDeleteTeam: false
  },
  integrationPermissions: {
    canViewIntegrations: false,
    canManageIntegrations: false
  },
  rolePermissions: {
    canViewRole: false,
    canCreateRole: false,
    canUpdateRole: false
  },
  activityPermissions: {
    canViewActivityLogs: false
  }
};

const AddRolePage = () => {
  const [addRoleSaving, setAddRoleSaving] = useState(false);
  const navigate = useNavigate();
  const userContext = useAuth();

  const onFinishAddRole = async (values: typeof ADD_ROLE_INIT_VALUES) => {
    const payload: CreateRolePayload = {
      roleName: values.roleName,
      roleDescription: values.description,
      can_view_conversations: values.conversationPermissions.canView,
      can_chat_and_share_files_with_customers: values.conversationPermissions.canChatShare,
      can_view_unassigned_chats: values.assignPermissions.canViewAssigned,
      can_assign_chats_to_others: values.assignPermissions.canAssignAgents,
      can_assign_chats_to_self: values.assignPermissions.canAssignSelf,
      can_view_customers: values.customerPermissions.canViewCustomers,
      can_update_customer_details: values.customerPermissions.canUpdate,
      can_view_team_members: values.teamPermissions.canViewTeam,
      can_add_team_members: values.teamPermissions.canAddTeam,
      can_update_team_members_details: values.teamPermissions.canUpdateTeam,
      can_delete_team_members: values.teamPermissions.canDeleteTeam,
      can_view_roles: values.rolePermissions.canViewRole,
      can_create_roles: values.rolePermissions.canCreateRole,
      can_update_roles: values.rolePermissions.canUpdateRole,
      can_view_integrations: values.integrationPermissions.canViewIntegrations,
      can_manage_integrations: values.integrationPermissions.canManageIntegrations,
      can_view_activity_logs: values.activityPermissions.canViewActivityLogs,
      can_view_canned_messages: false,
      can_create_canned_messages: false,
      can_update_canned_messages: false
    };

    try {
      setAddRoleSaving(true);
      const response = await createRole(payload);
      setAddRoleSaving(false);
      toast('success', `${payload.roleName} role created successfully`);
      navigate('/settings?tab=roles');
    } catch (err) {
      setAddRoleSaving(false);
      toastError(err);
    }
  };

  return (
    <StyledAddRoles>
      <Link to="/settings?tab=roles">
        <Text as="span" variant="xs" className="back">
          <Chevron /> Roles
        </Text>
      </Link>
      <Text as="h1" variant="lg" weight="medium" mb="32px" className="title">
        Add new role
      </Text>
      <Form
        name="add-role"
        colon={false}
        autoComplete="off"
        requiredMark={false}
        onFinish={onFinishAddRole}
        initialValues={ADD_ROLE_INIT_VALUES}>
        <FlexibleDiv justifyContent="space-between" mb="36px">
          <div>
            <Text as="h2" variant="lg" weight="medium" mb="4px">
              Role details
            </Text>
            <Text variant="sm" color="var(--grey-500)" mb="0">
              Update the details of this role here.
            </Text>
          </div>
          <FlexibleDiv alignItems="center" flexGap="12px">
            {/* <button className="btn cancel">Cancel</button> */}
            <Button className="btn" htmlType="submit" loading={addRoleSaving}>
              {addRoleSaving ? 'Saving' : 'Save'}
            </Button>
          </FlexibleDiv>
        </FlexibleDiv>
        <section className="role-details">
          <FormItem
            name="roleName"
            className="formItem"
            labelAlign="left"
            labelCol={{ span: 6 }}
            label={
              <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                Name of role
              </Text>
            }
            rules={[{ required: true, message: 'This field is required' }]}>
            <FormInput className="input" placeholder="e.g, IT specialist" />
          </FormItem>
          <FormItem
            name="description"
            labelAlign="left"
            labelCol={{ span: 6 }}
            className="formItem description"
            label={
              <div style={{ display: 'block' }}>
                <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                  Role description
                </Text>
                <Text as="span" variant="sm" color="var(--grey-500)">
                  Describe the role
                </Text>
              </div>
            }
            rules={[{ required: true, message: 'This field is required' }]}>
            <FormInput
              as={FormTextArea}
              className="input"
              autoSize={{ minRows: 5 }}
              placeholder="Please enter a description"
            />
          </FormItem>
        </section>
        <div>
          <Text as="h2" variant="lg" weight="medium" mb="4px">
            Assign permissions
          </Text>
          <Text variant="sm" color="var(--grey-500)" mb="40px">
            Assign the permissions and access for team members with this role.
          </Text>
        </div>
        <section className="assign-perm">
          <FormItem
            labelAlign="left"
            labelCol={{ span: 6 }}
            className="assign-list"
            label={
              <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                Conversation permissions
              </Text>
            }>
            <>
              <FlexibleDiv justifyContent="space-between" className="contain">
                <label htmlFor="canView">Can view conversations</label>
                <FormItem
                  noStyle
                  name={['conversationPermissions', 'canView']}
                  rules={[{ required: true, message: 'This field is required' }]}>
                  <VPSwitch id="canView" />
                </FormItem>
              </FlexibleDiv>
              <FlexibleDiv justifyContent="space-between" className="contain">
                <label htmlFor="canChat">Can chat and share files with customers</label>
                <FormItem
                  noStyle
                  name={['conversationPermissions', 'canChatShare']}
                  rules={[{ required: true, message: 'This field is required' }]}>
                  <VPSwitch id="canChat" />
                </FormItem>
              </FlexibleDiv>
            </>
          </FormItem>
          <FormItem
            labelAlign="left"
            labelCol={{ span: 6 }}
            className="assign-list"
            label={
              <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                Assign permissions
              </Text>
            }>
            <>
              <FlexibleDiv justifyContent="space-between" className="contain">
                <label htmlFor="canViewAssigned">Can view unassigned conversations</label>
                <FormItem
                  noStyle
                  name={['assignPermissions', 'canViewAssigned']}
                  rules={[{ required: true, message: 'This field is required' }]}>
                  <VPSwitch id="canViewAssigned" />
                </FormItem>
              </FlexibleDiv>
              <FlexibleDiv justifyContent="space-between" className="contain">
                <label htmlFor="canAssignAgents">Can assign chats to others</label>
                <FormItem
                  noStyle
                  name={['assignPermissions', 'canAssignAgents']}
                  rules={[{ required: true, message: 'This field is required' }]}>
                  <VPSwitch id="canAssignAgents" />
                </FormItem>
              </FlexibleDiv>
              <FlexibleDiv justifyContent="space-between" className="contain">
                <label htmlFor="canAssignSelf">Can assign chats to self</label>
                <FormItem
                  noStyle
                  name={['assignPermissions', 'canAssignSelf']}
                  rules={[{ required: true, message: 'This field is required' }]}>
                  <VPSwitch id="canAssignSelf" />
                </FormItem>
              </FlexibleDiv>
            </>
          </FormItem>
          <FormItem
            labelAlign="left"
            labelCol={{ span: 6 }}
            className="assign-list"
            label={
              <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                Customer permissions
              </Text>
            }>
            <>
              <FlexibleDiv justifyContent="space-between" className="contain">
                <label htmlFor="canViewCustomers">Can view customers</label>
                <FormItem
                  noStyle
                  name={['customerPermissions', 'canViewCustomers']}
                  rules={[{ required: true, message: 'This field is required' }]}>
                  <VPSwitch id="canViewCustomers" />
                </FormItem>
              </FlexibleDiv>
              <FlexibleDiv justifyContent="space-between" className="contain">
                <label htmlFor="canUpdate">Can update customer details</label>
                <FormItem
                  noStyle
                  name={['customerPermissions', 'canUpdate']}
                  rules={[{ required: true, message: 'This field is required' }]}>
                  <VPSwitch id="canUpdate" />
                </FormItem>
              </FlexibleDiv>
            </>
          </FormItem>
          <FormItem
            labelAlign="left"
            labelCol={{ span: 6 }}
            className="assign-list"
            label={
              <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                Team permissions
              </Text>
            }>
            <>
              <FlexibleDiv justifyContent="space-between" className="contain">
                <label htmlFor="canViewTeam">Can view team members</label>
                <FormItem
                  noStyle
                  name={['teamPermissions', 'canViewTeam']}
                  rules={[{ required: true, message: 'This field is required' }]}>
                  <VPSwitch id="canViewTeam" />
                </FormItem>
              </FlexibleDiv>
              <FlexibleDiv justifyContent="space-between" className="contain">
                <label htmlFor="canAddTeam">Can add team members</label>
                <FormItem
                  noStyle
                  name={['teamPermissions', 'canAddTeam']}
                  rules={[{ required: true, message: 'This field is required' }]}>
                  <VPSwitch id="canAddTeam" />
                </FormItem>
              </FlexibleDiv>
              <FlexibleDiv justifyContent="space-between" className="contain">
                <label htmlFor="canUpdateTeam">Can update team members details</label>
                <FormItem
                  noStyle
                  name={['teamPermissions', 'canUpdateTeam']}
                  rules={[{ required: true, message: 'This field is required' }]}>
                  <VPSwitch id="canUpdateTeam" />
                </FormItem>
              </FlexibleDiv>
              <FlexibleDiv justifyContent="space-between" className="contain">
                <label htmlFor="canDeleteTeam">Can delete team members</label>
                <FormItem
                  noStyle
                  name={['teamPermissions', 'canDeleteTeam']}
                  rules={[{ required: true, message: 'This field is required' }]}>
                  <VPSwitch id="canDeleteTeam" />
                </FormItem>
              </FlexibleDiv>
            </>
          </FormItem>
          <FormItem
            labelAlign="left"
            labelCol={{ span: 6 }}
            className="assign-list"
            label={
              <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                Role permissions
              </Text>
            }>
            <>
              <FlexibleDiv justifyContent="space-between" className="contain">
                <label htmlFor="canViewRole">Can view roles</label>
                <FormItem
                  noStyle
                  name={['rolePermissions', 'canViewRole']}
                  rules={[{ required: true, message: 'This field is required' }]}>
                  <VPSwitch id="canViewRole" />
                </FormItem>
              </FlexibleDiv>
              <FlexibleDiv justifyContent="space-between" className="contain">
                <label htmlFor="canCreateRole">Can create roles</label>
                <FormItem
                  noStyle
                  name={['rolePermissions', 'canCreateRole']}
                  rules={[{ required: true, message: 'This field is required' }]}>
                  <VPSwitch id="canCreateRole" />
                </FormItem>
              </FlexibleDiv>
              <FlexibleDiv justifyContent="space-between" className="contain">
                <label htmlFor="canUpdateRole">Can update roles</label>
                <FormItem
                  noStyle
                  name={['rolePermissions', 'canUpdateRole']}
                  rules={[{ required: true, message: 'This field is required' }]}>
                  <VPSwitch id="canUpdateRole" />
                </FormItem>
              </FlexibleDiv>
            </>
          </FormItem>
          <FormItem
            labelAlign="left"
            labelCol={{ span: 6 }}
            className="assign-list"
            label={
              <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                Integration permissions
              </Text>
            }>
            <>
              <FlexibleDiv justifyContent="space-between" className="contain">
                <label htmlFor="canViewIntegrations">Can view integrations</label>
                <FormItem
                  noStyle
                  name={['integrationPermissions', 'canViewIntegrations']}
                  rules={[{ required: true, message: 'This field is required' }]}>
                  <VPSwitch id="canViewIntegrations" />
                </FormItem>
              </FlexibleDiv>
              <FlexibleDiv justifyContent="space-between" className="contain">
                <label htmlFor="canManageIntegrations">Can manage integrations</label>
                <FormItem
                  noStyle
                  name={['integrationPermissions', 'canManageIntegrations']}
                  rules={[{ required: true, message: 'This field is required' }]}>
                  <VPSwitch id="canManageIntegrations" />
                </FormItem>
              </FlexibleDiv>
            </>
          </FormItem>
          <FormItem
            labelAlign="left"
            labelCol={{ span: 6 }}
            className="assign-list"
            label={
              <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                Activity log permissions
              </Text>
            }>
            <>
              <FlexibleDiv justifyContent="space-between" className="contain">
                <label htmlFor="canViewActivityLogs">Can view activity logs</label>
                <FormItem
                  noStyle
                  name={['activityPermissions', 'canViewActivityLogs']}
                  rules={[{ required: true, message: 'This field is required' }]}>
                  <VPSwitch id="canViewActivityLogs" />
                </FormItem>
              </FlexibleDiv>
            </>
          </FormItem>
        </section>
      </Form>
    </StyledAddRoles>
  );
};

export default AddRolePage;
