import styled from 'styled-components';

const StyledIntegrations = styled.section`
  .integration-channel {
    flex: 1 0 31%;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 1px 3px #10182819, 0px 1px 2px rgba(16, 24, 40, 0.06);

    & button {
      width: 100%;
      border: none;
      background: none;
    }

    .main {
      padding: 24px;
    }

    .whatsapp-integrate {
      text-align: center;
      padding: 16px;
      cursor: pointer;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.25rem;
      color: var(--primary-700);
      border-top: 1px solid var(--grey-200);

      &:hover {
        background: var(--primary-50);
      }
    }

    .integrate {
      text-align: center;
      padding: 16px;
      cursor: pointer;
      border-top: 1px solid var(--grey-200);

      &.active {
        cursor: default;

        p {
          color: var(--primary-400);
        }
      }

      &.disabled p {
        color: var(--grey-500);
      }

      p {
        color: var(--primary-700);
      }

      &:hover {
        background: var(--primary-50);
      }
    }
  }

  .hiddenIg {
    display: none;
  }
`;

export default StyledIntegrations;
