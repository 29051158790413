import { createContext } from 'react';
import { io } from 'socket.io-client';
const SOCKET_SERVER = process.env.REACT_APP_SOCKET_URL as string;

const AUTH_TOKEN = localStorage.getItem('VP_TOKEN') || null;

const socket = io(SOCKET_SERVER, {
  // query: { authorization: `Bearer ${AUTH_TOKEN}` },
  auth: { Authorization: `Bearer ${AUTH_TOKEN}` }
});

socket.on('connect', () => {
  socket.emit('authenticate', { token: AUTH_TOKEN }).on('authenticated', () => {});
});

socket.on('disconnect', () => {});

const SocketContext = createContext<any | null>(null);
type ISocketProvider = {
  children: React.ReactNode;
};

const SocketContextProvider = ({ children }: ISocketProvider) => {
  return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>;
};

export { SocketContext, SocketContextProvider };
