import { IChannels, IConversationAnalytics, IConvoCountGraph } from 'src/types/dashboard';
import gatewayInstance from './axiosGateway';

type IConversationTrend = {
  channel: IChannels;
  date: [string, string];
};

export const getDashboardAnalytics = async (date: [string, string]) => {
  const { data } = await gatewayInstance.get('/conversations/analytics/data', {
    params: {
      startDate: date[0],
      endDate: date[1]
    }
  });

  return data.data as IConversationAnalytics;
};

export const getConversationsTrend = async (params: IConversationTrend) => {
  const { data } = await gatewayInstance.get('/conversations/analytics/conversation-per-day', {
    params: {
      channel: params.channel,
      startDate: params.date[0],
      endDate: params.date[1]
    }
  });

  return data.data as IConvoCountGraph[];
};
