import axios from 'axios';

const gatewayInstance = axios.create({
  baseURL: process.env.REACT_APP_VIPI_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});

gatewayInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('VP_TOKEN');
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // if (error.response && error.response.status === 401) {
    //   // console.log("You're not authorized to access this page");
    //   localStorage.setItem('VP_USER', JSON.stringify(null));
    //   localStorage.setItem('VP_TOKEN', JSON.stringify(null));
    //   localStorage.setItem('VP_PERMISSIONS', JSON.stringify(null));
    //   window.location.href = '/login';
    //   return Promise.reject(error);
    // }
    return Promise.reject(error);
  }
);

gatewayInstance.interceptors.request.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("You're not authorized to access this page");
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('VP_PERMISSIONS');
      localStorage.removeItem('VP_USER');
      localStorage.removeItem('VP_TOKEN');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);
export default gatewayInstance;
