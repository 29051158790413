import React from 'react';
import styled from 'styled-components';
import { IWalletStatus } from 'src/types/wallet';

type StatusProps = {
  status: IWalletStatus;
};

const WalletStatus = ({ status }: StatusProps) => {
  return (
    <StyledWalletStatus status={status}>
      <span className="circle"></span>
      {status}
    </StyledWalletStatus>
  );
};

export default WalletStatus;

const StyledWalletStatus = styled.span<{
  status: IWalletStatus;
}>`
  padding: 2px 8px;
  border-radius: 16px;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  text-transform: capitalize;
  background-color: ${({ status }) =>
    status === ('cancelled' || 'failed')
      ? 'var(--error-50)'
      : status === 'successful'
      ? 'var(--success-50)'
      : 'var(--grey-100)'};
  color: ${({ status }) =>
    status === ('cancelled' || 'failed')
      ? 'var(--error-700)'
      : status === 'successful'
      ? 'var(--success-700)'
      : 'var(--grey-700)'};

  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${({ status }) =>
      status === ('cancelled' || 'failed')
        ? 'var(--error-500)'
        : status === 'successful'
        ? 'var(--success-500)'
        : 'var(--grey-500)'};
  }
`;
