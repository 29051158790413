import React, { useState, useContext, useEffect } from 'react';
import Button from 'src/components/Button';
import { Text } from 'src/components/Typography';
import useDebounce from 'src/hooks/useDebounce';
import { SocketContext } from 'src/context/socketContext';
import { getAgentsforAssign } from 'src/network/conversations';
import { AgentWithConvo } from 'src/types/conversation';
import toastError from 'src/utils/toastError';
import toast from 'src/utils/toasts';
import AssignList from '../AssignList';
import FlexibleDiv from '../FlexableDiv';
import { FormInput } from '../Form';
import StyledAssignedModal from './styles';

type IAssignConvoModal = {
  conversationId: string;
  setShowAssignConversationModal: (value: boolean) => void;
};

const AssignConvoModal = ({
  conversationId,
  setShowAssignConversationModal
}: IAssignConvoModal) => {
  const [selectedAgent, setSelectedAgent] = useState('');
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const [agentsForAssign, setAgentsForAssign] = useState<AgentWithConvo[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await getAgentsforAssign(debouncedSearch.trim());
        setAgentsForAssign(res);
      } catch (err) {
        toastError(err);
      }
    })();
  }, [debouncedSearch]);

  const { socket } = useContext(SocketContext);

  return (
    <StyledAssignedModal>
      <Text variant="lg" weight="medium" mb="8px">
        Assign conversation
      </Text>
      <Text variant="sm" color="var(--grey-500)" mb="20px">
        Choose an agent to assign this conversation
      </Text>
      <form className="search">
        <FormInput placeholder="Search..." onChange={(e) => setSearch(e.currentTarget.value)} />
      </form>
      <section className="agents-list">
        {agentsForAssign?.map((elem) => (
          <AssignList
            key={elem._id}
            item={elem}
            onClick={() => {
              if (selectedAgent === elem._id) {
                setSelectedAgent('');
              } else {
                setSelectedAgent(elem._id);
              }
            }}
            selectedAgent={selectedAgent}
          />
        ))}
      </section>
      <FlexibleDiv flexGap="12px" alignItems="center">
        <button
          className="btn cancel"
          onClick={() => {
            setShowAssignConversationModal(false);
            setSelectedAgent('');
          }}>
          Cancel
        </button>
        <Button
          htmlType="submit"
          className="btn submit"
          loading={loading}
          disabled={!selectedAgent}
          onClick={async () => {
            try {
              setLoading(true);
              setShowAssignConversationModal(false);
              socket.emit('assignAgentToConversation', {
                conversationId,
                agentId: selectedAgent!
              });
              setSelectedAgent('');
              toast('success', 'Conversation assigned successfully');
            } catch (err) {
              toastError(err);
            } finally {
              setLoading(false);
            }
          }}>
          Assign
        </Button>
      </FlexibleDiv>
    </StyledAssignedModal>
  );
};

export default AssignConvoModal;
