import { AgentPayload, TeamRes, UpdateAgentPayload } from 'src/types/team';
import gatewayInstance from './axiosGateway';

type GetAgentParams = {
  perPage?: number;
  page?: number;
};

export const createAgent = async (payload: AgentPayload) => {
  const { data } = await gatewayInstance.post('/users/create/agent', payload);
  return data;
};

export const updateAgent = async (payload: UpdateAgentPayload) => {
  const { data } = await gatewayInstance.put('/users/update/agent', payload);
  return data;
};

export const deleteAgent = async (id: string) => {
  const { data } = await gatewayInstance.delete('/users/delete/agent', {
    data: { userId: id }
  });
  return data;
};

export const reactivateAgent = async (id: string) => {
  const { data } = await gatewayInstance.patch('/users/reactivate/' + id);
  return data;
};

export const getAgents = async (params: GetAgentParams) => {
  const { data } = await gatewayInstance.get(`/users/agents`, { params });

  return data.data as TeamRes;
};
