import styled from 'styled-components';
import { Checkbox } from 'antd';

const VPCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    border-radius: 5px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--primary-500);
    border-color: var(--primary-500);
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: var(--primary-500);
  }

  .ant-checkbox-checked::after {
    border-radius: 5px;
    border-color: var(--primary-500);
  }

  &:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--primary-500);
  }
`;

export default VPCheckbox;
