import styled from 'styled-components';

const StyledAuthLayout = styled.main`
  max-width: 552px;
  margin: 0px auto;
  padding: 0 12px;
  padding-bottom: 48px;

  h1 {
    letter-spacing: -0.05em;
  }

  p {
    letter-spacing: -0.01em;
  }

  .resend {
    cursor: pointer;
    color: var(--primary-500);

    &:hover {
      color: var(--primary-800);
    }
  }
`;

export default StyledAuthLayout;
