import styled from 'styled-components';

const StyledConversationsSidebar = styled.aside`
  width: 320px;
  border-left: 1px solid var(--grey-200);

  .title-section {
    padding: 27px 12px 13px;
    position: sticky;
    top: 0;
    z-index: 2;
    background: #fff;
    border-bottom: 1px solid var(--grey-200);
  }

  .sidebar-details {
    position: sticky;
    top: 0;
    height: calc(100vh - 69px);
    overflow-y: auto;
  }

  .container {
    padding: 0 16px;
  }

  .close {
    width: 28px;
    height: 28px;
    display: grid;
    place-content: center;
    cursor: pointer;
    background: var(--grey-100);
    border-radius: 60px;
  }

  .title {
    letter-spacing: -0.04em;
    margin-bottom: 0;
  }

  .initials {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: grid;
    place-content: center;
    background-color: var(--primary-100);
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: -0.04em;
    color: var(--primary-500);
  }

  .name {
    color: var(--grey-700);
    margin-bottom: 2px;
  }

  .mail {
    color: var(--grey-500);
    margin-bottom: 0;
  }

  .map {
    height: 150px;
  }

  .detail-sections {
    padding: 16px 0;
    border-bottom: 1px solid var(--grey-200);

    &:last-child {
      border-bottom: none;
    }

    .title {
      letter-spacing: -0.04em;
      margin-bottom: 12px;
    }
  }

  .tech-info {
    color: var(--grey-500);
    margin-bottom: 8px;

    span {
      font-weight: 500;
      color: var(--grey-600);
      margin-left: 4px;
    }
  }

  .whatsapp-details {
    padding: 16px 0;

    .profile-image {
      width: 56px;
      height: 56px;
      border-radius: 50%;
    }

    .whatsapp-name {
      color: var(--grey-700);
      font-weight: 500;
      text-align: center;
      margin-bottom: 20px;
    }

    & svg {
      vertical-align: middle;
    }
  }
`;

export default StyledConversationsSidebar;
