import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Form } from 'antd';
import { closePaymentModal, useFlutterwave } from 'flutterwave-react-v3';
import { WalletDetailsContext } from 'src/routes/BroadcastPage';
import { Text } from 'src/components/Typography';
import Button from 'src/components/Button';
import { useAuth } from 'src/context/Auth';
import { FormInput, FormItem } from '../Form';
import FlexibleDiv from '../FlexableDiv';
import { initTopUp } from 'src/network/wallet';
import toastError from 'src/utils/toastError';
import { currencyFormatter } from 'src/utils/app';
import { VipiLogo } from 'src/constants';

type ITopUpWallet = {
  setShowTopUpWallet: (val: boolean) => void;
  refetch: () => void;
};

const StyledTopUp = styled.div`
  .amount-input {
    display: flex;
    align-items: center;
  }

  .inputField {
    border-radius: 0px 8px 8px 0px;
  }

  .currency {
    align-self: stretch;
    padding: 10px 12px 8px 14px;
    border: 1px solid var(--grey-300);
    border-right: none;
    border-radius: 8px 0px 0px 8px;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: var(--grey-500);
  }
`;

const TopUpWalletModal = ({ setShowTopUpWallet, refetch }: ITopUpWallet) => {
  const [loading, setLoading] = useState(false);
  const [flwTxRef, setFlwTxRef] = useState(
    `INV-${Math.floor(Date.now() * Math.random()).toString()}`
  );
  const [amount, setAmount] = useState('');
  const userContext = useAuth();
  const [form] = Form.useForm();

  const wallet = useContext(WalletDetailsContext);

  const flutterwaveConfig = {
    amount: parseFloat(amount),
    public_key: process.env.REACT_APP_FLW_KEY as string,
    tx_ref: flwTxRef,
    currency: 'NGN',
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: userContext?.user?.email!,
      phone_number: '',
      name: `${userContext?.user?.firstname} ${userContext?.user?.lastname}`
    },
    customizations: {
      title: 'Vipichat',
      description: 'Vipichat wallet top-up',
      logo: VipiLogo
    }
  };
  const handleFlutterPayment = useFlutterwave(flutterwaveConfig);

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const res = await initTopUp(parseFloat(amount), flwTxRef);
      handleFlutterPayment({
        callback: (response) => {
          closePaymentModal();
          setLoading(false);
          form.resetFields();
          setShowTopUpWallet(false);
          setTimeout(() => {
            // @ts-ignore
            wallet?.setWalletDetails((prev) => {
              if (prev) {
                return { ...prev, walletBalance: prev.walletBalance + parseFloat(amount) };
              }
            });
            refetch();
            setAmount('');
          }, 1250);
        },
        onClose: () => {
          setLoading(false);
        }
      });
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <StyledTopUp>
      <Text variant="lg" weight="medium" mb="24px">
        Top-up wallet
      </Text>
      <Form
        form={form}
        name="topUpWallet"
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}>
        <FormItem
          mb="40px"
          name="amount"
          label={
            <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
              Top-up amount
            </Text>
          }
          rules={[
            {
              required: true,
              message: 'This field is required'
            },
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject();
                }
                if (isNaN(value)) {
                  return Promise.reject('Invalid amount');
                }
                if (!value.match(/\d{5,}/)) {
                  return Promise.reject(
                    `Amount can't be less than ${currencyFormatter.format(10000)}`
                  );
                }
                return Promise.resolve();
              }
            })
          ]}>
          <div className="amount-input">
            <div className="currency">NGN</div>
            <FormInput
              className="inputField"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
        </FormItem>
        <FlexibleDiv flexGap="12px" alignItems="center">
          <button
            className="btn cancel"
            onClick={() => {
              form.resetFields();
              setShowTopUpWallet(false);
            }}>
            Cancel
          </button>
          <Button htmlType="submit" className="btn submit" loading={loading}>
            Continue
          </Button>
        </FlexibleDiv>
      </Form>
    </StyledTopUp>
  );
};

export default TopUpWalletModal;
