import styled from 'styled-components';

const StyledAddRoles = styled.div`
  flex-grow: 1;
  padding: 0 72px 0 44px;
  margin-top: 36px;
  margin-bottom: 48px;

  .back {
    color: var(--grey-900);
    margin-bottom: 150px;
    cursor: pointer;

    svg {
      rotate: 90deg;
    }
  }

  .title {
    letter-spacing: -0.04em;
  }

  .btn {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 11px 16px;

    &.cancel {
      padding: 10px 16px;
      cursor: pointer;
      color: var(--grey-700);
      background: #ffffff;
      border: 1px solid var(--grey-300);
      border-radius: 8px;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    }
  }

  .role-details {
    border-bottom: 1px solid var(--grey-200);
    margin-bottom: 32px;
  }

  .role-users {
    :not(:last-child) {
      margin-bottom: 24px;
    }

    & img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }

  .formItem {
    margin-bottom: 32px;
  }

  .input {
    max-width: 512px;
  }

  .description span:last-child {
    display: block;
  }

  .assign-list {
    p {
      color: #344054;
    }

    &:not(:last-child) {
      margin-bottom: 40px;
    }

    label {
      color: var(--grey-500);
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }

  .contain {
    max-width: 500px;
    margin-bottom: 22px;
  }
`;

export default StyledAddRoles;
