import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { Text } from 'src/components/Typography';
import { ITemplates } from 'src/types/templates';
import FlexibleDiv from '../FlexableDiv';
import Button from 'src/components/Button';
import { SendBroadcastPayload } from 'src/network/templates';
import { FormInput, FormItem } from '../Form';
import StyledNewBroadcast from './styled';
import headerImg from 'src/assets/images/headerImage.png';
import { CloseIcon } from 'src/assets/svg';

type NewBroadcastProps = {
  onClose: () => void;
  activeBroadcast: ITemplates | null;
  setBroadcastPayload: (val: SendBroadcastPayload) => void;
  setActiveDrawer: (val: string) => void;
};

const NewBroadcast = ({
  onClose,
  activeBroadcast,
  setActiveDrawer,
  setBroadcastPayload
}: NewBroadcastProps) => {
  const [broadcastForm] = Form.useForm();
  const [headerUrl, setHeaderUrl] = useState('');
  const [headerVariables, setHeaderVariables] = useState<string[]>([]);
  const [bodyVariables, setBodyVariables] = useState<string[]>([]);

  const sendBroadcast = async (values: any) => {
    let payload: SendBroadcastPayload = {
      language: activeBroadcast?.language,
      name: activeBroadcast?.name,
      headerFormat: activeBroadcast?.headerFormat?.toLowerCase()
    };

    if (headerUrl) {
      payload.headerUrl = headerUrl;
    }

    if (bodyVariables.length > 0) {
      payload.bodyVariables = bodyVariables;
    }

    if (headerVariables.length > 0) {
      payload.headerVariables = headerVariables;
    }
    setBroadcastPayload(payload);
    setActiveDrawer('selectContacts');
  };

  useEffect(() => {
    if (activeBroadcast?.headerUrl) {
      broadcastForm.setFieldsValue({ headerUrl: activeBroadcast?.headerUrl });
      setHeaderUrl(activeBroadcast?.headerUrl);
    }

    if (activeBroadcast?.bodyVariables?.length) {
      let arr: string[] = [];
      activeBroadcast?.bodyVariables.forEach((variable, ind) => {
        arr.push(variable);
        broadcastForm.setFieldsValue({ [`bodyVariable${ind + 1}`]: variable });
      });
      setBodyVariables(arr);
    }

    if (activeBroadcast?.headerVariables?.length) {
      let arr: string[] = [];
      activeBroadcast?.headerVariables.forEach((variable, ind) => {
        arr.push(variable);
        broadcastForm.setFieldsValue({ [`headerVariable${ind + 1}`]: variable });
      });
      setHeaderVariables(arr);
    }
  }, [activeBroadcast]);

  return (
    <StyledNewBroadcast>
      <FlexibleDiv flexGap="8px" alignItems="center" className="header">
        <div className="close-icon" onClick={onClose}>
          <CloseIcon />
        </div>
        <Text variant="md" className="title">
          New broadcast
        </Text>
      </FlexibleDiv>
      <Form
        name="sendBroadcast"
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
        form={broadcastForm}
        onFinish={sendBroadcast}>
        <FlexibleDiv
          flexDir="column"
          justifyContent="space-between"
          className="main-area broadcast-info">
          <section style={{ width: '100%' }}>
            {activeBroadcast?.headerFormat !== 'TEXT' && (
              <FlexibleDiv justifyContent="center" mb="14px">
                <img
                  src={headerUrl || headerImg}
                  alt="header"
                  width={headerUrl ? '124px' : 'auto'}
                  className="header-img"
                />
              </FlexibleDiv>
            )}
            <div className="textarea">
              {bodyVariables.length > 0
                ? activeBroadcast?.bodyText?.replace(/{{\d+}}/g, (match) => {
                    const index = Number(match.replace(/[^\d]/g, ''));
                    return bodyVariables[index - 1];
                  })
                : activeBroadcast?.bodyText}
            </div>
            <FlexibleDiv flexGap="10px" className="btn-section">
              {activeBroadcast?.buttons?.map((button) => (
                <Button outline className="button">
                  {button.buttonText}
                </Button>
              ))}
            </FlexibleDiv>
            {activeBroadcast?.headerUrl && (
              <FormItem
                mb="12px"
                name="headerUrl"
                label={
                  <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                    Header Image URL
                  </Text>
                }
                rules={[{ required: true, message: 'This field is required' }]}>
                <FormInput onChange={(e) => setHeaderUrl(e.target.value)} />
              </FormItem>
            )}
            {activeBroadcast?.headerVariables?.length! >= 1 &&
              activeBroadcast?.headerVariables?.map((variable, ind) => (
                <FormItem
                  mb="12px"
                  name={`headerVariable${ind + 1}`}
                  label={
                    <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                      {`Header {{${ind + 1}}}`}
                    </Text>
                  }
                  rules={[{ required: true, message: 'This field is required' }]}>
                  <FormInput
                    placeholder={`Enter value for {{${ind + 1}}}`}
                    onChange={(e) => {
                      let arr = [...headerVariables];
                      arr[ind] = e.target.value;
                      setHeaderVariables(arr);
                    }}
                  />
                </FormItem>
              ))}
            {activeBroadcast?.bodyVariables?.length! >= 1 &&
              activeBroadcast?.bodyVariables?.map((variable, ind) => (
                <FormItem
                  mb="12px"
                  name={`bodyVariable${ind + 1}`}
                  label={
                    <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                      {`Body {{${ind + 1}}}`}
                    </Text>
                  }
                  rules={[{ required: true, message: 'This field is required' }]}>
                  <FormInput
                    placeholder={`Enter value for {{${ind + 1}}}`}
                    onChange={(e) => {
                      let arr = [...bodyVariables];
                      arr[ind] = e.target.value;
                      setBodyVariables(arr);
                    }}
                  />
                </FormItem>
              ))}
          </section>
          <Button className="btn-con" fullWidth htmlType="submit">
            Continue
          </Button>
        </FlexibleDiv>
      </Form>
    </StyledNewBroadcast>
  );
};

export default NewBroadcast;
