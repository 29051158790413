import styled from 'styled-components';

const DisplayPicture = styled.div`
  display: grid;
  place-content: center;
  width: 44px;
  height: 44px;
  background: #f4f2ff;
  border-radius: 50%;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  color: #6f4eff;
`;

export default DisplayPicture;
