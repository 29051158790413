import styled from 'styled-components';

const StyledRoles = styled.div`
  .table-header {
    padding: 20px 24px;
    border: 1px solid #e4e7ec;
    border-radius: 8px 8px 0 0;
  }

  .add-role {
    padding: 10px 16px;
    display: inline-flex;
    gap: 8px;
    font-weight: 500;
  }

  .view-perm a {
    cursor: pointer;
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #7d5fff;
  }
`;

export default StyledRoles;
