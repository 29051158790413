import React from 'react';
import styled from 'styled-components';

type StatusProps = {
  status: boolean;
};

const StatusPills = ({ status }: StatusProps) => {
  return (
    <StyledStatusPills status={status}>
      <span className="circle"></span>
      {status ? 'Active' : 'Offline'}
    </StyledStatusPills>
  );
};

export default StatusPills;

const StyledStatusPills = styled.span<StatusProps>`
  padding: 2px 8px;
  border-radius: 16px;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  background-color: ${({ status }) => (status ? 'var(--success-50)' : 'var(--grey-100)')};
  color: ${({ status }) => (status ? 'var(--success-700)' : 'var(--grey-700)')};

  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${({ status }) => (status ? 'var(--success-500)' : 'var(--grey-500)')};
  }
`;
