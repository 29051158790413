import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Form } from 'antd';
import { DateTime } from 'luxon';
import type { ColumnsType } from 'antd/es/table';
import useDebounce from 'src/hooks/useDebounce';
import VPTable from 'src/components/VPTable';
import AuthenticatedLayout from 'src/layout/AuthenticatedLayout';
import FlexibleDiv from 'src/components/FlexableDiv';
import { FormInput, FormItem } from 'src/components/Form';
import { Display, Text } from 'src/components/Typography';
import VPModal from 'src/components/VPModal';
import DeleteModal from 'src/components/DeleteModal';
import Button from 'src/components/Button';
import toastError from 'src/utils/toastError';
import TablePaginationItem from 'src/utils/pagination';
import toast from 'src/utils/toasts';
import {
  CustomersPayload,
  deleteCustomer,
  getAllCustomers,
  updateCustomer
} from 'src/network/customers';
import { ICustomer } from 'src/types/customers';
import { Edit, IG, Messenger, Search, Trash, Web, Whatsapp } from 'src/assets/svg';
import StyledCustomersPage from './styled';
import { useAuth } from 'src/context/Auth';

const CustomersPage = () => {
  const [editCustomerForm] = Form.useForm();
  const userContext = useAuth();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const [total, setTotal] = useState<number>();
  const [tableLoading, setTableLoading] = useState(false);

  const [customers, setCustomers] = useState<ICustomer[]>([]);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [actionKey, setActionKey] = useState<ICustomer>();

  const columns: ColumnsType<ICustomer> = [
    {
      title: 'Name',
      dataIndex: 'user',
      render: (user, record) => (
        <FlexibleDiv flexGap="12px" alignItems="center" className="image">
          <img
            width={40}
            height={40}
            alt={`${record.fullname}`}
            src={record.profilePicture ?? 'https://via.placeholder.com/40x40'}
          />
          {record.fullname.replace('undefined', '')}
        </FlexibleDiv>
      )
    },
    {
      title: 'Identifier',
      dataIndex: 'platformIdentifier'
    },
    {
      title: 'Channel',
      dataIndex: 'channel',
      render: (text: string) => {
        if (text === 'WHATSAPP') {
          return <Whatsapp width={24} height={24} />;
        }
        if (text === 'INSTAGRAM') {
          return <IG width={24} height={24} />;
        }
        if (text === 'FACEBOOK') {
          return <Messenger width={24} height={24} />;
        }
        if (text === 'WEB') {
          return <Web width={24} height={24} />;
        }
      }
    },
    {
      title: 'Date added',
      dataIndex: 'createdAt',
      render: (text, record) => DateTime.fromISO(record.createdAt).toFormat('LLLL dd, yyyy')
    }
  ];

  if (userContext?.user?.rolePermissions?.can_update_customer_details) {
    columns.push({
      title: '',
      className: 'actions',
      render: (text, record, index) => {
        return (
          <FlexibleDiv flexGap="8px" alignItems="center" justifyContent="flex-end">
            <div
              className="action"
              onClick={() => {
                setActionKey(record);
                setIsDeleteModalOpen(true);
              }}>
              <Trash />
            </div>
            <div
              className="action"
              onClick={() => {
                setActionKey(record);
                editCustomerForm.setFieldsValue({
                  name: record.fullname
                });
                setIsEditModalOpen(true);
              }}>
              <Edit />
            </div>
          </FlexibleDiv>
        );
      }
    });
  }

  const saveEditCustomer = async (values: { name: string }) => {
    try {
      const res = await updateCustomer(actionKey?.id as string, values.name);
      toast('success', 'Customer updated successfully');
      const customerRes = await getAllCustomers({
        page,
        perPage: 10,
        query: debouncedSearch.trim()
      });
      setCustomers(customerRes.data.data);
      setIsEditModalOpen(false);
    } catch (err) {
      toastError(err);
    }
  };

  const onDeleteCustomer = async (id: string) => {
    try {
      const res = await deleteCustomer(id);
      toast('success', res.message);
    } catch (err) {
      toastError(err);
    }
  };

  const fetchCustomers = async (params: CustomersPayload) => {
    setTableLoading(true);

    try {
      const res = await getAllCustomers(params);
      setPage(Number(res.data.current));
      setTotal(res.data.total);
      setCustomers(res.data.data);
    } catch (error) {
      toastError(error);
    } finally {
      setTableLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers({
      page: page,
      perPage: 10,
      query: debouncedSearch.trim(),
      channel: 'ALL'
    });
  }, [page, debouncedSearch]);

  return (
    <AuthenticatedLayout>
      <Helmet>
        <title>Vipichat | Customers</title>
      </Helmet>
      <StyledCustomersPage>
        <Display as="h1" variant="xs" weight="medium">
          Customers
        </Display>
        <section className="customers">
          <FlexibleDiv alignItems="center" justifyContent="space-between" mb="24px">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}>
              <FormInput
                height="40px"
                width="412px"
                placeholder="Search"
                className="search"
                prefix={<Search />}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </form>
            {/* <FlexibleDiv alignItems="center" flexGap="16px">
              <button className="button">
                <FlexibleDiv alignItems="center" flexGap="8px">
                  <Download />
                  <span>Download CSV</span>
                </FlexibleDiv>
              </button>
              <button className="button">Edit column</button>
            </FlexibleDiv> */}
          </FlexibleDiv>
          <VPTable
            loading={tableLoading}
            rowSelection={{
              type: 'checkbox',
              onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
              }
            }}
            // @ts-ignore
            columns={columns}
            rowKey="_id"
            dataSource={customers}
            pagination={TablePaginationItem(total, 10, setPage)}
          />
        </section>
        <VPModal
          centered
          closable={false}
          className="delete-modal"
          visible={isDeleteModalOpen}
          footer={<></>}>
          <DeleteModal
            title="Customer"
            name={actionKey?.fullname as string}
            onCancelClick={() => setIsDeleteModalOpen(false)}
            onDeleteClick={() => {
              onDeleteCustomer(actionKey?.id as string);
              setIsDeleteModalOpen(false);
            }}
          />
        </VPModal>
        <VPModal
          centered
          closable={false}
          className="form-modal"
          visible={isEditModalOpen}
          footer={<></>}>
          <Text variant="lg" weight="medium" mb="20px">
            Edit {actionKey?.fullname}
          </Text>
          <Form
            name="editCustomer"
            autoComplete="off"
            layout="vertical"
            requiredMark={false}
            form={editCustomerForm}
            onFinish={saveEditCustomer}>
            <FormItem
              mb="16px"
              name="name"
              label={
                <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                  Name
                </Text>
              }
              rules={[{ required: true, message: 'This field is required' }]}>
              <FormInput />
            </FormItem>
            <FlexibleDiv flexGap="12px" alignItems="center">
              <button
                type="button"
                className="btn cancel"
                onClick={() => {
                  setActionKey(undefined);
                  editCustomerForm.resetFields();
                  setIsEditModalOpen(false);
                }}>
                Cancel
              </button>
              <Button htmlType="submit" className="btn submit">
                Save
              </Button>
            </FlexibleDiv>
          </Form>
        </VPModal>
      </StyledCustomersPage>
    </AuthenticatedLayout>
  );
};

export default CustomersPage;
