import React, { useCallback, useEffect, useState } from 'react';
import { Dropdown, Menu, Tooltip } from 'antd';
import type { MenuProps } from 'antd';
import { DateTime } from 'luxon';
import { Text } from '../Typography';
import FlexibleDiv from '../FlexableDiv';
import StyledConversationsList from './styled';
import {
  Assigned,
  AssignedMe,
  Chevron,
  IG,
  Messenger,
  NoResultsSearch,
  Telegram,
  Twitter,
  Unassigned,
  Web,
  Whatsapp
} from 'src/assets/svg';
import { getAssignedStats } from 'src/network/conversations';
import { Channels, IAggregatedConversation, IAssignedStats } from 'src/types/conversation';
import UserAvatar from '../../assets/images/user.jpg';
import { useAuth } from 'src/context/Auth';
import { FormInput } from '../Form';
import Loading from '../Loading';

type ConvListProps = {
  activeTab: 'ALL' | 'ASSIGNED' | 'UNASSIGNED' | 'OWN';
  channel: Channels | 'ALL';
  loadingConvoList: boolean;
  // debouncedSearch: string;
  setSearch: (val: string) => void;
  setChannel: (val: Channels | 'ALL') => void;
  setActiveTab: (val: 'ALL' | 'ASSIGNED' | 'UNASSIGNED' | 'OWN') => void;
  list: IAggregatedConversation[];
  activeChat: string | undefined;
  showcustomerDetails: boolean;
  convListRef: React.RefObject<HTMLDivElement>;
  setShowcustomerDetails: (val: boolean) => void;
  handleActiveConversation: (chat: IAggregatedConversation) => void;
};

const ConversationsList = ({
  list,
  loadingConvoList,
  // debouncedSearch,
  setSearch,
  activeTab,
  setActiveTab,
  channel,
  setChannel,
  activeChat,
  handleActiveConversation,
  showcustomerDetails,
  convListRef,
  setShowcustomerDetails
}: ConvListProps) => {
  const userContext = useAuth();
  const [assignedStats, setAssignedStats] = useState<IAssignedStats>();

  const onClick: MenuProps['onClick'] = ({ key }) => {
    setChannel(key as Channels | 'ALL');
  };

  const menu = (
    <Menu
      onClick={onClick}
      className="dropdown-menu"
      items={[
        {
          label: 'All',
          key: 'ALL',
          className: channel === 'ALL' ? 'active' : ''
        },
        {
          label: 'WhatsApp',
          key: Channels.Whatsapp,
          className: channel === Channels.Whatsapp ? 'active' : ''
        },
        {
          label: 'Instagram',
          key: Channels.Instagram,
          className: channel === Channels.Instagram ? 'active' : ''
        },
        {
          label: 'Facebook',
          key: Channels.Facebook,
          className: channel === Channels.Facebook ? 'active' : ''
        },
        {
          label: 'Web',
          key: Channels.Web,
          className: channel === Channels.Web ? 'active' : ''
        }
        // {
        //   label: 'Twitter',
        //   key: 'twitter',
        //   className: channel === 'twitter' ? 'active' : ''
        // },
        // {
        //   label: 'Telegram',
        //   key: 'telegram',
        //   className: channel === 'telegram' ? 'active' : ''
        // }
      ]}
    />
  );

  useEffect(() => {
    (async () => {
      const res = await getAssignedStats();
      setAssignedStats(res.data);
    })();
  }, []);

  return (
    <StyledConversationsList showCustomerDetails={showcustomerDetails}>
      <div className="tabs">
        <FlexibleDiv wrap="wrap" flexGap="12px" justifyContent="space-between">
          <FlexibleDiv alignItems="center">
            <Tooltip title="All conversations" placement="bottom">
              <Text
                variant="sm"
                className={activeTab === 'ALL' ? 'active-tab' : 'tab-item'}
                onClick={() => setActiveTab('ALL')}>
                All
              </Text>
            </Tooltip>
            <Tooltip title="Assigned to me" placement="bottom">
              <FlexibleDiv
                alignItems="center"
                flexGap="8px"
                className={activeTab === 'OWN' ? 'active-tab' : 'tab-item'}
                onClick={() => setActiveTab('OWN')}>
                <AssignedMe /> <span>{assignedStats?.assignedToMe}</span>
              </FlexibleDiv>
            </Tooltip>
            <Tooltip title="Unassigned" placement="bottom">
              <FlexibleDiv
                alignItems="center"
                flexGap="8px"
                className={activeTab === 'UNASSIGNED' ? 'active-tab' : 'tab-item'}
                onClick={() => setActiveTab('UNASSIGNED')}>
                <Unassigned /> <span>{assignedStats?.unassigned}</span>
              </FlexibleDiv>
            </Tooltip>
            <Tooltip title="Assigned" placement="bottom">
              <FlexibleDiv
                alignItems="center"
                flexGap="8px"
                className={activeTab === 'ASSIGNED' ? 'active-tab' : 'tab-item'}
                onClick={() => setActiveTab('ASSIGNED')}>
                <Assigned /> <span>{assignedStats?.assigned}</span>
              </FlexibleDiv>
            </Tooltip>
          </FlexibleDiv>
          <Dropdown
            overlay={menu}
            placement="bottomCenter"
            trigger={['click']}
            className="channel-dropdown">
            <FlexibleDiv mb="12px" flexGap="6px" alignItems="center">
              <Text variant="sm" mb="0" color="var(--grey-500)" weight="medium">
                Channels: {channel === 'ALL' && 'All'}
              </Text>
              {channel === Channels.Whatsapp && <Whatsapp width={18} height={18} />}
              {channel === Channels.Instagram && <IG width={18} height={18} />}
              {channel === Channels.Facebook && <Messenger width={18} height={18} />}
              {channel === Channels.Web && <Web width={18} height={18} />}
              {/* {channel === 'twitter' && <Twitter width={18} height={18} />}
              {channel === 'telegram' && <Telegram width={18} height={18} />} */}
              <Chevron />
            </FlexibleDiv>
          </Dropdown>
        </FlexibleDiv>
      </div>
      <div className="search">
        <FormInput height="36px" placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
      </div>
      <div className="chat-list" ref={convListRef}>
        {loadingConvoList ? (
          <FlexibleDiv alignItems="center" justifyContent="center">
            <Loading />
          </FlexibleDiv>
        ) : list.length > 0 ? (
          list?.map((chat) => (
            <div
              key={chat?.id}
              className={`chat-item ${activeChat === chat?._id ? 'active' : ''}`}
              onClick={() => {
                handleActiveConversation(chat);
                setShowcustomerDetails(false);
              }}>
              <FlexibleDiv flexGap="12px">
                <div className="chat-image">
                  <img
                    src={UserAvatar}
                    // src={
                    //   chat?.customerId?.channel.toLowerCase() === 'instagram'
                    //     ? UserAvatar
                    //     : chat?.customerId?.profilePicture || UserAvatar
                    // }
                    alt={chat?.customerId?.fullname}
                  />
                  {chat?.customerId?.channel.toLowerCase() === 'instagram' && <IG />}
                  {chat?.customerId?.channel.toLowerCase() === 'whatsapp' && <Whatsapp />}
                  {chat?.customerId?.channel.toLowerCase() === 'twitter' && <Twitter />}
                  {chat?.customerId?.channel.toLowerCase() === 'facebook' && <Messenger />}
                  {chat?.customerId?.channel.toLowerCase() === 'telegram' && <Telegram />}
                  {chat?.customerId?.channel.toLowerCase() === 'web' && <Web />}
                </div>
                <div className={`chat-info ${!chat.read ? 'unread' : ''}`}>
                  <FlexibleDiv justifyContent="space-between" alignItems="center" flexGap="6px">
                    <Text variant="sm">{chat?.customerId?.fullname.replace('undefined', '')}</Text>
                    <Text variant="xs" color="var(--grey-500)">
                      {DateTime.fromISO(chat?.lastMessage.updatedAt).toRelative()}
                    </Text>
                  </FlexibleDiv>
                  <Text
                    variant="xs"
                    color="var(--grey-500)"
                    className="recent-msg"
                    style={{
                      fontStyle: chat?.lastMessage.status === 'DELETED' ? 'italic' : 'normal'
                    }}>
                    {chat?.lastMessage.status === 'DELETED'
                      ? 'Message deleted'
                      : chat?.lastMessage.messageBody}
                  </Text>
                  {!chat.read && <span className="unread-dot"></span>}
                  {chat.conversationAssignedTo && (
                    <Text as="span" variant="xs" className="assigned-agent">
                      {chat?.conversationAssignedTo?.firstname}{' '}
                      {chat?.conversationAssignedTo?.lastname}
                    </Text>
                  )}
                </div>
              </FlexibleDiv>
            </div>
          ))
        ) : (
          <div className="noConvo">
            <div>
              <FlexibleDiv justifyContent="center" mb="20px">
                <NoResultsSearch />
              </FlexibleDiv>
              <Text variant="md" weight="medium" mb="6px">
                No results
              </Text>
              {/* <Text variant="sm" color="var(--grey-500)">
                Nothing found for “{debouncedSearch}”, try searching something else
              </Text> */}
            </div>
          </div>
        )}
      </div>
    </StyledConversationsList>
  );
};

export default ConversationsList;
