import styled from 'styled-components';

const StyledActivityLogs = styled.section`
  .activity {
    color: var(--grey-500) !important;

    span {
      color: var(--grey-900) !important;
    }
  }

  .user-filter .ant-select-selector {
    min-width: 175px;
  }
`;

export default StyledActivityLogs;
