import styled from 'styled-components';

const StyledOrderCatalogue = styled.section`
  width: 100%;
  .header {
    padding: 0 18px 12px;
    border-bottom: 1px solid var(--grey-200);
  }

  .title {
    font-weight: 500;
    letter-spacing: -0.04em;
    margin-bottom: 0;
  }

  .main {
    padding: 26px 18px 0;
    &-inner {
      padding: 0 16px;
      &-first {
        border-bottom: 1px solid var(--grey-200);
        margin-bottom: 40px;
      }
      &-second {
        margin-bottom: 80px;
      }
    }
  }

  .product {
    list-style: none;
    img {
      width: 48px;
      height: 48px;
      object-fit: cover;
    }
    &-content-meta, &-pricing-meta {
      flex-grow: 1;
    }
    &-pricing-meta p {
      text-wrap: nowrap;
    }
  }

  .order-btn {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    font-weight: 500;
    font-size: 1rem;
  }

  .cancel-order {
    border: 1px solid var(--error-500);
    background-color: #fff;
    color: var(--error-500);
  }

  .accept-order {
    border: 1px solid var(--success-500);
    background-color: var(--success-500);
  }
`;

export default StyledOrderCatalogue;
