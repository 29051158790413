import React from "react";
import { TablePaginationConfig } from "antd";
import { ArrowLeft } from "src/assets/svg";

const TablePaginationItem = (
  total?: number, // total number of items
  pageSize?: number, // number of items per page
  onChange?: (page: number, pageSize?: number | undefined) => void
): false | TablePaginationConfig => {
  return {
    total,
    pageSize,
    showSizeChanger: false,
    showPrevNextJumpers: true,
    onChange: (page) => onChange && onChange(page),
    itemRender(page, type, element) {
      if (type === "prev") {
        return (
          <span className="page-prev">
            <ArrowLeft />
            Previous
          </span>
        );
      }
      if (type === "next") {
        return (
          <span className="page-next">
            Next
            <ArrowLeft className="rotate" />
          </span>
        );
      }
      if (type === "jump-next") {
        return "...";
      }
      if (type === "jump-prev") {
        return "...";
      }
      return element;
    },
  };
};

export default TablePaginationItem;
