import styled from 'styled-components';

const StyledSettings = styled.section`
  flex-grow: 1;
  padding: 0 72px 0 44px;
  margin-top: 36px;
  margin-bottom: 48px;

  h1 {
    letter-spacing: -0.04em;
    margin-bottom: 28px;
  }

  .tab-item {
    cursor: pointer;
    color: var(--grey-500);
    padding-bottom: 16px;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;

    &.active {
      color: var(--primary-500);
      border-bottom: 2px solid var(--primary-500);
    }
  }
`;

export default StyledSettings;
