import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import type { ColumnsType } from 'antd/es/table';
import Button from 'src/components/Button';
import FlexibleDiv from 'src/components/FlexableDiv';
import { Text } from 'src/components/Typography';
import VPTable from 'src/components/VPTable';
import TablePaginationItem from 'src/utils/pagination';
import toastError from 'src/utils/toastError';
import { getRoles } from 'src/network/roles';
import { useAuth } from 'src/context/Auth';
import { IRole } from 'src/types/roles';
import { Plus, Trash } from 'src/assets/svg';
import StyledRoles from './styled';
import { trimString } from 'src/utils/app';

const Roles = () => {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [roles, setRoles] = useState<IRole[]>([]);

  const userContext = useAuth();

  const columns: ColumnsType<IRole> = [
    {
      title: 'Name',
      dataIndex: 'roleName'
    },
    {
      title: 'Description',
      dataIndex: 'roleDescription',
      render: (value, record) => trimString(record.roleDescription, 50)
    },
    // {
    //   title: 'Date modified',
    //   dataIndex: 'updatedAt',
    //   render: (date, record) => DateTime.fromISO(record.updatedAt).toFormat('LLL dd, yyyy')
    // },
    {
      title: '',
      render: (role, record) => <Link to={`/settings/role/${record._id}`}>View permissions</Link>,
      className: 'view-perm'
    }
    // {
    //   title: '',
    //   render: (text, record) => (
    //     <div className="action" onClick={() => {}}>
    //       <Trash />
    //     </div>
    //   )
    // }
  ];

  const getAllRoles = useCallback(async () => {
    const res = await getRoles(userContext?.user?.businessId.id!, currentPage);
    return res;
  }, [userContext?.user?.businessId.id]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await getAllRoles();
        setRoles(res.data);
        setTotal(res.pagination.totalCount);
        setCurrentPage(Number(res.pagination.page));
      } catch (error) {
        toastError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [getAllRoles]);

  return (
    <StyledRoles>
      <Text as="h2" variant="lg" weight="medium" mb="0">
        Roles and permissions
      </Text>
      <Text variant="sm" color="var(--grey-500)" mb="24px">
        Manage and assign the actions that a team member can perform
      </Text>
      <section>
        <FlexibleDiv alignItems="center" justifyContent="space-between" className="table-header">
          <Text as="h2" variant="lg" weight="medium" mb="0">
            Roles
          </Text>
          {userContext?.user?.rolePermissions?.can_create_roles && (
            <Link to="/settings/add-role">
              <Button icon={<Plus />} className="add-role">
                Add new role
              </Button>
            </Link>
          )}
        </FlexibleDiv>
        <VPTable
          removeTop
          noSelect
          loading={loading}
          dataSource={roles}
          columns={columns as ColumnsType<object>}
          pagination={TablePaginationItem(total, 10, setCurrentPage)}
        />
      </section>
    </StyledRoles>
  );
};

export default Roles;
