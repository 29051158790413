import styled from 'styled-components';

const StyledSelectContacts = styled.div`
  .search {
    margin-bottom: 20px;
  }

  .contact-list {
    padding: 12px;
    border-radius: 4px;
    overflow-y: auto;
    background: var(--grey-100);
    height: calc(100vh - 322px);
  }

  .contact-checkbox:not(:last-child) {
    margin-bottom: 16px;
  }

  .contact-checkbox + .contact-checkbox {
    margin-left: 0;
  }

  .ant-checkbox + span,
  .contact-checkbox {
    width: 100%;
  }

  .checkbox-label {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 0.875rem;
      line-height: 1.25rem;

      &.name {
        color: var(--grey-700);
      }

      &.number {
        color: var(--grey-500);
      }
    }
  }
`;

export default StyledSelectContacts;
