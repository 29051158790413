import React from 'react';
import { Outlet } from 'react-router-dom';
import AuthenticatedLayout from 'src/layout/AuthenticatedLayout';

const ProductsPage = () => {
  return (
    <AuthenticatedLayout>
      <Outlet />
    </AuthenticatedLayout>
  );
};

export default ProductsPage;
