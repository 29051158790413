import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, Navigate } from 'react-router-dom';
import { Form } from 'antd';
import AuthScreensLayout from 'src/layout/AuthScreensLayout';
import Button from 'src/components/Button';
import FlexibleDiv from 'src/components/FlexableDiv';
import { Display, Text } from 'src/components/Typography';
import { FormItem, FormInput } from 'src/components/Form';
import { forgotPassword } from 'src/network/auth';
import { toastErrorUnauthScreens } from 'src/utils/toastError';
import toast from 'src/utils/toasts';

const ForgotPasswordPage = () => {
  const [onSuccess, setOnSuccess] = useState(false);
  const [email, setEmail] = useState('');

  if (localStorage.getItem('VP_TOKEN')) {
    return <Navigate to="/dashboard" />;
  }

  const onFinish = async (values: any) => {
    try {
      const res = await forgotPassword(values);
      setOnSuccess(true);
      toast('success', res);
    } catch (error) {
      toastErrorUnauthScreens(error);
    }
  };

  return (
    <AuthScreensLayout>
      <Helmet>
        <title>Vipichat | Forgot Password</title>
      </Helmet>
      <Display as="h1" variant="md" weight="bold">
        {onSuccess ? 'Check your email' : 'Forgot your password?'}
      </Display>
      <Text variant="md" color="#667085" mb="36px">
        {onSuccess
          ? 'We have sent a secure reset link to your email address. Click on the link to reset your password.'
          : 'Enter your email below and we’ll send you instructions to reset your password.'}
      </Text>
      {onSuccess ? (
        <>
          <Link to="/signin">
            <Button mb="28px" fullWidth>
              Back to Login
            </Button>
          </Link>
          <FlexibleDiv justifyContent="space-between">
            <Text underline className="resend" variant="sm" onClick={() => onFinish({ email })}>
              Resend email
            </Text>
          </FlexibleDiv>
        </>
      ) : (
        <>
          <Form
            name="forgotPassword"
            autoComplete="off"
            layout="vertical"
            requiredMark={false}
            onFinish={(values) => {
              onFinish(values);
              setEmail(values.email);
            }}>
            <FormItem
              mb="28px"
              name="email"
              label={
                <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                  Email address
                </Text>
              }
              rules={[
                { required: true, message: 'This field is required' },
                {
                  type: 'email',
                  message: 'Please enter a valid email address'
                }
              ]}>
              <FormInput width="100%" type="email" />
            </FormItem>
            <Button htmlType="submit" fullWidth mb="28px">
              Send email
            </Button>
          </Form>
          <Text as={Link} to="/signin" variant="sm" color="var(--primary-500)" underline>
            Sign into existing account
          </Text>
        </>
      )}
    </AuthScreensLayout>
  );
};

export default ForgotPasswordPage;
