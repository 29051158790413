import styled from 'styled-components';

const SidebarNav = styled.nav`
  width: 64px;
  height: 100vh;
  padding: 28px 16px 16px;
  background-color: var(--vipi-black);
  position: sticky;
  top: 0;
  /* overflow-y: auto; */

  .nav {
    height: calc(100vh - 98px);
  }

  .avatar {
    padding: 6px 6px 0;

    & img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
  }

  .nav-links {
    padding: 12px;
    cursor: pointer;
    border-radius: 6px;

    & > div {
      display: grid;
      place-content: center;
    }

    @media (max-width: 1024px) {
      padding: 10px;

      & svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .active {
    background: #233747;
  }

  @media (max-width: 1024px) {
    width: 64px;
  }
`;

export default SidebarNav;
