import styled from 'styled-components';

const StyledBroadcastSummary = styled.div`
  .summary__flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 16px;
    }
  }

  .key,
  .value {
    margin-bottom: 0;
  }

  .key {
    letter-spacing: -0.02em;
    color: var(--grey-500);
  }

  .value {
    color: var(--grey-700);
  }

  .total p {
    font-weight: 500;
    color: var(--grey-700);
    margin-bottom: 0;
  }

  .insufficient {
    padding: 10px 8px;
    border-radius: 4px;
    border: 1px solid var(--warning-100);
    background: var(--warning-50);

    p:first-child {
      color: var(--grey-700);
    }

    & > * {
      letter-spacing: -0.02em;
    }
  }

  .topUpWallet {
    display: inline-block;
    cursor: pointer;
    color: var(--warning-600);
    margin-bottom: 0;

    svg {
      transform: rotate(-90deg);
      width: 8px;
      height: 8px;
      margin-left: 2px;
    }
    path {
      fill: var(--warning-600);
    }
  }
`;

export default StyledBroadcastSummary;
