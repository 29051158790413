import { PERMITTED_PRODUCTS_CSV_COL } from 'src/constants';

export const trimString = (str: string, length: number) => {
  if (str.length > length) {
    return str.substring(0, length) + '...';
  }
  return str;
};

export const currencyFormatter = new Intl.NumberFormat('en-NG', {
  style: 'currency',
  currency: 'NGN',
  minimumFractionDigits: 0
});

export const checkNotificationPromise = () => {
  try {
    Notification.requestPermission().then();
  } catch (e) {
    return false;
  }

  return true;
};

function stripCommasInQuotes(csvString: string) {
  let insideQuotes = false;
  let result = '';

  for (let i = 0; i < csvString.length; i++) {
    const char = csvString[i];

    if (char === '"') {
      insideQuotes = !insideQuotes;
    } else if (char === ',' && insideQuotes) {
      continue; // Skip comma within quotes
    }

    result += char;
  }

  return result;
}

export const productCSVValidator = (csv: string) => {
  const rowData = stripCommasInQuotes(csv).split('\r\n');
  if (!rowData.length) return null;
  const isValidHeader = rowData[0]
    .split(',')
    .every((item) => PERMITTED_PRODUCTS_CSV_COL.includes(item.toLowerCase()));
  if (!isValidHeader) return null;
  const filteredRows = rowData.reduce(
    (acc, curr) =>
      curr.split(',').length === PERMITTED_PRODUCTS_CSV_COL.length ? acc.concat(curr) : acc,
    [] as string[]
  );
  return filteredRows.length && filteredRows[1]
    ? { preview: filteredRows[1].split(','), totalLength: filteredRows.length - 1 }
    : null;
};
