import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: "Inter", sans-serif;
  }

  ::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;

    &:hover {
      background-color: var(--grey-100);
    }
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--grey-300);
    border-radius: 1em;
  }

  @supports (scrollbar-color: var(--grey-500) transparent) {
    * {
      scrollbar-color: var(--grey-500) transparent;
      scrollbar-width: normal;
    }
  }

  a:hover {
    color: var(--primary-800);
  }

  html {
    height: auto;
  }

  body {
    overflow-x: hidden;
    background-color: #ffffff;
    scroll-behavior: smooth;
  }

  li {
    list-style: none;
  }
  /* button reset */
  button {
    outline: none;
    background: none;
    cursor: pointer;
    border: none;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
  }


  .or {
    margin: 0 12px;
    text-align: center;
    position: relative;

    &::before {
      content: "";
      width: 47%;
      border-top: 1px solid var(--grey-300);
      position: absolute;
      left: -12px;
      top: 50%;
    }

    &::after {
      content: "";
      width: 47%;
      border-top: 1px solid var(--grey-300);
      position: absolute;
      right: -12px;
      top: 50%;
    }
  }

  .fb-login {
    display: inline-flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    margin: 24px 0px;
    cursor: pointer;
    padding: 14px 20px;
    border-radius: 6px;
    background-color: #3b5998;
    letter-spacing: -0.02em;
    border: none;
    width: 100%;
    color: #fff;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }

  .ant-select-dropdown {
    padding: 4px;
    box-shadow: 0px 24px 94px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .ant-select-item {
      padding: 10px 14px;
      border-radius: 4px;
      font-size: 0.875rem;
      line-height: 1rem;
      color: var(--grey-900);
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: var(--primary-50);
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color: var(--primary-100);
    }
  }

  .ant-picker-header-view button:hover {
    color: var(--primary-500);
  }

  .ant-picker-header-next-btn, .ant-picker-header-super-next-btn {
    transform: rotate(-90deg);
  }

  .ant-picker-header-prev-btn, .ant-picker-header-super-prev-btn {
    transform: rotate(90deg);
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: var(--primary-100);
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
    background-color: var(--primary-500);
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid var(--primary-500);
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background-color: var(--primary-100);
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    border-color: var(--primary-500) !important;
  }

  .ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
    color: var(--primary-500);
    background: var(--primary-50);
    border-color: var(--primary-300);
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    color: var(--primary-500);
  }

  .ant-message {
    top: 48px;
  }

  .ant-message-notice-content {
    color: #fff;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-radius: 5px;
    padding: 10px 16px;

    & > div {
      display: flex;
      gap: 12px;
    }
  }

  .toast-success > div {
    background-color: var(--success-500);
  }

  .toast-error > div {
    background-color: var(--error-500);
  }

  .dropdown-menu {
    padding: 4px;
    box-shadow: 0px 24px 94px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    
    
    li {
      padding: 6px 8px;
      border-radius: 3px;
      font-size: 0.75rem;
      line-height: 1.125rem;

      &:not(:last-child) {
        margin-bottom: 4px;
      }

      &.active {
        background-color: var(--primary-50);
      }
    }
  }

  .delete-msg-menu .ant-dropdown-menu-title-content {
    font-size: 12px;
    line-height: 16px;
  }

  .delete-modal {
    width: 424px !important;
  }

  .whatsapp-modal {
    width: 432px !important;
  }

  .form-modal {
    width: 448px !important;

    .btn {
      flex: 1;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 8px;
      cursor: pointer;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

    &.cancel {
      border: 1px solid var(--grey-300);
      background: #fff;
    }

    &.delete {
      border: none;
      background: var(--error-500);
      color: #ffffff;
    }

    &.primary {
      border: none;
      background: var(--primary-500);
      color: #ffffff;
    }
  }
  }

    .profilePicture-upload {
    & .image {
      width: 56px;
      height: 56px;
      border-radius: 50%;
    }

    .upload-section {
      cursor: pointer;
      padding: 8px 12px;
      text-align: center;
      background: #fff;
      border-radius: 8px;
      border: 1px solid var(--grey-300);
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    }
  }

  .page-prev,.page-next {
    color: var(--grey-700);
    font-weight: 500;
    padding: 0px 16px;
    display: inline-flex;
    align-items: center;
    gap: 12px;

    .rotate {
      transform: rotate(180deg);
    }
  }

  .ant-pagination-prev,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next,
  .ant-pagination-item {
    margin-right: 0px;
  }

  .ant-table-pagination.ant-pagination {
    margin: 0;
    padding: 12px 0 16px;
    filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));

    & li {
      height: 40px;
      min-width: 40px;
      display: inline-grid;
      place-content: center;
      border-radius: 0;
      border-left: none;
      border-top: 1px solid var(--grey-300);
      border-right: 1px solid var(--grey-300);
      border-bottom: 1px solid var(--grey-300);

      &:first-child {
        border-left: 1px solid var(--grey-300);
        border-radius: 8px 0 0 8px;
      }

      &:last-child {
        border-radius: 0 8px 8px 0;
      }
    }
  }

  .ant-pagination-item {
    a {
      height: 100%;
    }

    &:hover a {
      color: #353535;
    }
  }

  .ant-pagination-item-active {
    background: rgba(35, 31, 32, 0.08);

    & a {
      color: var(--grey-500);
    }
  }

  .error-trash  {
    width: 12px;
    height: 12px;

    path{
    stroke: var(--error-500);}
  }
`;

export default GlobalStyle;
