import { WalletDetailsRes } from 'src/types/wallet';
import gatewayInstance from './axiosGateway';

export type GetWalletHistoryParams = {
  page: number;
  perPage: number;
};

export const initTopUp = async (amount: number, transactionReference: string) => {
  const { data } = await gatewayInstance.post('/payments/initialize', {
    amount,
    transactionReference
  });

  return data;
};

export const getTopUpHistory = async (params: GetWalletHistoryParams) => {
  const { data } = await gatewayInstance.get('/payments/top-up-history', {
    params
  });

  return data.data;
};

export const getWalletDetails = async () => {
  const { data } = await gatewayInstance.get('/payments/wallet');

  return data.data as WalletDetailsRes;
};
