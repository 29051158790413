import styled from 'styled-components';

const StyledDashboardPage = styled.section`
  flex-grow: 1;
  padding: 0 72px 0 44px;
  margin-top: 36px;
  margin-bottom: 48px;

  .loading-center {
    display: grid;
    place-content: center;
    height: calc(100vh - 84px);
  }

  h1 {
    letter-spacing: -0.04em;
    margin-bottom: 12px;
  }

  .desc {
    letter-spacing: -0.01em;
    color: var(--grey-500);
    margin-bottom: 0;
  }

  .ant-select-selector {
    min-width: 120px;
  }

  .card {
    flex: 1;
    padding: 24px;
    border-radius: 8px;
    background: #ffffff;
    border: 1px solid var(--grey-200);
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  }

  .card-title {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.25rem;
    letter-spacing: 0.1em;
    color: var(--grey-500);
    margin-bottom: 8px;
  }

  .card-value {
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.75rem;
    letter-spacing: -0.07em;
    color: var(--grey-900);
    margin-bottom: 0;
  }

  .chart-section {
    flex: 6.55;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid var(--grey-200);
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  }

  .top-agents {
    flex: 3.45;
    align-self: stretch;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid var(--grey-200);
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  }

  .conversation-channel {
    display: flex;
    gap: 8px;
    align-items: end;
    padding: 10px 24px 0;
    justify-content: space-between;
    background: var(--grey-50);
    border-bottom: 1px solid var(--grey-200);
    border-radius: 8px 8px 0 0;
  }

  .filter {
    display: flex;
    gap: 16px;

    .tab-item {
      cursor: pointer;
      padding-bottom: 8px;
    }

    .active-tab {
      border-bottom: 2px solid var(--primary-500);
      span {
        color: var(--primary-500);
      }
    }

    span {
      text-transform: capitalize;
      padding: 0 4px;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: var(--grey-500);
    }
  }

  .title {
    padding: 10px 24px;
    background: var(--grey-50);
    border-bottom: 1px solid var(--grey-200);
    border-radius: 8px 8px 0 0;

    h2 {
      font-weight: 500;
      color: var(--grey-900);
      margin-bottom: 0;
    }

    &.has-pill {
      display: flex;
      align-items: center;
      gap: 8px;

      span {
        padding: 2px 8px;
        font-weight: 500;
        border-radius: 16px;
        color: var(--primary-700);
        background: var(--primary-50);
      }
    }
  }

  .agents-list {
    padding: 16px;

    p {
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: var(--grey-700);
      margin-bottom: 0;
    }

    span {
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: var(--grey-500);
      margin-bottom: 0;
    }

    & > div {
      padding: 8px;
      display: flex;
      gap: 8px;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  .customers-link {
    margin: 0 auto 12px;
    text-align: center;
    font-weight: 500;

    a {
      color: var(--primary-500);
    }
  }
`;

export default StyledDashboardPage;
