import { CreateRolePayload } from 'src/types/roles';
import gatewayInstance from './axiosGateway';

export const getRoles = async (id: string, page: number) => {
  const { data } = await gatewayInstance.get(`/roles/business/${id}?perPage=10&page=${page}`);

  return data;
};

export const getRolesWithoutPagination = async (id: string) => {
  const { data } = await gatewayInstance.get(`/roles/business/${id}`);

  return data;
};

export const createRole = async (payload: CreateRolePayload) => {
  const { data } = await gatewayInstance.post('/roles', payload);

  return data;
};

export const getRoleById = async (id: string) => {
  const { data } = await gatewayInstance.get(`/roles/${id}`);

  return data;
};

export const modifyRole = async (id: string, payload: CreateRolePayload) => {
  const { data } = await gatewayInstance.put(`/roles/${id}`, payload);

  return data;
};
