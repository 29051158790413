import React from 'react';
import styled from 'styled-components';

const StyledWalletsCard = styled.div`
  flex: 1;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  border: 1px solid var(--grey-200);

  .title {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.25rem;
    letter-spacing: 0.1em;
    color: var(--grey-500);
    margin-bottom: 8px;
  }

  .value {
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.75rem;
    letter-spacing: -0.07em;
    margin-bottom: 0;
  }
`;

const WalletsCard = ({ title, value }: { title: string; value: string | number }) => {
  return (
    <StyledWalletsCard>
      <p className="title">{title}</p>
      <p className="value">{value}</p>
    </StyledWalletsCard>
  );
};

export default WalletsCard;
