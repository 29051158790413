import React from 'react';
import { useNavigate } from 'react-router-dom';
import FlexibleDiv from 'src/components/FlexableDiv';
import { Display } from 'src/components/Typography';
import Integrations from 'src/components/SettingsTabs/Integrations';
import StyledSettings from '../AllSettingsPage/styled';

const TABS = [
  { title: 'Team', name: 'team' },
  { title: 'Roles', name: 'roles' },
  { title: 'Quick Replies', name: 'quick-replies' },
  { title: 'Activity', name: 'activity' }
  // { title: "Plan" },
  // { title: "Billing" },
  // { title: "Bank account" },
  // { title: "Automations" },
  // { title: "Account settings" },
];

const IntegrationsPage = () => {
  const navigate = useNavigate();

  return (
    <StyledSettings>
      <Display as="h1" variant="xs" weight="medium">
        Settings
      </Display>
      <FlexibleDiv as="nav" flexGap="20px" alignItems="center" mb="24px">
        <div className="tab-item" onClick={() => navigate(`/settings?tab=profile`)}>
          Profile
        </div>
        <div className="tab-item active" id="integrations-tab">
          Integrations
        </div>
        {TABS.map((tab) => (
          <div
            key={tab.name}
            className="tab-item"
            onClick={() => navigate(`/settings?tab=${tab.name}`)}>
            {tab.title}
          </div>
        ))}
      </FlexibleDiv>
      <Integrations />
    </StyledSettings>
  );
};

export default IntegrationsPage;
