import React, { createContext, useEffect, useState, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import Button from 'src/components/Button';
import FlexibleDiv from 'src/components/FlexableDiv';
import { Display, Text } from 'src/components/Typography';
import AuthenticatedLayout from 'src/layout/AuthenticatedLayout';
import Templates from 'src/components/BroadcastTabs/Templates';
import Wallets from 'src/components/BroadcastTabs/Wallets';
import VPModal from 'src/components/VPModal';
import TopUpWalletModal from 'src/components/TopUpWalletModal';
import { getTopUpHistory, getWalletDetails, GetWalletHistoryParams } from 'src/network/wallet';
import { SocketContext } from 'src/context/socketContext';
import { TopUpHistory, WalletDetailsRes } from 'src/types/wallet';
import toastError from 'src/utils/toastError';
import StyledAllTemplates from './styled';
import { Helmet } from 'react-helmet-async';

export const WalletDetailsContext = createContext<{
  walletDetails: WalletDetailsRes | null;
  setWalletDetails: React.Dispatch<React.SetStateAction<WalletDetailsRes | null>>;
} | null>(null);

const AllTemplates = () => {
  const { socket } = useContext(SocketContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(searchParams.get('tab') || 'templates');

  const [showTopUpWallet, setShowTopUpWallet] = useState(false);
  const [wallets, setWallets] = useState<TopUpHistory[]>([]);
  const [walletDetails, setWalletDetails] = useState<WalletDetailsRes | null>(null);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<number>();
  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    socket.on(
      'PAYMENT_UPDATE_EVENT',
      (data: { businessId: number; id: number; status: string }) => {
        // @ts-ignore
        setWalletDetails((prev) => {
          if (prev) {
            return { ...prev, walletBalance: prev.walletBalance + data.id };
          }
        });
      }
    );

    return () => {
      socket.off('PAYMENT_UPDATE_EVENT');
    };
  }, [socket]);

  useEffect(() => {
    (async () => {
      try {
        const res = await getWalletDetails();
        setWalletDetails(res);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  const fetchWallets = async (params: GetWalletHistoryParams) => {
    setTableLoading(true);

    try {
      const res = await getTopUpHistory(params);
      setPage(Number(res.current));
      setTotal(res.total);
      setWallets(res.topupHistory);
    } catch (error) {
      toastError(error);
    } finally {
      setTableLoading(false);
    }
  };

  useEffect(() => {
    fetchWallets({
      page,
      perPage: 20
    });
  }, [page]);

  const TABS = [
    {
      title: 'Templates',
      name: 'templates',
      Component: (
        <>
          <Helmet>
            <title>Vipichat | Templates</title>
          </Helmet>
          <Templates />
        </>
      )
    },
    {
      title: 'Wallet',
      name: 'wallet',
      Component: (
        <>
          <Helmet>
            <title>Vipichat | Wallet</title>
          </Helmet>
          <Wallets tableLoading={tableLoading} setPage={setPage} wallets={wallets} total={total} />
        </>
      )
    }
  ];

  return (
    <WalletDetailsContext.Provider value={{ walletDetails, setWalletDetails }}>
      <AuthenticatedLayout>
        <StyledAllTemplates>
          <FlexibleDiv justifyContent="space-between" alignItems="center" mb="40px">
            <div>
              <Display as="h1" variant="xs" weight="medium">
                Broadcast
              </Display>
              <Text variant="md" className="desc">
                Send messages to many contacts at once using broadcasts
              </Text>
            </div>
            <Button className="topUp-btn" onClick={() => setShowTopUpWallet(true)}>
              Top up wallet
            </Button>
          </FlexibleDiv>
          <FlexibleDiv as="nav" flexGap="20px" alignItems="center" mb="24px">
            {TABS.map((tab) => (
              <div
                key={tab.name}
                onClick={() => {
                  setSearchParams({ tab: tab.name });
                  setActiveTab(tab.name);
                }}
                className={`tab-item ${activeTab === tab.name ? 'active' : ''}`}>
                {tab.title}
              </div>
            ))}
          </FlexibleDiv>
          {TABS.map(({ Component, name }) => name === activeTab && Component)}
          <VPModal
            centered
            closable={false}
            className="form-modal"
            visible={showTopUpWallet}
            footer={<></>}>
            <TopUpWalletModal
              setShowTopUpWallet={setShowTopUpWallet}
              refetch={() =>
                fetchWallets({
                  page,
                  perPage: 20
                })
              }
            />
          </VPModal>
        </StyledAllTemplates>
      </AuthenticatedLayout>
    </WalletDetailsContext.Provider>
  );
};

export default AllTemplates;
