import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form } from 'antd';
import { Text } from 'src/components/Typography';
import FlexibleDiv from 'src/components/FlexableDiv';
import Button from 'src/components/Button';
import Loading from 'src/components/Loading/index';
import { FormInput, FormItem } from 'src/components/Form';
import FormTextArea from 'src/components/Form/TextArea';
import VPSwitch from 'src/components/VPSwitch';
import { getRoleById, modifyRole } from 'src/network/roles';
import toast from 'src/utils/toasts';
import toastError from 'src/utils/toastError';
import { useAuth } from 'src/context/Auth';
import { checkFirstKey, checkSecondKey } from 'src/utils/roles';
import { CreateRolePayload, RoleDetail } from 'src/types/roles';
import { Chevron } from 'src/assets/svg';
import StyledAddRoles from '../AddRolePage/styled';
import DisplayPicture from 'src/components/DisplayPicture';

const ViewRolePage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { roleId } = useParams();
  const [roleInfo, setRoleInfo] = useState<RoleDetail>();
  const [isRoleSaving, setIsRoleSaving] = useState(false);

  const userContext = useAuth();

  const onFinishAddRole = async (values: any) => {
    const payload: CreateRolePayload = {
      roleName: values.roleName!,
      roleDescription: values.description!,
      can_view_conversations: values.conversationPermissions.canView!,
      can_chat_and_share_files_with_customers: values.conversationPermissions.canChatShare!,
      can_view_unassigned_chats: values.assignPermissions.canViewAssigned!,
      can_assign_chats_to_others: values.assignPermissions.canAssignAgents!,
      can_assign_chats_to_self: values.assignPermissions.canAssignSelf!,
      can_view_customers: values.customerPermissions.canViewCustomers!,
      can_update_customer_details: values.customerPermissions.canUpdate!,
      can_view_team_members: values.teamPermissions.canViewTeam!,
      can_add_team_members: values.teamPermissions.canAddTeam!,
      can_update_team_members_details: values.teamPermissions.canUpdateTeam!,
      can_delete_team_members: values.teamPermissions.canDeleteTeam!,
      can_view_roles: values.rolePermissions.canViewRole!,
      can_create_roles: values.rolePermissions.canCreateRole!,
      can_update_roles: values.rolePermissions.canUpdateRole!,
      can_view_integrations: values.integrationPermissions.canViewIntegrations!,
      can_manage_integrations: values.integrationPermissions.canManageIntegrations,
      can_view_activity_logs: values.activityPermissions.canViewActivityLogs!,
      can_view_canned_messages: false,
      can_create_canned_messages: false,
      can_update_canned_messages: false
    };

    if (userContext?.user?.rolePermissions?.can_update_roles) {
      try {
        setIsRoleSaving(true);
        const response = await modifyRole(roleId!, payload);
        toast('success', `${payload.roleName} role updated successfully`);
        // if (userContext?.user?.roleId === response.data.id) {
        //   localStorage.setItem('VP_PERMISSIONS', JSON.stringify(payload));
        //   userContext?.setUser((user) => ({
        //     ...user!,
        //     rolePermissions: payload
        //   }));
        // }
        navigate('/settings?tab=roles');
      } catch (err) {
        toastError(err);
      } finally {
        setIsRoleSaving(false);
      }
    }
  };

  useEffect(() => {
    (async () => {
      const res = await getRoleById(roleId as string);
      setRoleInfo(res.data);
    })();
  }, [roleId]);

  useEffect(() => {
    form.setFieldsValue({
      roleName: roleInfo?.roleName,
      description: roleInfo?.roleDescription,
      conversationPermissions: {
        canView: roleInfo?.permissionWithCategory[0].permissions[0].hasPermission,
        canChatShare: roleInfo?.permissionWithCategory[0].permissions[1].hasPermission
      },
      assignPermissions: {
        canViewAssigned: roleInfo?.permissionWithCategory[1].permissions[0].hasPermission,
        canAssignAgents: roleInfo?.permissionWithCategory[1].permissions[1].hasPermission,
        canAssignSelf: roleInfo?.permissionWithCategory[1].permissions[2].hasPermission
      },
      customerPermissions: {
        canViewCustomers: roleInfo?.permissionWithCategory[2].permissions[0].hasPermission,
        canUpdate: roleInfo?.permissionWithCategory[2].permissions[1].hasPermission
      },
      teamPermissions: {
        canViewTeam: roleInfo?.permissionWithCategory[3].permissions[0].hasPermission,
        canAddTeam: roleInfo?.permissionWithCategory[3].permissions[1].hasPermission,
        canUpdateTeam: roleInfo?.permissionWithCategory[3].permissions[2].hasPermission,
        canDeleteTeam: roleInfo?.permissionWithCategory[3].permissions[3].hasPermission
      },
      rolePermissions: {
        canViewRole: roleInfo?.permissionWithCategory[4].permissions[0].hasPermission,
        canCreateRole: roleInfo?.permissionWithCategory[4].permissions[1].hasPermission,
        canUpdateRole: roleInfo?.permissionWithCategory[4].permissions[2].hasPermission
      },
      integrationPermissions: {
        canViewIntegrations: roleInfo?.permissionWithCategory[5].permissions[0].hasPermission,
        canManageIntegrations: roleInfo?.permissionWithCategory[5].permissions[1].hasPermission
      },
      activityPermissions: {
        canViewActivityLogs: roleInfo?.permissionWithCategory[7].permissions[0].hasPermission
      }
    });
  }, [roleInfo]);

  return (
    <StyledAddRoles>
      <Link to="/settings?tab=roles">
        <Text as="span" variant="xs" className="back">
          <Chevron /> Roles
        </Text>
      </Link>
      {roleInfo ? (
        <>
          <Text as="h1" variant="lg" weight="medium" mb="32px" className="title">
            {roleInfo?.roleName}
          </Text>
          <Form
            form={form}
            name="add-role"
            colon={false}
            autoComplete="off"
            requiredMark={false}
            onFinish={onFinishAddRole}>
            <FlexibleDiv justifyContent="space-between" mb="36px">
              <div>
                <Text as="h2" variant="lg" weight="medium" mb="4px">
                  Role details
                </Text>
                <Text variant="sm" color="var(--grey-500)" mb="0">
                  Update the details of this role here.
                </Text>
              </div>
              {userContext?.user?.rolePermissions?.can_update_roles && (
                <FlexibleDiv alignItems="center" flexGap="12px">
                  {/* <button className="btn cancel">Cancel</button> */}
                  <Button className="btn" htmlType="submit" loading={isRoleSaving}>
                    {isRoleSaving ? 'Saving' : 'Save'}
                  </Button>
                </FlexibleDiv>
              )}
            </FlexibleDiv>
            <section className="role-details">
              <FormItem
                name="roleName"
                className="formItem"
                labelAlign="left"
                labelCol={{ span: 6 }}
                label={
                  <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                    Name of role
                  </Text>
                }
                rules={[{ required: true, message: 'This field is required' }]}>
                <FormInput
                  disabled={!userContext?.user?.rolePermissions?.can_update_roles}
                  className="input"
                  placeholder="e.g, IT specialist"
                />
              </FormItem>
              <FormItem
                name="description"
                labelAlign="left"
                labelCol={{ span: 6 }}
                className="formItem description"
                label={
                  <div style={{ display: 'block' }}>
                    <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                      Role description
                    </Text>
                    <Text as="span" variant="sm" color="var(--grey-500)">
                      Describe the role
                    </Text>
                  </div>
                }
                rules={[{ required: true, message: 'This field is required' }]}>
                <FormInput
                  disabled={!userContext?.user?.rolePermissions?.can_update_roles}
                  as={FormTextArea}
                  className="input"
                  autoSize={{ minRows: 5 }}
                  placeholder="Please enter a description"
                />
              </FormItem>
              <FlexibleDiv mb="40px">
                <div className="ant-col ant-col-6">
                  <Text variant="sm" weight="medium" color="var(--grey-700)" mb="4px">
                    Team members with this role
                  </Text>
                </div>
                <div className="input">
                  {roleInfo.roleUsers.map((user) => (
                    <FlexibleDiv className="role-users" flexGap="12px" key={user.id}>
                      {user.profilePicture ? (
                        <img alt={user.lastname} src={user.profilePicture} />
                      ) : (
                        <DisplayPicture>
                          {user.firstname.charAt(0)}
                          {user.lastname.charAt(0)}
                        </DisplayPicture>
                      )}
                      <div>
                        <Text variant="md" mb="8px" className="role-name">
                          {user.firstname} {user.lastname}
                        </Text>
                        <Text variant="sm" mb="0" className="role-email">
                          {user.email}
                        </Text>
                      </div>
                    </FlexibleDiv>
                  ))}
                </div>
              </FlexibleDiv>
            </section>
            <div>
              <Text as="h2" variant="lg" weight="medium" mb="4px">
                Assign permissions
              </Text>
              <Text variant="sm" color="var(--grey-500)" mb="40px">
                Assign the permissions and access for team members with this role.
              </Text>
            </div>
            <section className="assign-perm">
              {roleInfo?.permissionWithCategory
                .filter((item) => item.category !== 'Canned message permissions')
                .map((elem, index) => (
                  <FormItem
                    key={elem.category}
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    className="assign-list"
                    label={
                      <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                        {elem.category}
                      </Text>
                    }>
                    <>
                      {elem.permissions.map((item, ind) => (
                        <FlexibleDiv
                          key={item.permissionId}
                          justifyContent="space-between"
                          className="contain">
                          <label htmlFor={checkSecondKey(item.permission)}>{item.permission}</label>
                          <FormItem
                            noStyle
                            name={[checkFirstKey(elem.category), checkSecondKey(item.permission)]}
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <VPSwitch
                              disabled={!userContext?.user?.rolePermissions?.can_update_roles}
                              id={checkSecondKey(item.permission)}
                              defaultChecked={
                                roleInfo?.permissionWithCategory[index]?.permissions[ind]
                                  ?.hasPermission
                              }
                            />
                          </FormItem>
                        </FlexibleDiv>
                      ))}
                    </>
                  </FormItem>
                ))}
            </section>
          </Form>
        </>
      ) : (
        <FlexibleDiv justifyContent="center">
          <Loading />
        </FlexibleDiv>
      )}
    </StyledAddRoles>
  );
};

export default ViewRolePage;
