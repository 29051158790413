import styled from 'styled-components';

const StyledAllTemplates = styled.section`
  flex-grow: 1;
  padding: 0 72px 0 44px;
  margin-top: 36px;
  margin-bottom: 48px;

  h1 {
    letter-spacing: -0.04em;
    margin-bottom: 12px;
  }

  .desc {
    letter-spacing: -0.01em;
    color: var(--grey-500);
    margin-bottom: 0;
  }

  .topUp-btn {
    padding: 10px 16px;
    letter-spacing: 0;
  }

  .tab-item {
    cursor: pointer;
    color: var(--grey-500);
    padding-bottom: 16px;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;

    &.active {
      color: var(--primary-500);
      border-bottom: 2px solid var(--primary-500);
    }
  }

  .filter-section {
    margin-bottom: 12px;

    .ant-select-selector {
      min-width: 164px;
    }
  }

  .category {
    text-transform: capitalize;
  }

  .send-broadcast {
    cursor: pointer;
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #7d5fff;

    span {
      padding: 8px;
    }
  }
`;

export default StyledAllTemplates;
