import gatewayInstance from './axiosGateway';
import { ResetPassword, SignIn, SignUp, SignInFB } from 'src/types/auth';

export const signupUser = async function (payload: SignUp) {
  const { data } = await gatewayInstance.post('/users/create', payload);

  return data;
};

export const signinUser = async function (payload: SignIn) {
  const { data } = await gatewayInstance.post('/auth/login', payload);

  return data;
};

export const signinFB = async function (payload: SignInFB) {
  const { data } = await gatewayInstance.post('/auth/facebook/login', payload);

  return data;
};

export const forgotPassword = async (payload: { email: string }) => {
  const { data } = await gatewayInstance.post('/auth/forgot-password', payload);

  return data;
};

export const resetPassword = async function (
  payload: Pick<ResetPassword, 'newPassword'>,
  passwordToken: string
) {
  const { data } = await gatewayInstance.post(
    `/auth/reset-password?passwordResetToken=${passwordToken}`,
    payload
  );

  return data;
};
