import styled from 'styled-components';
import { Button as Btn } from 'antd';

type ButtonProps = {
  outline?: boolean;
  fullWidth?: boolean;
  mb?: string;
  sm?: boolean;
};

const Button = styled(Btn)<ButtonProps>`
  cursor: pointer;
  height: ${({ sm }) => (sm ? '32px' : 'auto')};
  padding: ${({ sm }) => (sm ? '0px 14px' : '14px 20px')};
  border-radius: 6px;
  letter-spacing: -0.02em;
  margin-bottom: ${({ mb }) => mb && mb};
  border: ${({ outline }) => (outline ? '1px solid var(--primary-500)' : 'none')};
  background-color: ${({ outline }) => (outline ? '#fff' : 'var(--primary-500)')};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  color: ${({ outline }) => (outline ? 'var(--primary-500)' : '#fff')};
  font-size: ${({ fullWidth, sm }) => {
    if (sm) return '0.75rem';
    if (fullWidth) return '1rem';
    else return '0.875rem';
  }};
  line-height: ${({ fullWidth }) => (fullWidth ? '1.5rem' : '1.25rem')};

  &:hover,
  &:active {
    background-color: ${({ outline }) => outline && 'var(--primary-800)'};
    background-color: ${({ outline }) => (outline ? 'var(--primary-50)' : 'var(--primary-800)')};
    border: ${({ outline }) => (outline ? '1px solid var(--primary-800)' : 'none')};
  }

  &:focus {
    background-color: ${({ outline }) => (outline ? '#fff' : 'var(--primary-500)')};
  }

  &:hover,
  &:active,
  &:focus {
    color: ${({ outline }) => (outline ? 'var(--primary-800)' : '#fff')};
  }

  &:disabled {
    color: var(--grey-500);
    background-color: ${({ outline }) => (outline ? 'var(--grey-100)' : 'var(--grey-200)')};
    border: ${({ outline }) => (outline ? '1px solid var(--grey-500)' : 'none')};
  }
`;

export default Button;
