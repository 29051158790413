import gatewayInstance from './axiosGateway';

export type ActivityPayload = {
  perPage?: number;
  page?: number;
  userId?: string;
  from?: string;
  to?: string;
};

export const getActivityLogs = async (payload: ActivityPayload) => {
  const { data } = await gatewayInstance.get('/user-activities', {
    params: payload
  });

  return data.data;
};
