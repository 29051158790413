import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Form, Upload } from 'antd';
import type { UploadProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import FlexibleDiv from 'src/components/FlexableDiv';
import { Text } from 'src/components/Typography';
import { FormItem, FormInput } from 'src/components/Form';
import Button from 'src/components/Button';
import VPTable from 'src/components/VPTable';
import VPModal from 'src/components/VPModal';
import DeleteModal from 'src/components/DeleteModal';
import TablePaginationItem from 'src/utils/pagination';
import toastError from 'src/utils/toastError';
import toast from 'src/utils/toasts';
import {
  createAgent,
  deleteAgent,
  getAgents,
  reactivateAgent,
  updateAgent
} from 'src/network/team';
import StatusPills from './StatusPills';
import StyledTeam from './styled';
import Select from 'src/components/Select';
import { useAuth } from 'src/context/Auth';
import { SocketContext } from 'src/context/socketContext';
import { getRolesWithoutPagination } from 'src/network/roles';
import ReactivateModal from 'src/components/ReactivateModal';
import DisplayPicture from 'src/components/DisplayPicture';
import { AgentPayload, UpdateAgentPayload, Member } from 'src/types/team';
import { IRole } from 'src/types/roles';
import { Chevron, Download, Edit, Plus, Trash } from 'src/assets/svg';

const Team = () => {
  const [addTeamForm] = Form.useForm();
  const [editTeamForm] = Form.useForm();

  const userContext = useAuth();
  const { socket } = useContext(SocketContext);
  const [roles, setRoles] = useState<{ label: string; value: string }[]>([]);

  const [team, setTeam] = useState<Member[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [actionKey, setActionKey] = useState<Member>();

  const [isReactivateModalOpen, setIsReactivateModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentProfilePicture, setCurrentProfilePicture] = useState('');

  const getAllAgents = useCallback(async (page: number) => {
    const res = await getAgents({ page, perPage: 10 });
    return res;
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await getRolesWithoutPagination(userContext?.user?.businessId.id!);
        setRoles(
          res.data
            .map(
              (item: IRole) =>
                item.roleType !== 'SYSTEM' && { label: item.roleName, value: item._id }
            )
            .filter(Boolean)
        );
      } catch (error) {
        toastError(error);
      }
    })();
  }, [userContext?.user?.businessId, userContext?.user?.id]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await getAllAgents(currentPage);
        setCurrentPage(Number(res.current));
        setTeam(res.data);
        setTotal(res.total);
      } catch (error) {
        toastError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [getAllAgents, currentPage]);

  useEffect(() => {
    socket.on('USER_ONLINE_STATUS_EVENT', (data: { userId: number; isOnline: boolean }) => {
      setTeam((prev) => {
        const index = prev.findIndex((item) => item.id === data.userId);
        if (index !== -1) {
          prev[index].isOnline = data.isOnline;
        }
        return [...prev];
      });
    });

    return () => {
      socket.off('USER_ONLINE_STATUS_EVENT');
    };
  }, [socket]);

  useEffect(() => {
    addTeamForm.resetFields();
  }, [isAddModalOpen]);

  const columns: ColumnsType<Member> = [
    {
      title: 'Name',
      dataIndex: 'user',
      render: (user, record) => (
        <FlexibleDiv flexGap="12px" alignItems="center">
          {record.profilePicture ? (
            <img className="team-img" alt={record.lastname} src={record.profilePicture} />
          ) : (
            <DisplayPicture>
              {record.firstname.charAt(0)}
              {record.lastname.charAt(0)}
            </DisplayPicture>
          )}
          {record.firstname} {record.lastname}
        </FlexibleDiv>
      )
    },
    {
      title: 'Email address',
      dataIndex: 'email'
    },
    {
      title: 'Status',
      dataIndex: 'isOnline',
      render: (status, record) => <StatusPills status={record.isOnline} />
    },
    {
      title: 'Role',
      dataIndex: 'accountType',
      render: (role, record) => `${record.roleId.roleName}`,
      className: 'text-capitalize'
    },
    {
      title: 'Date added',
      dataIndex: 'createdAt',
      render: (date, record) => DateTime.fromISO(record.createdAt).toFormat('LLL dd, yyyy')
    }
  ];

  if (
    userContext?.user?.rolePermissions?.can_update_team_members_details &&
    userContext?.user?.rolePermissions?.can_delete_team_members
  ) {
    columns.push({
      title: '',
      className: 'actions',
      render: (text, record, index) => {
        return (
          <FlexibleDiv flexGap="8px" alignItems="center" justifyContent="flex-end">
            <div
              className="action"
              onClick={() => {
                setIsDeleteModalOpen(true);
                setActionKey(record);
              }}>
              <Trash />
            </div>
            <div
              className="action"
              onClick={() => {
                editTeamForm.setFieldsValue({
                  firstname: record.firstname,
                  lastname: record.lastname,
                  email: record.email,
                  roleId: roles.find((item) => item.value === record.roleId._id),
                  accountType: record.accountType,
                  profilePicture: record.profilePicture || ''
                });
                setActionKey(record);
                setIsEditModalOpen(true);
                setCurrentProfilePicture(record?.profilePicture || '');
              }}>
              <Edit />
            </div>
          </FlexibleDiv>
        );
      }
    });
  }

  if (
    userContext?.user?.rolePermissions?.can_update_team_members_details &&
    !userContext?.user?.rolePermissions?.can_delete_team_members
  ) {
    columns.push({
      title: '',
      className: 'actions',
      render: (text, record, index) => {
        return (
          <FlexibleDiv flexGap="8px" alignItems="center" justifyContent="flex-end">
            <div
              className="action"
              onClick={() => {
                editTeamForm.setFieldsValue({
                  firstname: record.firstname,
                  lastname: record.lastname,
                  email: record.email,
                  // roleId: record.roleId,
                  accountType: record.accountType,
                  profilePicture: record.profilePicture || ''
                });
                setActionKey(record);
                setIsEditModalOpen(true);
                setCurrentProfilePicture(record?.profilePicture || '');
              }}>
              <Edit />
            </div>
          </FlexibleDiv>
        );
      }
    });
  }

  if (
    !userContext?.user?.rolePermissions?.can_update_team_members_details &&
    userContext?.user?.rolePermissions?.can_delete_team_members
  ) {
    columns.push({
      title: '',
      className: 'actions',
      render: (text, record, index) => {
        return (
          <FlexibleDiv flexGap="8px" alignItems="center" justifyContent="flex-end">
            <div
              className="action"
              onClick={() => {
                setIsDeleteModalOpen(true);
                setActionKey(record);
              }}>
              <Trash />
            </div>
          </FlexibleDiv>
        );
      }
    });
  }

  const submitAddTeamForm = async (values: AgentPayload) => {
    try {
      const res = await createAgent(values);
      const agentRes = await getAllAgents(currentPage);
      setTeam(agentRes.data);
      setIsAddModalOpen(false);
      toast('success', 'Agent added successfully');
    } catch (error: any) {
      if (error.response.status === 409) {
        setActionKey(error.response.data.data);
        setIsReactivateModalOpen(error.response.status === 409);
        setIsAddModalOpen(false);
        return;
      }
      toastError(error);
    }
  };

  const submitEditTeamForm = async (values: UpdateAgentPayload) => {
    const payload = { ...values, userId: actionKey?.id as string };
    try {
      const res = await updateAgent(payload);
      toast('success', 'Agent updated successfully');
      const agentRes = await getAllAgents(currentPage);
      setTeam(agentRes.data);
      setIsEditModalOpen(false);
    } catch (error) {
      toastError(error);
    }
  };

  const uploadProfilePicture: UploadProps = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        toast('success', `${info.file.name} file uploaded successfully.`);
        setCurrentProfilePicture(info.file.response);
        editTeamForm.setFieldsValue({
          profilePicture: info.file.response
        });
      } else if (status === 'error') {
        toast('error', `${info.file.name} file upload failed.`);
      }
    },
    action: `${process.env.REACT_APP_VIPI_API_URL}media/create`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('VP_TOKEN')}`
    },
    onRemove() {
      setCurrentProfilePicture('');
      editTeamForm.setFieldsValue({
        profilePicture: ''
      });
    }
  };

  return (
    <StyledTeam>
      <section>
        <FlexibleDiv
          wrap="wrap"
          className="table-header"
          alignItems="center"
          justifyContent="space-between">
          <FlexibleDiv flexGap="12px" alignItems="center">
            <Text as="h2" variant="lg" weight="medium" mb="0">
              Team members
            </Text>
            <div className="user-number">
              <Text as="span" variant="xs" weight="medium">
                {team.length} users
              </Text>
            </div>
          </FlexibleDiv>
          <FlexibleDiv flexGap="12px" alignItems="center">
            {/* <button className="button">
              <FlexibleDiv alignItems="center" flexGap="8px">
                <Download />
                <span>Download CSV</span>
              </FlexibleDiv>
            </button> */}
            {userContext?.user?.rolePermissions?.can_add_team_members && (
              <Button icon={<Plus />} className="add-user" onClick={() => setIsAddModalOpen(true)}>
                Add user
              </Button>
            )}
          </FlexibleDiv>
        </FlexibleDiv>
        <VPTable
          removeTop
          noSelect
          loading={loading}
          dataSource={team}
          columns={columns as ColumnsType<object>}
          pagination={TablePaginationItem(total, 10, setCurrentPage)}
        />
      </section>
      <VPModal
        centered
        closable={false}
        className="form-modal"
        visible={isAddModalOpen}
        footer={<></>}>
        <Text variant="lg" weight="medium" mb="20px">
          Add a new team member
        </Text>
        <Form
          name="addTeamMember"
          autoComplete="off"
          layout="vertical"
          requiredMark={false}
          validateTrigger={['onChange', 'onBlur']}
          form={addTeamForm}
          onFinish={submitAddTeamForm}>
          <FormItem
            mb="16px"
            name="firstname"
            label={
              <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                First name
              </Text>
            }
            rules={[{ required: true, message: 'This field is required' }]}>
            <FormInput />
          </FormItem>
          <FormItem
            mb="16px"
            name="lastname"
            label={
              <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                Last name
              </Text>
            }
            rules={[{ required: true, message: 'This field is required' }]}>
            <FormInput />
          </FormItem>
          <FormItem
            mb="16px"
            name="email"
            label={
              <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                Email address
              </Text>
            }
            rules={[
              { required: true, message: 'This field is required' },
              { type: 'email', message: 'Please enter a valid email address' }
            ]}>
            <FormInput type="email" />
          </FormItem>
          <FormItem
            mb="16px"
            name="roleId"
            label={
              <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                Role
              </Text>
            }
            rules={[{ required: true, message: 'This field is required' }]}>
            <Select suffixIcon={<Chevron />} options={roles} />
          </FormItem>
          <FlexibleDiv flexGap="12px" alignItems="center">
            <button className="btn cancel" onClick={() => setIsAddModalOpen(false)}>
              Cancel
            </button>
            <Button htmlType="submit" className="btn submit">
              Add team member
            </Button>
          </FlexibleDiv>
        </Form>
      </VPModal>
      <VPModal
        centered
        closable={false}
        className="delete-modal"
        visible={isDeleteModalOpen}
        footer={<></>}>
        <DeleteModal
          title="team member"
          name={`${actionKey?.firstname} ${actionKey?.lastname}`}
          onCancelClick={() => setIsDeleteModalOpen(false)}
          onDeleteClick={() => {
            (async () => {
              try {
                await deleteAgent(actionKey?.id as string);
                setIsDeleteModalOpen(false);
                setTeam(team.filter((item) => item.id !== actionKey?.id));
                setIsDeleteModalOpen(false);
                toast('success', 'Agent deleted successfully');
              } catch (error) {
                toastError(error);
              }
            })();
          }}
        />
      </VPModal>
      <VPModal
        centered
        closable={false}
        className="delete-modal"
        visible={isReactivateModalOpen}
        footer={<></>}>
        <ReactivateModal
          onCancelClick={() => {
            setIsReactivateModalOpen(false);
          }}
          onReactivate={async () => {
            try {
              let { data } = await reactivateAgent(actionKey?.id as string);
              const agentRes = await getAllAgents(currentPage);
              setTeam(agentRes.data);
              toast('success', 'Agent reactivated successfully');
              setIsReactivateModalOpen(false);
            } catch (error) {
              toastError(error);
            }
          }}
        />
      </VPModal>
      <VPModal
        centered
        closable={false}
        className="form-modal"
        visible={isEditModalOpen}
        footer={<></>}>
        <Text variant="lg" weight="medium" mb="20px">
          Edit {actionKey?.firstname} {actionKey?.lastname}
        </Text>
        <Form
          name="editTeamMember"
          autoComplete="off"
          layout="vertical"
          requiredMark={false}
          validateTrigger={['onChange', 'onBlur']}
          form={editTeamForm}
          onFinish={submitEditTeamForm}>
          <FormItem name="profilePicture">
            <FlexibleDiv flexGap="12px" alignItems="center" className="profilePicture-upload">
              {currentProfilePicture && (
                <img
                  className="image"
                  src={currentProfilePicture}
                  alt={`${actionKey?.firstname} ${actionKey?.lastname}`}
                />
              )}
              <Upload {...uploadProfilePicture}>
                <div className="upload-section">
                  <Text variant="sm" weight="medium">
                    Click to upload
                  </Text>
                </div>
              </Upload>
            </FlexibleDiv>
          </FormItem>
          <FormItem
            mb="16px"
            name="firstname"
            label={
              <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                First name
              </Text>
            }
            rules={[{ required: true, message: 'This field is required' }]}>
            <FormInput />
          </FormItem>
          <FormItem
            mb="16px"
            name="lastname"
            label={
              <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                Last name
              </Text>
            }
            rules={[{ required: true, message: 'This field is required' }]}>
            <FormInput />
          </FormItem>
          <FormItem
            mb="16px"
            name="email"
            label={
              <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                Email address
              </Text>
            }
            rules={[
              { required: true, message: 'This field is required' },
              { type: 'email', message: 'Please enter a valid email address' }
            ]}>
            <FormInput type="email" />
          </FormItem>
          <FormItem
            mb="16px"
            name="roleId"
            label={
              <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                Role
              </Text>
            }
            rules={[{ required: true, message: 'This field is required' }]}>
            <Select suffixIcon={<Chevron />} options={roles} />
          </FormItem>
          <FlexibleDiv flexGap="12px" alignItems="center">
            <button
              type="button"
              className="btn cancel"
              onClick={() => {
                setActionKey(undefined);
                editTeamForm.resetFields();
                setIsEditModalOpen(false);
              }}>
              Cancel
            </button>
            <Button htmlType="submit" className="btn submit">
              Save
            </Button>
          </FlexibleDiv>
        </Form>
      </VPModal>
    </StyledTeam>
  );
};

export default Team;
