import styled from 'styled-components';

const StyledProfile = styled.section`
  .profile-sections {
    border-bottom: 1px solid var(--grey-200);
    padding-top: 48px;
    padding-bottom: 20px;

    &:first-child {
      padding-top: 0;
    }
  }

  .section-header {
    max-width: 1064px;
  }

  .btn {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 11px 16px;

    &.cancel {
      padding: 10px 16px;
      cursor: pointer;
      color: var(--grey-700);
      background: #ffffff;
      border: 1px solid var(--grey-300);
      border-radius: 8px;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    }
  }

  .input {
    max-width: 512px;
  }

  .drag-section {
    .description {
      color: var(--grey-500);
      margin-bottom: 0;
      margin-right: 32px;
    }

    .input {
      flex-grow: 1;

      & > div > span {
        flex-grow: 1;
      }
    }
  }

  .dragger {
    border: none;

    .ant-upload.ant-upload-btn {
      padding: 16px 24px;
      background: #fff;
      border-radius: 8px;
      border: 1px dashed var(--grey-200);
    }
  }

  .image {
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }

  .name-field {
    .ant-form-item-explain.ant-form-item-explain-connected {
      display: flex;
      gap: 20px;
      max-width: 512px;

      & > div {
        flex: 1;
      }
    }
  }
`;

export default StyledProfile;
