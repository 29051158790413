import React, { useCallback, useEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import useDebounce from 'src/hooks/useDebounce';
import FlexibleDiv from 'src/components/FlexableDiv';
import { Text } from 'src/components/Typography';
import { FormInput } from '../Form';
import { GetQuickRepliesPayload, getQuickReplies } from 'src/network/quickReplies';
import toastError from 'src/utils/toastError';
import { IQuickReply } from 'src/types/quickReplies';
import { CloseIcon } from 'src/assets/svg';
import StyledQuickReplies from './styled';
import Loading from '../Loading';

type QuickRepliesProps = {
  onClose: () => void;
  onReplyClick: (msg: string) => void;
};

const ChatQuickReplies = ({ onClose, onReplyClick }: QuickRepliesProps) => {
  const repliesListRef = useRef<HTMLUListElement>(null);

  const [loading, setLoading] = useState(false);
  const [loadingMoreReplies, setLoadingMoreReplies] = useState(false);

  const [quickReplies, setQuickReplies] = useState<IQuickReply[]>([]);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<number>();
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const fetchQuickReplies = useCallback(async (payload: GetQuickRepliesPayload) => {
    setLoading(true);
    try {
      const res = await getQuickReplies(payload);
      setQuickReplies(res.data);
      setTotal(res.total);
      setPage(res.current);
    } catch (error) {
      toastError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchQuickReplies({ page: 1, search: debouncedSearch.trim() });
  }, [debouncedSearch]);

  useEffect(() => {
    const list = repliesListRef.current;

    const handleScroll = async () => {
      if (!list) return;

      const { scrollTop, scrollHeight, clientHeight } = list;
      if (scrollTop + clientHeight >= scrollHeight) {
        if (quickReplies.length < total!) {
          try {
            setLoadingMoreReplies(true);
            const res = await getQuickReplies({ page: page + 1, search: debouncedSearch.trim() });
            flushSync(() => {
              setPage(res.current);
              setQuickReplies((prev) => [...prev, ...res.data]);
            });
          } catch (err) {
            toastError(err);
          } finally {
            setLoadingMoreReplies(false);
          }
        }
      }
    };

    list?.addEventListener('scroll', handleScroll);

    return () => {
      list?.removeEventListener('scroll', handleScroll);
    };
  }, [quickReplies]);

  return (
    <StyledQuickReplies>
      <FlexibleDiv flexGap="8px" alignItems="center" className="header">
        <div className="close-icon" onClick={onClose}>
          <CloseIcon />
        </div>
        <Text variant="md" className="title">
          Quick replies
        </Text>
      </FlexibleDiv>
      <section className="main">
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}>
          <FormInput
            height="36px"
            placeholder="Search..."
            className="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </form>
        {!loading ? (
          <>
            <ul className="reply-list" ref={repliesListRef}>
              {quickReplies.length > 0 &&
                quickReplies.map((elem) => (
                  <li key={elem.id} onClick={() => onReplyClick(elem.message)}>
                    <Text variant="sm" className="reply-title">
                      {elem.name}
                    </Text>
                    <Text variant="sm" className="reply-msg">
                      {elem.message}
                    </Text>
                  </li>
                ))}
              {loadingMoreReplies && (
                <FlexibleDiv justifyContent="center">
                  <Loading />
                </FlexibleDiv>
              )}
            </ul>
          </>
        ) : (
          <FlexibleDiv justifyContent="center">
            <Loading />
          </FlexibleDiv>
        )}
      </section>
    </StyledQuickReplies>
  );
};

export default ChatQuickReplies;
