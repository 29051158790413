import React from 'react';
import { message } from 'antd';
import { Check, Info } from 'src/assets/svg';

const toast = (type: 'success' | 'error', text: string) => {
  if (type === 'success') {
    message.success({
      content: text,
      className: 'toast-success',
      icon: <Check />
    });
  }
  if (type === 'error') {
    message.error({
      content: text,
      className: 'toast-error',
      icon: <Info />,
      duration: 5
    });
  }
};

export default toast;
