import React, { useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import VPTable from 'src/components/VPTable';
import VPDrawer from 'src/components/VPDrawer';
import NewBroadcast from 'src/components/NewBroadcast';
import { Text } from 'src/components/Typography';
import TemplateStatus from './TemplatesStatus';
import { getTemplates, GetTemplatesPayload, SendBroadcastPayload } from 'src/network/templates';
import TablePaginationItem from 'src/utils/pagination';
import { trimString } from 'src/utils/app';
import toastError from 'src/utils/toastError';
import { ITemplates } from 'src/types/templates';
import SelectContacts from 'src/components/SelectContacts';
import TopUpWalletDrawer from 'src/components/TopUpWalletDrawer';
import StyledSendBroadcast from './StyledSendBroadcast';
import BroadcastSummary from 'src/components/BroadcastSummary';
import Select from 'src/components/Select';
import { Chevron } from 'src/assets/svg';
import { TEMPLATES_CATEGORY } from 'src/constants';

const Templates = () => {
  const [filter, setFilter] = useState('');

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<number>();
  const [tableLoading, setTableLoading] = useState(false);

  const [activeDrawer, setActiveDrawer] = useState('newBroadCast');

  const [templates, setTemplates] = useState<ITemplates[]>([]);

  const [activeBroadcast, setActiveBroadcast] = useState<ITemplates | null>(null);
  const [broadcastPayload, setBroadcastPayload] = useState<SendBroadcastPayload>({});
  const [showSendBroadcastDrawer, setShowSendBroadcastDrawer] = useState(false);

  const [totalCustomers, setTotalCustomers] = useState(0);

  const fetchTemplates = async (params: GetTemplatesPayload) => {
    setTableLoading(true);

    try {
      const res = await getTemplates(params);
      setPage(Number(res.current));
      setTotal(res.total);
      setTemplates(res.templates);
    } catch (error) {
      toastError(error);
    } finally {
      setTableLoading(false);
    }
  };

  useEffect(() => {
    const payload: GetTemplatesPayload = {
      page,
      perPage: 15,
      name: ''
    };

    if (filter) {
      payload['category'] = filter;
    }

    fetchTemplates(payload);
  }, [page, filter]);

  const columns: ColumnsType<ITemplates> = [
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Category',
      className: 'category',
      render: (status, record) => record.category.replace('_', ' ').toLowerCase()
    },
    {
      title: 'Preview',
      render: (status, record) => trimString(record.bodyText, 82)
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status, record) => <TemplateStatus status={record.status} />
    },
    {
      title: '',
      render: (role, record) => (
        <span
          onClick={() => {
            setActiveBroadcast(record);
            setShowSendBroadcastDrawer(true);
          }}>
          Publish
        </span>
      ),
      className: 'send-broadcast'
    }
  ];

  const closeDrawer = () => {
    setShowSendBroadcastDrawer(false);
    setActiveBroadcast(null);
    setActiveDrawer('newBroadCast');
    setBroadcastPayload({});
    setTotalCustomers(0);
  };

  return (
    <>
      <section className="filter-section">
        <Text variant="xs" color="var(--grey-500)" mb="8px">
          Filter by
        </Text>
        <Select
          sm
          suffixIcon={<Chevron />}
          value={filter}
          options={[{ label: 'All', value: '' }, ...TEMPLATES_CATEGORY]}
          onChange={(val: any) => setFilter(val)}
        />
      </section>
      <VPTable
        loading={tableLoading}
        noSelect
        // @ts-ignore
        columns={columns}
        dataSource={templates}
        pagination={TablePaginationItem(total, 15, setPage)}
      />
      <VPDrawer visible={showSendBroadcastDrawer} onClose={() => closeDrawer()}>
        <StyledSendBroadcast>
          {activeDrawer === 'newBroadCast' && (
            <NewBroadcast
              onClose={() => closeDrawer()}
              setBroadcastPayload={setBroadcastPayload}
              activeBroadcast={activeBroadcast}
              setActiveDrawer={setActiveDrawer}
            />
          )}
          {activeDrawer === 'selectContacts' && (
            <SelectContacts
              totalCustomers={totalCustomers}
              setTotalCustomers={setTotalCustomers}
              setActiveDrawer={setActiveDrawer}
              setBroadcastPayload={setBroadcastPayload}
            />
          )}
          {activeDrawer === 'broadcastSummary' && (
            <BroadcastSummary
              totalCustomers={totalCustomers}
              setActiveDrawer={setActiveDrawer}
              broadcastPayload={broadcastPayload}
              onClose={() => closeDrawer()}
            />
          )}
          {activeDrawer === 'topUpWalletDrawer' && (
            <TopUpWalletDrawer setActiveDrawer={setActiveDrawer} />
          )}
        </StyledSendBroadcast>
      </VPDrawer>
    </>
  );
};

export default Templates;
