import styled from 'styled-components';

const StyledAssignedModal = styled.section`
  .search {
    margin-bottom: 12px;
  }

  .agents-list {
    margin-bottom: 28px;
    height: 210px;
    overflow-y: auto;
  }
`;

export default StyledAssignedModal;
