import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import Profile from 'src/components/SettingsTabs/Profile';
import Team from 'src/components/SettingsTabs/Team';
import Plan from 'src/components/SettingsTabs/Plan';
import Billing from 'src/components/SettingsTabs/Billing';
import BankAccount from 'src/components/SettingsTabs/BankAccount';
import Integrations from 'src/components/SettingsTabs/Integrations';
import Automations from 'src/components/SettingsTabs/Automations';
import AccountSettings from 'src/components/SettingsTabs/AccountSettings';
import Roles from 'src/components/SettingsTabs/Roles';
import ActivityLogs from 'src/components/SettingsTabs/ActivityLogs';
import FlexibleDiv from 'src/components/FlexableDiv';
import { Display } from 'src/components/Typography';
import StyledSettings from './styled';
import { useAuth } from 'src/context/Auth';
import QuickReplies from 'src/components/SettingsTabs/QuickReplies';
import { Helmet } from 'react-helmet-async';

const AllSettingsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userContext = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(
    searchParams.get('tab') || location.state || 'profile'
  );

  const TABS = [
    // { name: "Plan", component: Plan },
    // { name: "Billing", component: Billing },
    // { name: "Bank account", component: BankAccount },
    // { name: 'Integrations', component: Integrations},
    // { name: "Automations", component: Automations },
    // { name: "Account settings", component: AccountSettings },
  ];

  if (userContext?.user?.rolePermissions?.can_view_team_members) {
    TABS.push({ title: 'Team', name: 'team', component: Team });
  }
  if (userContext?.user?.rolePermissions?.can_view_roles) {
    TABS.push({ title: 'Roles', name: 'roles', component: Roles });
  }
  TABS.push({ title: 'Quick Replies', name: 'quick-replies', component: QuickReplies });

  if (userContext?.user?.rolePermissions?.can_view_activity_logs) {
    TABS.push({ title: 'Activity', name: 'activity', component: ActivityLogs });
  }

  return (
    <StyledSettings>
      <Helmet>
        <title>Vipichat | Settings</title>
      </Helmet>
      <Display as="h1" variant="xs" weight="medium">
        Settings
      </Display>
      <FlexibleDiv as="nav" flexGap="20px" alignItems="center" mb="24px">
        <div
          onClick={() => {
            setSearchParams({ tab: 'Profile' });
            setActiveTab('profile');
          }}
          className={`tab-item ${activeTab === 'profile' ? 'active' : ''}`}>
          Profile
        </div>
        {userContext?.user?.rolePermissions?.can_view_integrations && (
          <div className="tab-item" onClick={() => navigate('/settings/integrations')}>
            Integrations
          </div>
        )}
        {TABS.map((tab) => (
          <div
            key={tab.name}
            onClick={() => {
              setSearchParams({ tab: tab.name });
              setActiveTab(tab.name);
            }}
            className={`tab-item ${activeTab === tab.name ? 'active' : ''}`}>
            {tab.title}
          </div>
        ))}
      </FlexibleDiv>
      {activeTab === 'profile' && <Profile />}
      {TABS.map((tab) => tab.name === activeTab && <tab.component key={tab.name} />)}
    </StyledSettings>
  );
};

export default AllSettingsPage;
