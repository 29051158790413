import React from 'react';
import styled from 'styled-components';

const StyledBadge = styled.div`
  padding: 2px 8px;
  display: inline-flex;
  gap: 6px;
  align-items: center;
  border-radius: 16px;
  background: var(--success-50);

  .dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: var(--success-500);
  }

  span.text {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #027a48;
  }
`;

const ActiveBadge = () => {
  return (
    <StyledBadge>
      <span className="dot"></span>
      <span className="text">Active</span>
    </StyledBadge>
  );
};

export default ActiveBadge;
