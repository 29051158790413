import styled from 'styled-components';

const DeleteModalStyles = styled.div`
  .confirmation-text {
    color: var(--grey-500);
    margin-bottom: 32px;

    span {
      font-weight: 500;
    }
  }

  .btn {
    flex: 1;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

    &.cancel {
      border: 1px solid var(--grey-300);
      background: #fff;
    }

    &.delete {
      border: none;
      background: var(--error-500);
      color: #ffffff;
    }
  }
`;

export default DeleteModalStyles;
