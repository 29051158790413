import toast from './toasts';

const toastError = (err: any) => {
  if (localStorage.getItem('VP_TOKEN')) {
    toast('error', err.response.data.error || 'Something went wrong');
  }
};

export const toastErrorUnauthScreens = (err: any) => {
  toast('error', err.response.data.error || 'Something went wrong');
};

export default toastError;
