import React from 'react';
import styled from 'styled-components';
import FlexibleDiv from 'src/components/FlexableDiv';
import { Text } from 'src/components/Typography';

type IReactivateProps = {
  onCancelClick: () => void;
  onReactivate: () => void;
};

const ReactivateModalStyles = styled.div`
  .confirmation-text {
    color: var(--grey-500);
    margin-bottom: 32px;

    span {
      font-weight: 500;
    }
  }

  .btn {
    flex: 1;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

    &.cancel {
      border: 1px solid var(--grey-300);
      background: #fff;
    }

    &.delete {
      border: none;
      background: var(--primary-500);
      color: #ffffff;
    }
  }
`;

const ReactivateModal = ({ onCancelClick, onReactivate }: IReactivateProps) => {
  return (
    <ReactivateModalStyles>
      <Text as="h1" mb="8px" variant="lg" weight="medium">
        Re-activate Agent
      </Text>
      <Text variant="md" className="confirmation-text">
        Agent account has been deleted, do you want to reactivate their account?
      </Text>
      <FlexibleDiv flexGap="12px" alignItems="center">
        <button className="btn cancel" onClick={onCancelClick}>
          Cancel
        </button>
        <button className="btn delete" onClick={onReactivate}>
          Re-activate
        </button>
      </FlexibleDiv>
    </ReactivateModalStyles>
  );
};

export default ReactivateModal;
