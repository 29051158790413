import React, { useState } from 'react';
import { Form, Upload } from 'antd';
import type { UploadProps } from 'antd';
import { updateBusinessInfo, updatePassword, updateUserProfile } from 'src/network/profile';
import { useAuth } from 'src/context/Auth';
import toast from 'src/utils/toasts';
import toastError from 'src/utils/toastError';
import FlexibleDiv from 'src/components/FlexableDiv';
import { Text } from 'src/components/Typography';
import Button from 'src/components/Button';
import { FormInput, FormItem } from 'src/components/Form';
import { BusinessInfo, ProfileInfo, User } from 'src/types/user';
import { UploadCloud } from 'src/assets/svg';
import StyledProfile from './styled';

const { Dragger } = Upload;

const Profile = () => {
  const userContext = useAuth();
  const [changePwdForm] = Form.useForm();
  const [personalInfoSaving, setPersonalInfoSaving] = useState(false);
  const [businessInfoSaving, setBusinessInfoSaving] = useState(false);
  const [changePwdSaving, setChangePwdSaving] = useState(false);

  const localStorageData: User = JSON.parse(localStorage.getItem('VP_USER') as string);

  const localStoragePermissions: any = JSON.parse(localStorage.getItem('VP_PERMISSIONS') as string);

  const [personalInfoImg, setPersonalInfoImg] = useState(localStorageData?.profilePicture || '');

  const [businessInfoImg, setBusinessInfoImg] = useState(
    localStorageData?.businessId?.businessLogo || ''
  );

  const onFinishChangePassword = async (values: {
    'current-password': string;
    'new-password': string;
    'confirm-password': string;
  }) => {
    try {
      setChangePwdSaving(true);
      const res = await updatePassword(values['new-password']);
      toast('success', 'Password updated successfully.');
      changePwdForm.resetFields();
    } catch (error) {
      toastError(error);
    } finally {
      setChangePwdSaving(false);
    }
  };

  const onFinishBusiness = async (values: BusinessInfo) => {
    const payload = {
      ...values,
      businessLogo: businessInfoImg
    };

    const newlocalStorageData = {
      ...localStorageData,
      businessId: {
        ...localStorageData.businessId,
        ...payload
      },
      rolePermissions: { ...localStoragePermissions }
    };

    try {
      setBusinessInfoSaving(true);
      const res = await updateBusinessInfo(payload);
      toast('success', 'Business info updated successfully.');
      userContext?.setUser(newlocalStorageData);
      localStorage.setItem('VP_USER', JSON.stringify(newlocalStorageData));
    } catch (error) {
      toastError(error);
    } finally {
      setBusinessInfoSaving(false);
    }
  };

  const onFinishPersonal = async (values: ProfileInfo) => {
    const payload = {
      firstname: values.name.first,
      lastname: values.name.last,
      email: values.email,
      profilePicture: personalInfoImg
    };

    const newlocalStorageData = {
      ...localStorageData,
      ...payload,
      rolePermissions: { ...localStoragePermissions }
    };

    try {
      setPersonalInfoSaving(true);
      const res = await updateUserProfile(payload);
      toast('success', 'Personal info updated successfully.');
      userContext?.setUser(newlocalStorageData);
      localStorage.setItem('VP_USER', JSON.stringify(newlocalStorageData));
    } catch (error) {
      toastError(error);
    } finally {
      setPersonalInfoSaving(false);
    }
  };

  const draggerProps = (type: 'personal' | 'business'): UploadProps => {
    return {
      name: 'file',
      multiple: false,
      maxCount: 1,
      onChange(info) {
        const { status } = info.file;
        if (status === 'done') {
          toast('success', `${info.file.name} file uploaded successfully.`);
          if (type === 'personal') setPersonalInfoImg(info.file.response);
          if (type === 'business') setBusinessInfoImg(info.file.response);
        } else if (status === 'error') {
          toast('error', `${info.file.name} file upload failed.`);
        }
      },
      action: `${process.env.REACT_APP_VIPI_API_URL}media/create`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('VP_TOKEN')}`
      },
      onRemove() {
        if (type === 'personal') setPersonalInfoImg('');
        if (type === 'business') setBusinessInfoImg('');
      }
    };
  };

  const initPersonalInfoVal = {
    name: {
      first: userContext?.user?.firstname,
      last: userContext?.user?.lastname
    },
    email: userContext?.user?.email
  };

  const initBusinessInfoVal = {
    businessName: userContext?.user?.businessId.businessName || '',
    businessEmail: userContext?.user?.businessId.businessEmail || ''
  };

  return (
    <StyledProfile>
      <section className="profile-sections">
        <Form
          name="personal-info"
          colon={false}
          autoComplete="off"
          requiredMark={false}
          onFinish={onFinishPersonal}
          initialValues={initPersonalInfoVal}>
          <FlexibleDiv className="section-header" justifyContent="space-between" mb="36px">
            <div>
              <Text as="h2" variant="lg" weight="medium" mb="4px">
                Personal info
              </Text>
              <Text variant="sm" color="var(--grey-500)" mb="0">
                Update your photo and personal details here.
              </Text>
            </div>
            <FlexibleDiv alignItems="center" flexGap="12px">
              {/* <button className="btn cancel">Cancel</button> */}
              <Button className="btn" htmlType="submit" loading={personalInfoSaving}>
                {personalInfoSaving ? 'Saving' : 'Save'}
              </Button>
            </FlexibleDiv>
          </FlexibleDiv>
          <FormItem
            mb="40px"
            labelAlign="left"
            labelCol={{ span: 6 }}
            className="name-field"
            label={
              <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                Name
              </Text>
            }>
            <FlexibleDiv flexGap="20px" className="input">
              <FormItem
                noStyle
                name={['name', 'first']}
                rules={[{ required: true, message: 'This field is required' }]}>
                <FormInput />
              </FormItem>
              <FormItem
                noStyle
                name={['name', 'last']}
                rules={[{ required: true, message: 'This field is required' }]}>
                <FormInput />
              </FormItem>
            </FlexibleDiv>
          </FormItem>
          <FormItem
            mb="40px"
            name="email"
            labelAlign="left"
            labelCol={{ span: 6 }}
            label={
              <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                Email address
              </Text>
            }
            rules={[
              { required: true, message: 'This field is required' },
              { type: 'email', message: 'Invalid email' }
            ]}>
            <FormInput className="input" type="email" />
          </FormItem>
          <FlexibleDiv className="drag-section">
            <div className="ant-col ant-col-6">
              <Text variant="sm" weight="medium" color="var(--grey-700)" mb="4px">
                Your photo
              </Text>
              <Text variant="sm" className="description">
                This will be displayed on your profile
              </Text>
            </div>
            <div className="input">
              <FlexibleDiv flexGap="20px">
                {personalInfoImg && <img src={personalInfoImg} alt="profile" className="image" />}
                <Dragger {...draggerProps('personal')} className="dragger">
                  <div className="ant-upload-drag-icon">
                    <UploadCloud />
                  </div>
                  <Text variant="sm" color="var(--grey-500)">
                    <Text as="span" variant="sm" weight="medium" color="var(--primary-500)">
                      Click to upload
                    </Text>{' '}
                    or drag and drop
                  </Text>
                  <Text variant="xs" color="var(--grey-500)">
                    SVG, PNG, JPG or GIF (max. 800x400px)
                  </Text>
                </Dragger>
              </FlexibleDiv>
            </div>
          </FlexibleDiv>
        </Form>
      </section>
      <section className="profile-sections">
        <Form
          name="passwords"
          form={changePwdForm}
          colon={false}
          autoComplete="off"
          requiredMark={false}
          onFinish={onFinishChangePassword}>
          <FlexibleDiv className="section-header" justifyContent="space-between" mb="36px">
            <div>
              <Text as="h2" variant="lg" weight="medium" mb="4px">
                Password
              </Text>
              <Text variant="sm" color="var(--grey-500)" mb="0">
                Please enter your current password to change your password.
              </Text>
            </div>
            <FlexibleDiv alignItems="center" flexGap="12px">
              {/* <button className="btn cancel">Cancel</button> */}
              <Button className="btn" htmlType="submit" loading={changePwdSaving}>
                {changePwdSaving ? 'Saving' : 'Save'}
              </Button>
            </FlexibleDiv>
          </FlexibleDiv>
          <FormItem
            mb="40px"
            name="current-password"
            labelAlign="left"
            labelCol={{ span: 6 }}
            label={
              <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                Current password
              </Text>
            }
            rules={[{ required: true, message: 'This field is required' }]}>
            <FormInput className="input" type="password" />
          </FormItem>
          <FormItem
            mb="40px"
            name="new-password"
            labelAlign="left"
            labelCol={{ span: 6 }}
            label={
              <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                New password
              </Text>
            }
            rules={[
              { required: true, message: 'This field is required' },
              {
                min: 8,
                message: 'Password must be at least 8 characters'
              }
            ]}>
            <FormInput className="input" type="password" />
          </FormItem>
          <FormItem
            mb="0"
            name="confirm-password"
            dependencies={['new-password']}
            labelAlign="left"
            labelCol={{ span: 6 }}
            label={
              <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                Confirm password
              </Text>
            }
            rules={[
              { required: true, message: 'This field is required' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new-password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Passwords do not match'));
                }
              })
            ]}>
            <FormInput className="input" type="password" />
          </FormItem>
        </Form>
      </section>
      <section className="profile-sections">
        <Form
          name="business-info"
          colon={false}
          autoComplete="off"
          requiredMark={false}
          onFinish={onFinishBusiness}
          initialValues={initBusinessInfoVal}>
          <FlexibleDiv className="section-header" justifyContent="space-between" mb="36px">
            <div>
              <Text as="h2" variant="lg" weight="medium" mb="4px">
                Business info
              </Text>
              <Text variant="sm" color="var(--grey-500)" mb="0">
                Update your photo and business details here.
              </Text>
            </div>
            <FlexibleDiv alignItems="center" flexGap="12px">
              {/* <button className="btn cancel">Cancel</button> */}
              <Button className="btn" htmlType="submit" loading={businessInfoSaving}>
                {businessInfoSaving ? 'Saving' : 'Save'}
              </Button>
            </FlexibleDiv>
          </FlexibleDiv>
          <FormItem
            mb="40px"
            name="businessName"
            labelAlign="left"
            labelCol={{ span: 6 }}
            label={
              <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                Business Name
              </Text>
            }
            rules={[{ required: true, message: 'This field is required' }]}>
            <FormInput className="input" />
          </FormItem>
          <FormItem
            mb="40px"
            name="businessEmail"
            labelAlign="left"
            labelCol={{ span: 6 }}
            label={
              <Text as="span" variant="sm" weight="medium" color="var(--grey-700)">
                Business email address
              </Text>
            }
            rules={[
              { required: true, message: 'This field is required' },
              { type: 'email', message: 'Invalid email' }
            ]}>
            <FormInput className="input" type="email" />
          </FormItem>
          <FlexibleDiv className="drag-section">
            <div className="ant-col ant-col-6">
              <Text variant="sm" weight="medium" color="var(--grey-700)">
                Business logo
              </Text>
              <Text variant="sm" className="description">
                We recommend you use a square logo with dimensions 100px by 100px for best results
                on checkout form
              </Text>
            </div>
            <div className="input">
              <FlexibleDiv flexGap="20px">
                {businessInfoImg && <img src={businessInfoImg} alt="profile" className="image" />}
                <Dragger {...draggerProps('business')} className="dragger">
                  <div className="ant-upload-drag-icon">
                    <UploadCloud />
                  </div>
                  <Text variant="sm" color="var(--grey-500)">
                    <Text as="span" variant="sm" weight="medium" color="var(--primary-500)">
                      Click to upload
                    </Text>{' '}
                    or drag and drop
                  </Text>
                  <Text variant="xs" color="var(--grey-500)">
                    SVG, PNG, JPG or GIF (max. 800x400px)
                  </Text>
                </Dragger>
              </FlexibleDiv>
            </div>
          </FlexibleDiv>
        </Form>
      </section>
    </StyledProfile>
  );
};

export default Profile;
