import styled from 'styled-components';

type TextVariants = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type FontWeight = 'bold' | 'semibold' | 'medium';

type ITextProps = {
  variant: TextVariants;
  mb?: string;
  underline?: boolean;
  color?: string;
  weight?: FontWeight;
};

export const textWeight = (weight: FontWeight) => {
  switch (weight) {
    case (weight = 'bold'):
      return 700;
    case (weight = 'semibold'):
      return 600;
    case (weight = 'medium'):
      return 500;
    default:
      return 400;
  }
};

const textVariant = (variant: TextVariants) => {
  switch (variant) {
    case (variant = 'xs'):
      return ['0.75rem', '1.125rem'];
    case (variant = 'sm'):
      return ['0.875rem', '1.25rem'];
    case (variant = 'md'):
      return ['1rem', '1.5rem'];
    case (variant = 'lg'):
      return ['1.125rem', '1.75rem'];
    case (variant = 'xl'):
      return ['1.25rem', '1.875rem'];
    default:
      return ['1rem', '1.5rem'];
  }
};

const Text = styled.p<ITextProps>`
  margin-bottom: ${({ mb }) => mb && mb};
  font-weight: ${({ weight }) => weight && textWeight(weight)};
  color: ${({ color }) => (color ? color : `var(--grey-900)`)};
  font-size: ${({ variant }) => textVariant(variant)[0]};
  line-height: ${({ variant }) => textVariant(variant)[1]};
  text-decoration: ${({ underline }) => underline && 'underline'};
`;

export default Text;
