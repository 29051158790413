import { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { flushSync } from 'react-dom';
import { CallBackProps, Step } from 'react-joyride';
import { message } from 'antd';
import ResetPasswordPage from './routes/ResetPasswordPage';
import ForgotPasswordPage from './routes/ForgotPasswordPage';
import SignInPage from './routes/SignInPage';
import SignUpPage from './routes/SignUpPage';
import DashboardPage from './routes/DashboardPage';
import SettingsPage from './routes/SettingsPage';
import CustomersPage from './routes/CustomersPage';
import AllSettingsPage from './routes/SettingsPage/AllSettingsPage';

import ConversationsPage from './routes/ConversationsPage';
import AuthProvider from './context/Auth';
import IndexPage from './routes';
import { SocketContextProvider } from './context/socketContext';
import IntegrationsPage from './routes/SettingsPage/IntegrationsPage';
import AddRolePage from './routes/SettingsPage/AddRolePage';
import ViewRolePage from './routes/SettingsPage/ViewRolePage';
import BroadcastPage from './routes/BroadcastPage';
import { checkNotificationPromise } from './utils/app';
import { HelmetProvider } from 'react-helmet-async';
import EarningsPage from './routes/EarningsPage';
import ProductsPage from './routes/ProductsPage';
import AllProducts from './routes/ProductsPage/AllProducts';
import AddProducts from './routes/ProductsPage/AddProducts';
import EditProducts from './routes/ProductsPage/EditProducts';
import useSetState from './hooks/useSetState';
import TourWrapper from './components/Tour';

function App() {
  const navigate = useNavigate();
  const [{ run, stepIndex, steps }, setTour] = useSetState({
    run: false,
    stepIndex: 0,
    steps: [] as Step[]
  });

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, lifecycle, type } = data;

    if (type === 'step:after' && index === 0) {
      setTour({ run: false, stepIndex: 1 });
      navigate('/settings/integrations');

      flushSync(() => {
        setTour({ run: true });
      });
    } else if (type === 'step:after' && index === 1 && action === 'prev') {
      setTour({ run: true, stepIndex: 0 });
    } else if (action === 'reset' || lifecycle === 'complete') {
      setTour({ run: false, stepIndex: 0 });
    }
  };

  const ROUTES = [
    { path: '/signin', element: SignInPage },
    { path: '/signup', element: SignUpPage },
    { path: '/forgot-password', element: ForgotPasswordPage },
    { path: '/reset-password', element: ResetPasswordPage }
  ];

  const props = {
    run,
    stepIndex,
    steps,
    callback: handleJoyrideCallback
  };

  const PROTECTEDROUTES = [
    {
      path: '/dashboard',
      element: <TourWrapper {...props} setTour={setTour} children={<DashboardPage />} />
    },
    {
      path: '/conversations',
      element: <TourWrapper {...props} setTour={setTour} children={<ConversationsPage />} />
    },
    {
      path: '/customers',
      element: <TourWrapper {...props} setTour={setTour} children={<CustomersPage />} />
    },
    {
      path: '/broadcast',
      element: <TourWrapper {...props} setTour={setTour} children={<BroadcastPage />} />
    },
    // {
    //   path: '/earnings',
    //   element: <EarningsPage />
    // },
    {
      path: '/products',
      element: <TourWrapper {...props} setTour={setTour} children={<ProductsPage />} />,
      children: [
        // { path: '', element: <AllProducts /> },
        // { path: 'add', element: <AddProducts /> },
        // { path: 'edit/:editId', element: <EditProducts /> }
      ]
    },
    // { path: "/earnings", element: <EarningsPage /> },
    {
      path: '/settings',
      element: <TourWrapper {...props} setTour={setTour} children={<SettingsPage />} />,
      children: [
        { path: '', element: <AllSettingsPage /> },
        { path: 'integrations', element: <IntegrationsPage /> },
        { path: 'add-role', element: <AddRolePage /> },
        { path: 'role/:roleId', element: <ViewRolePage /> }
        // { path: "diagflow-cx", element: <DiagflowCXPage /> },
        // { path: "diagflow-es", element: <DiagflowESPage /> },
      ]
    }
  ];

  message.config({ maxCount: 1 });

  useEffect(() => {
    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      console.log('This browser does not support notifications.');
    } else if (checkNotificationPromise()) {
      Notification.requestPermission().then();
    } else {
      Notification.requestPermission();
    }
  }, []);

  return (
    <AuthProvider>
      <SocketContextProvider>
        <HelmetProvider>
          <Routes>
            <Route path="/" element={<IndexPage />} />
            {ROUTES.map((elem) => (
              <Route key={elem.path} path={elem.path} element={<elem.element />} />
            ))}
            {PROTECTEDROUTES.map((elem) => {
              if (elem.children) {
                return (
                  <Route path={elem.path} key={elem.path} element={elem.element}>
                    {elem.children.map((child) => (
                      <Route key={child.path} path={child.path} element={child.element} />
                    ))}
                  </Route>
                );
              }
              return <Route key={elem.path} path={elem.path} element={elem.element} />;
            })}
          </Routes>
        </HelmetProvider>
      </SocketContextProvider>
    </AuthProvider>
  );
}

export default App;
