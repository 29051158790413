import { BusinessInfo, User } from './user';

export type OrderStatus = 'pending' | 'complete' | 'cancel';
export interface IMessage {
  attachments?: any[];
  id?: string;
  conversationId: string;
  messageBody: string;
  messageType: string;
  attachmentType?: string;
  attachment?: Array<any>;
  senderId: string;
  sentBy: string;
  status: string;
  messageId: string;
  direction: string;
  quickReplies?: string[];
  webMessage: string;
  isTemplate: boolean;
  updatedAt: string;
  createdAt: string;
  catalogueMessage?: boolean;
  orderStatus?: OrderStatus;
}

export interface IOrder {
  orderId: string;
  orderStatus?: OrderStatus;
  data: {
    order: {
      id: number;
      name: string;
      description: string;
      imageSrc: string;
      amount: number;
      quantity: number;
      currency: string;
    }[];
  };
}

export type conversationsParam = {
  perPage: number;
  page: number;
  channel: 'ALL' | Channels;
  status: 'ALL' | 'ASSIGNED' | 'UNASSIGNED' | 'OWN';
  range: string;
  search?: string;
};

export type SingleConversation = {
  limit: number;
  page: number;
};

export interface IGetAllConvosResponse {
  conversations: IConversation;
  current: number;
  total: number;
  pages: number;
}

export enum Channels {
  Facebook = 'FACEBOOK',
  Whatsapp = 'WHATSAPP',
  Instagram = 'INSTAGRAM',
  Web = 'WEB'
}

export interface IConversation {
  _id: string;
  businessId: string;
  conversationId: string;
  conversationChannel: Channels;
  conversationHandler: 'BOT' | 'HUMAN';
  conversationAssignedTo: string;
  conversationClosedBy: string;
  conversationHasBeenAssigned: boolean;
  conversationAcceptedOn: Date;
  customerId: string;
  conversationState: 'OPEN' | 'CLOSE';
}

export interface ICustomer {
  id: string;
  fullname: string;
  lastname: string;
  firstname: string;
  profilePicture: string;
  email?: string;
  integrationId: string;
  businessId: string;
  platformIdentifier: string;
  channel: string;
}

export interface IAggregatedConversation {
  read: boolean;
  _id: string;
  id: string;
  businessId: string;
  conversationId: string;
  conversationAssignedTo?: User;
  conversationHandler: 'HUMAN' | 'BOT';
  conversationChannel: Channels;
  conversationHasBeenAssigned: boolean;
  customerId: ICustomer;
  conversationState: 'OPEN' | 'CLOSED';
  createdAt: string;
  updatedAt: string;
  lastMessage: IMessage;
  __v: number;
  business: BusinessInfo;
}

export type AgentWithConvo = {
  _id: string;
  firstname: string;
  lastname: string;
  email: string;
  accountType: string;
  isOnline: boolean;
  createdAt: string;
  profilePicture?: '';
  conversations: [];
  conversationsCount: number;
};

export type IAssignedStats = {
  total: number;
  assigned: number;
  unassigned: number;
  assignedToMe: number;
};
