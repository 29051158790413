import {
  conversationsParam,
  IAggregatedConversation,
  IMessage,
  OrderStatus,
  SingleConversation
} from 'src/types/conversation';
import gatewayInstance from './axiosGateway';

export const getAllConversations = async (
  payload: conversationsParam
): Promise<{
  conversations: IAggregatedConversation[];
  total: number;
  pages: number;
  current: number;
}> => {
  const { data } = await gatewayInstance.get('/conversations', {
    params: payload
  });
  return data.data;
};

export const getSingleConversation = async (
  id: string,
  payload: SingleConversation
): Promise<{ currentPage: number; total: number; totalPages: number; messages: IMessage[] }> => {
  const { data } = await gatewayInstance.get(`/conversations/${id}`, {
    params: payload
  });
  return data.data;
};

export const getAgentsforAssign = async (search: string) => {
  const { data } = await gatewayInstance.get(
    `/users/agents-with-conversation?queryString=${search}`
  );

  return data;
};

export const getAssignedStats = async () => {
  const { data } = await gatewayInstance.get(`/conversations/get/stats`);
  return data;
};

export const updateOrderStatus = async (payload: {
  orderId: string;
  orderStatus: OrderStatus;
  messageId: string;
}) => {
  const { data } = await gatewayInstance.post('/whatsapp/order', payload);

  return data;
};
