import styled from 'styled-components';

const WhatsAppIntegrationModal = styled.section`
  display: flex;
  flex-direction: column;
  gap: 32px;

  pre {
    padding: 12px;
    color: var(--primary-900);
    font-size: 0.75rem;
    line-height: 1rem;
    background: var(--primary-50);
    border: 1px solid var(--primary-300);
    border-radius: 4px;
  }

  button {
    flex: 1;
    font-weight: 500;
    font-size: 1rem;
  }

  .btn.close {
    padding: 11px;
    line-height: 1.5rem;
    background: #ffffff;
    border: 1px solid var(--grey-300);
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
  }
`;

export default WhatsAppIntegrationModal;
