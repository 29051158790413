import styled from 'styled-components';

const StyledConversationsList = styled.div<{ showCustomerDetails: boolean }>`
  .tabs {
    position: sticky;
    top: 0;
    padding: 32px 12px 0 16px;
  }

  .tab-item {
    margin: 0;
    cursor: pointer;
    padding: 0 10px 16px;
    color: var(--grey-500);
    font-weight: 500;
  }

  .active-tab {
    margin: 0;
    cursor: pointer;
    padding: 0 10px 14px;
    font-weight: 500;
    color: var(--primary-500);
    border-bottom: 2px solid var(--primary-500);

    path {
      stroke: var(--primary-500);
    }
  }

  .channel-dropdown {
    cursor: pointer;
  }

  .search {
    padding: 8px;
    border-top: 1px solid var(--grey-200);
    border-right: 1px solid var(--grey-200);
  }

  .chat-list {
    overflow-y: auto;
    min-height: calc(100vh - 123px);
    max-height: calc(100vh - 167px);
    border-right: 1px solid var(--grey-200);

    @media (max-width: 1111px) {
      min-height: ${({ showCustomerDetails }) =>
        showCustomerDetails ? 'calc(100vh - 112px)' : 'calc(100vh - 123px)'};
    }
  }

  .recent-msg {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .chat-item {
    padding: 12px 16px;
    letter-spacing: -0.02em;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid var(--grey-200);

    &:first-child {
      border-top: 1px solid var(--grey-200);
    }

    &:hover {
      background-color: #f4f2ff87;
    }

    & p {
      margin-bottom: 8px;
      max-width: 296px;
    }

    & .assigned-agent {
      padding: 2px 10px;
      border-radius: 68px;
      background-color: var(--primary-50);
    }

    &.active {
      background-color: var(--primary-50);
    }
  }

  .chat-image {
    position: relative;

    & img {
      width: 44px;
      height: 44px;
      border-radius: 50%;
    }

    & svg {
      width: 14px;
      height: 14px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .chat-info {
    position: relative;
    flex-grow: 1;

    &.unread p {
      font-weight: 500;

      &:not(:first-child) {
        color: var(--grey-700);
      }
    }

    .unread-dot {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: var(--primary-600);
      position: absolute;
      top: 50%;
      right: -10px;
      transform: translateY(-50%);
    }
  }

  .assigned-agent {
    font-size: 0.625rem;
    line-height: 1.125rem;
    letter-spacing: -0.02em;
    color: #192733;
  }

  .noConvo {
    padding: 0 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 123px);
    border-top: 1px solid var(--grey-200);

    & p {
      text-align: center;
    }
  }
`;

export default StyledConversationsList;
