import styled from 'styled-components';
import { Form, Input } from 'antd';

type FormItemProps = {
  mb?: string;
};

type FormInputProps = {
  height?: string;
  width?: string;
  error?: boolean;
};

const FormItem = styled(Form.Item)<FormItemProps>`
  margin-bottom: ${({ mb }) => mb && mb};

  & .ant-form-item-explain-error {
    font-size: 0.75rem;
    line-height: 1rem;
    margin-top: 4px;
    margin-bottom: 19px;
    letter-spacing: -0.02em;
    color: var(--error-500);
  }
`;

const FormInput = styled(Input)<FormInputProps>`
  height: ${({ height }) => (height ? height : '44px')};
  border-radius: 8px;
  padding: 10px 14px;
  background-color: #fff;
  width: ${({ width }) => width};
  border: 1px solid var(--grey-300);
  border-color: ${({ error }) => error && 'var(--error-500)'};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  &:focus,
  &-focused {
    border-color: var(--primary-500);
    box-shadow: none;
  }

  &:hover {
    border-color: ${({ error }) => (error ? 'var(--error-500)' : 'var(--grey-300)')};
  }

  &:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
    box-shadow: none;
  }

  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: var(--grey-300);
  }

  &.ant-input-affix-wrapper-focused {
    border-color: var(--primary-500) !important;
  }

  &:focus-visible:not(.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover) {
    outline: none;
    border: 1px solid var(--primary-500);
  }

  &::placeholder {
    color: var(--grey-500);
  }
`;

export { FormItem, FormInput };
