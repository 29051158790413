import React, { useEffect } from 'react';
import Joyride, { CallBackProps, Step } from 'react-joyride';
import styled from 'styled-components';
import { getIntegrationDetails } from 'src/network/integrations';
import toastError from 'src/utils/toastError';
import { useAuth } from 'src/context/Auth';

type ITour = {
  run: boolean;
  stepIndex: number;
  steps: Step[];
  callback: (data: CallBackProps) => void;
};

const StyledTooltip = styled.div`
  display: flex;
  width: 360px;
  padding: 20px 12px;
  flex-direction: column;
  gap: 10px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 8px 64px 0px rgba(0, 0, 0, 0.12);

  .title {
    color: #1d2939;
    font-size: 1rem;
    letter-spacing: -0.02rem;
    margin-bottom: 6px;
  }

  .content {
    color: #667085;
    font-size: 0.8125rem;
    line-height: 1.25rem;
    letter-spacing: -0.00813rem;
    margin-bottom: 0;
  }

  .footer-area {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;

    .step {
      color: #667085;
      font-size: 0.8125rem;
      line-height: 1.25rem;
      letter-spacing: 0.15438rem;
    }

    button {
      width: 124px;
      padding: 8px 16px;

      color: #fff;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.25rem;

      border-radius: 6px;
      background: #7d5fff;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  }
`;

const Tour = ({ run, callback, stepIndex, steps }: ITour) => {
  return (
    <Joyride
      run={run}
      continuous
      hideCloseButton
      disableCloseOnEsc
      disableOverlayClose
      callback={callback}
      stepIndex={stepIndex}
      steps={steps}
      tooltipComponent={(props) => (
        <StyledTooltip>
          <div>
            <h4 className="title">{props.step.title}</h4>
            <p className="content">{props.step.content}</p>
          </div>
          <div className="footer-area">
            <span className="step">
              {props.index + 1}/{2}
            </span>
            <button {...props.primaryProps}>{props.index + 1 === 2 ? 'Finish' : 'Next'}</button>
          </div>
        </StyledTooltip>
      )}
    />
  );
};

const TourWrapper = ({
  run,
  callback,
  stepIndex,
  steps,
  setTour,
  children
}: ITour & { setTour: any; children: React.ReactNode }) => {
  const userContext = useAuth();

  useEffect(() => {
    (async () => {
      if (userContext?.user?.rolePermissions.can_manage_integrations) {
        try {
          let hasIntegration = false;
          const res = await getIntegrationDetails();

          Object.keys(res.data).forEach((key) => {
            if (res.data[key].createdAt) {
              hasIntegration = true;
            }
          });

          if (!hasIntegration) {
            setTour({
              run: true,
              stepIndex: 0,
              steps: [
                {
                  target: '#setting-tour',
                  spotlightPadding: 0,
                  placement: 'right',
                  disableBeacon: true,
                  spotlightClicks: true,
                  title: 'Welcome to VipiChat Integrations! 🚀',
                  content: 'To get started click on this icon initiate integration.'
                },
                {
                  target: '#integrations-tab',
                  spotlightPadding: 8,
                  placement: 'right',
                  disableBeacon: true,
                  spotlightClicks: true,
                  title: 'Integrate at least one channel',
                  content: 'Please integrate a channel you want to chat with customers from'
                }
              ]
            });
          }
        } catch (err) {
          toastError(err);
        }
      }
    })();
  }, []);

  return (
    <>
      {children}
      <Tour run={run} stepIndex={stepIndex} callback={callback} steps={steps} />
    </>
  );
};

export default TourWrapper;
