import { LogoText } from 'src/assets/svg/';
import StyledHeader from './styles';

const Header = () => {
  return (
    <StyledHeader>
      <LogoText />
    </StyledHeader>
  );
};

export default Header;
