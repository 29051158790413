import { Table } from 'antd';
import styled from 'styled-components';

type VPTableProps = {
  removeTop: boolean;
  noSelect: boolean;
};

const VPTable = styled(Table)<VPTableProps>`
  .action {
    padding: 8px;
    cursor: pointer;
  }

  .ant-table {
    border: 1px solid var(--grey-200);
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-top: ${({ removeTop }) => removeTop && 'none'};
    border-radius: ${({ removeTop }) => (removeTop ? '0 0 8px 8px' : '8px')};
  }

  .ant-table-selection-column {
    padding-left: 24px;
    padding-right: 0;
  }

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border-radius: 6px;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: var(--primary-500);
  }

  .ant-checkbox:hover::after,
  .ant-checkbox-wrapper:hover .ant-checkbox::after {
    visibility: hidden;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--primary-500);
    border-color: var(--primary-500);
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--primary-500);
  }

  & thead {
    & th {
      background: var(--grey-50);
      padding: 12px 0;
      color: var(--grey-500);
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 1.125rem;

      &:nth-child(2) {
        padding-left: ${({ noSelect }) => !noSelect && '16px'};
      }

      &:nth-child(1) {
        padding-left: ${({ noSelect }) => noSelect && '16px'};
      }

      &::before {
        content: none !important;
      }
    }
  }

  & tbody {
    .ant-checkbox-inner::after {
      left: 27.5%;
    }

    & .ant-table-row-selected > td,
    & .ant-table-row-selected:hover > td {
      background: var(--primary-50);
    }

    & > tr > td {
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: var(--grey-500);

      &:nth-child(1) {
        font-weight: ${({ noSelect }) => noSelect && 500};
        color: ${({ noSelect }) => noSelect && 'var(--grey-900)'};
      }

      &:nth-child(2) {
        font-weight: ${({ noSelect }) => !noSelect && 500};
        color: ${({ noSelect }) => !noSelect && 'var(--grey-900)'};
      }

      &:not(:nth-child(1)) {
        padding-left: ${({ noSelect }) => noSelect && 0};
      }

      &:not(:nth-child(1)):not(:nth-child(2)) {
        padding-left: ${({ noSelect }) => !noSelect && 0};
      }
    }
  }
`;

export default VPTable;
