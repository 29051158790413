import styled from 'styled-components';
import { Modal, ModalProps } from 'antd';

const Mdl = styled(Modal)`
  .ant-modal-content {
    padding: 24px;
    background: #ffffff;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
    border-radius: 12px;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-footer {
    padding: 0;
    text-align: left;
    background: inherit;
    border-top: none;
  }

  .upload-box {
    padding: 12px;
    margin-bottom: 20px;
    text-align: center;
    border-radius: 4px;
    background: var(--primary-50);
    border: 1px dashed var(--primary-300);

    .upload-text {
      color: var(--primary-500);
      font-weight: 500;
    }
  }
`;

const VPModal = (props: ModalProps) => {
  return <Mdl destroyOnClose={true} keyboard={true} maskClosable={true} {...props} />;
};

export default VPModal;
