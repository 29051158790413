import styled from 'styled-components';

const StyledQuickReplies = styled.section`
  .header {
    padding: 0 18px 12px;
    border-bottom: 1px solid var(--grey-200);
  }

  .title {
    font-weight: 500;
    letter-spacing: -0.04em;
    margin-bottom: 0;
  }

  .main {
    padding: 26px 18px 0;
  }

  form {
    margin-bottom: 20px;
  }

  .reply-list {
    overflow-y: auto;
    height: calc(100vh - 173px);
    border-radius: 8px;
    border: 1px solid var(--grey-200);
  }

  .reply-list li {
    cursor: pointer;
    background: var(--grey-50);
    padding: 10.5px 16px;

    &:first-child {
      border-radius: 8px 8px 0 0;
    }

    &:last-child {
      border-radius: 0 0 8px 8px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--grey-200);
    }
  }

  .reply-title {
    font-weight: 500;
    margin-bottom: 4px;
    color: var(--grey-900);
  }

  .reply-msg {
    color: var(--grey-500);
    margin-bottom: 0;
    letter-spacing: -0.01em;
  }
`;

export default StyledQuickReplies;
