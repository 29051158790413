import styled from 'styled-components';

const StyledNewBroadcast = styled.div`
  .broadcast-info {
    width: 100%;
    height: calc(100vh - 78px);
  }

  .textarea {
    padding: 12px;
    font-size: 0.75rem;
    line-height: 1rem;
    margin-bottom: 14px;
    color: var(--grey-700);
    background: var(--grey-50);
    border-radius: 4px;
    border: 1px solid var(--grey-300);
  }

  .btn-section {
    margin-bottom: 24px;
    border-bottom: 1px solid var(--grey-200);
  }

  .button {
    flex: 50%;
    cursor: not-allowed;
    padding: 6px 0;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid var(--grey-300);
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: var(--primary-500);
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  }

  .header-img {
    border: 1px solid #e4e7ec;
    border-radius: 5px;
    padding: 1px;
  }
`;

export default StyledNewBroadcast;
