import React from 'react';
import Header from 'src/components/Header';
import StyledAuthLayout from './styles';

type AuthScreensLayoutProps = {
  children: React.ReactNode;
};

const AuthScreensLayout = ({ children }: AuthScreensLayoutProps) => {
  return (
    <>
      <Header />
      <StyledAuthLayout>{children}</StyledAuthLayout>
    </>
  );
};

export default AuthScreensLayout;
