import React, { useContext, useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { flushSync } from 'react-dom';
import AuthenticatedLayout from 'src/layout/AuthenticatedLayout';
import ConversationsList from 'src/components/ConversationsList';
import MainConversation from 'src/components/MainConversation';
import FlexibleDiv from 'src/components/FlexableDiv';
import { Text } from 'src/components/Typography';
import EmptyChat from 'src/assets/images/empty-chat.svg';
import ConversationsPageStyles from './styled';
import { SocketContext } from 'src/context/socketContext';
import { Channels, IAggregatedConversation, IMessage } from 'src/types/conversation';
import { getAllConversations, getSingleConversation } from 'src/network/conversations';
import { ISingleConvo } from 'src/types';
import toastError from 'src/utils/toastError';
import Loading from 'src/components/Loading';
import StyledConvoList from 'src/components/ConvoList';
import useDebounce from 'src/hooks/useDebounce';

const ConversationsPage = () => {
  const [notificationSound] = useState(
    new Audio('https://assets.mixkit.co/sfx/download/mixkit-dry-pop-up-notification-alert-2356.wav')
  );

  const [showCustomerDetails, setShowCustomerDetails] = useState(false);
  const [activeConversation, setActiveConversation] = useState<string>('');
  const [allConversations, setAllConversations] = useState<IAggregatedConversation[]>([]);
  const [singleConversation, setSingleConversation] = useState<ISingleConvo>();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingConvoList, setLoadingConvoList] = useState(true);

  const [convoPage, setConvoPage] = useState(1);
  const [totalMsgs, setTotalMsgs] = useState<number>();
  const [total, setTotal] = useState<number>();
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const [activeTab, setActiveTab] = useState<'ALL' | 'ASSIGNED' | 'UNASSIGNED' | 'OWN'>('ALL');
  const [channel, setChannel] = useState<'ALL' | Channels>('ALL');

  const convListRef = useRef<HTMLDivElement>(null);
  const { socket } = useContext(SocketContext);

  const handleActiveConversation = async (chat: IAggregatedConversation) => {
    setActiveConversation(chat._id);

    const data = await getSingleConversation(chat._id, { limit: 35, page: 1 });
    let conversationStatus: boolean;
    conversationStatus = chat.read;

    if (!conversationStatus) chat.read = true;
    setTotalMsgs(data.total);
    setConvoPage(1);
    const newArr = [...data.messages].reverse();
    setSingleConversation({
      conversation: chat,
      // messages: data
      messages: [...new Map(newArr.map((item) => [item.id, item])).values()]
    });
  };

  const fetchConversations = async (
    debouncedSearch: string,
    activeConversation: string,
    channel: 'ALL' | Channels,
    tab: 'ALL' | 'ASSIGNED' | 'UNASSIGNED' | 'OWN'
  ) => {
    setLoadingConvoList(true);
    try {
      const res = await getAllConversations({
        page: 1,
        perPage: 25,
        channel: channel,
        status: tab,
        range: '',
        search: debouncedSearch.trim()
      });
      setTotal(res.total);
      setAllConversations(res.conversations.filter((a) => a.lastMessage));

      if (activeConversation) {
        const res = await getSingleConversation(activeConversation, { limit: 35, page: 1 });
        const convo = allConversations.find(
          (conversation) => conversation._id === activeConversation
        );
        if (convo) {
          const newArr = [...res.messages].reverse();

          setTotalMsgs(res.total);
          setConvoPage(1);
          setSingleConversation({ conversation: convo, messages: newArr });
        }
      }
    } catch (err) {
      toastError(err);
    } finally {
      setLoadingConvoList(false);
    }
  };

  useEffect(() => {
    fetchConversations(debouncedSearch, activeConversation, channel, activeTab);
  }, [debouncedSearch, activeTab, channel]);

  useEffect(() => {
    socket.on('NEW_ASSIGNED_CONVERSATION', ({ conversation, agent }: any) => {
      // socket.emit('joinConversation', { conversationId: conversation.id });

      if (agent) {
        setAllConversations((c) =>
          c.map((convo) => {
            if (convo._id === conversation.id) {
              convo.conversationAssignedTo = {
                accountType: agent.accountType,
                email: agent.email,
                firstname: agent.firstname,
                lastname: agent.lastname,
                _id: agent._id,
                accountIsActive: agent.accountIsActive,
                profilePicture: agent.profilePicture,
                id: agent._id,
                roleId: agent.roleId
              } as any;
            }
            return convo;
          })
        );
      }

      // socket.emit('FETCH_SINGLE_CONVERSATION_EVENT', {
      //   conversationId: conversation.id
      // });

      // socket.on('FETCHED_SINGLE_CONVERSATION_EVENT', (payload: IAggregatedConversation) => {
      //   const otherMessage = allConversations.filter(
      //     (existingConversation) => existingConversation._id !== conversation.id
      //   );
      //   const newConversation: IAggregatedConversation = {
      //     ...payload,
      //     customerId: payload.customerId,
      //     lastMessage: payload.lastMessage
      //   };

      //   const newData = [newConversation, ...otherMessage].sort(
      //     (a: IAggregatedConversation, b: IAggregatedConversation) =>
      //       new Date(b.lastMessage.createdAt).getTime() -
      //       new Date(a.lastMessage.createdAt).getTime()
      //   );

      //   setAllConversations(newData);
      // });
    });

    socket.on('NEW_MESSAGE_EVENT', (data: IMessage) => {
      const currentConversation = allConversations?.find(
        (conversation) => conversation._id === data.conversationId
      );

      if (!currentConversation) {
        socket.emit('FETCH_SINGLE_CONVERSATION_EVENT', {
          conversationId: data.conversationId
        });

        socket.on('FETCHED_SINGLE_CONVERSATION_EVENT', (payload: IAggregatedConversation) => {
          const otherMessage = allConversations.filter(
            (conversation) => conversation._id !== data.conversationId
          );

          const newConversation: IAggregatedConversation = {
            ...payload,
            customerId: payload.customerId,
            lastMessage: payload.lastMessage
          };

          const newData = [newConversation, ...otherMessage].sort(
            (a: IAggregatedConversation, b: IAggregatedConversation) =>
              new Date(b.lastMessage.createdAt).getTime() -
              new Date(a.lastMessage.createdAt).getTime()
          );

          setAllConversations(newData);
        });
      }

      if (currentConversation) {
        const otherMessage = allConversations?.filter(
          (conversation) => conversation._id !== data.conversationId
        );
        currentConversation.lastMessage = data;
        // currentConversation.conversationState = data.conversationState;
        currentConversation.conversationState = 'OPEN';
        if (activeConversation !== data.conversationId) {
          currentConversation.read = false;
        }

        const newData = [...otherMessage, currentConversation].sort(
          (a: IAggregatedConversation, b: IAggregatedConversation) =>
            new Date(b.lastMessage.createdAt).getTime() -
            new Date(a.lastMessage.createdAt).getTime()
        );
        setAllConversations([...newData]);

        if (currentConversation.lastMessage.direction === 'INBOUND') {
          notificationSound.play();
          new Notification(`New message from ${currentConversation.customerId.fullname}`, {
            body: currentConversation.lastMessage.messageBody
          });
        }

        if (activeConversation === data.conversationId) {
          if (singleConversation)
            setSingleConversation({
              ...singleConversation,
              messages: [...singleConversation?.messages, data]
            });
        }
      }
    });

    socket.on('NEW_CONVERSATION_EVENT', (conversation: IAggregatedConversation) => {
      setAllConversations((prev) => [conversation, ...prev]);
    });

    socket.on(
      'MARKED_CONVERSATION_AS_CLOSED_EVENT',
      (data: { conversationId: string; message: string }) => {
        setAllConversations((c) => {
          return c.map((conversation) => {
            if (conversation.conversationId === data.conversationId) {
              conversation.conversationState = 'CLOSED';
            }
            return conversation;
          });
        });
      }
    );

    socket.on(
      'UPDATE_MESSAGE_EVENT',
      (data: {
        _id: string;
        conversationId: string;
        messageBody: string;
        messageType: string;
        senderId: string;
        messageId: string;
        direction: string;
        isTemplate: false;
        createdAt: string;
        updatedAt: string;
        __v: number;
        status: string;
        id: string;
      }) => {
        setSingleConversation((c) => {
          if (c) {
            return {
              ...c,
              messages: c.messages.map((message) => {
                if (message.id === data.id) {
                  message.status = data.status;
                }
                return message;
              })
            };
          }
          return c;
        });
      }
    );

    socket.on(
      'MESSAGE_DELETED_EVENT',
      (data: {
        _id: string;
        conversationId: string;
        messageBody: string;
        messageType: string;
        senderId: string;
        messageId: string;
        direction: string;
        isTemplate: false;
        createdAt: string;
        updatedAt: string;
        __v: number;
        status: string;
        id: string;
      }) => {
        setSingleConversation((c) => {
          if (c) {
            return {
              ...c,
              messages: c.messages.map((message) => {
                if (message.id === data.id) {
                  message.status = 'DELETED';
                }
                return message;
              })
            };
          }
          return c;
        });
      }
    );

    socket.on('exception', (err: any) => {
      toastError('An error occured😞');
    });

    return () => {
      socket.off('exception');
      socket.off('NEW_MESSAGE_EVENT');
      socket.off('NEW_CONVERSATION_EVENT');
      socket.off('MARKED_CONVERSATION_AS_CLOSED_EVENT');
      socket.off('FETCHED_SINGLE_CONVERSATION_EVENT');
      socket.off('UPDATE_MESSAGE_EVENT');
      socket.off('MESSAGE_DELETED_EVENT');
    };
  }, [allConversations, singleConversation]);

  useEffect(() => {
    socket.on('MARKED_CONVERSATION_AS_READ_EVENT', (data: { conversationId: string }) => {
      setAllConversations((prev) =>
        prev.map((item) => {
          if (item.lastMessage.conversationId === data.conversationId) {
            item.read = true;
          }
          return item;
        })
      );
    });

    return () => {
      socket.off('MARKED_CONVERSATION_AS_READ_EVENT');
    };
  }, [socket]);

  useEffect(() => {
    const onScroll = async () => {
      let scrollTop = convListRef.current?.scrollTop as number;
      let clientHeight = convListRef.current?.clientHeight as number;
      let scrollHeight = convListRef.current?.scrollHeight as number;

      if (scrollTop + clientHeight >= scrollHeight) {
        if (allConversations.length < total!) {
          setLoading(true);
          try {
            const res = await getAllConversations({
              page: page + 1,
              perPage: 25,
              channel: 'ALL',
              status: activeTab,
              range: '',
              search: debouncedSearch.trim()
            });
            flushSync(() => {
              setPage(page + 1);
              setAllConversations((prevData) => [
                ...prevData,
                ...res.conversations.filter((a) => a.lastMessage)
              ]);
              setLoading(false);
            });
          } catch (err) {
            setLoading(false);
          }
        }
      }
    };

    let list = convListRef.current;
    list?.addEventListener('scroll', onScroll);

    return () => list?.removeEventListener('scroll', onScroll);
  }, [allConversations]);

  return (
    <AuthenticatedLayout>
      <ConversationsPageStyles>
        <Helmet>
          <title>Vipichat | Conversations</title>
        </Helmet>
        <StyledConvoList showCustomerDetails={showCustomerDetails}>
          <ConversationsList
            // debouncedSearch={debouncedSearch}
            loadingConvoList={loadingConvoList}
            setSearch={setSearch}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            channel={channel}
            setChannel={setChannel}
            convListRef={convListRef}
            list={allConversations}
            activeChat={activeConversation}
            handleActiveConversation={handleActiveConversation}
            showcustomerDetails={showCustomerDetails}
            setShowcustomerDetails={setShowCustomerDetails}
          />
          {loading && (
            <FlexibleDiv justifyContent="center" className="loading-area">
              <Loading />
            </FlexibleDiv>
          )}
        </StyledConvoList>
        {singleConversation ? (
          <MainConversation
            totalMsgs={totalMsgs}
            convoPage={convoPage}
            setConvoPage={setConvoPage}
            setSingleConversation={setSingleConversation}
            setAllConversations={setAllConversations}
            activeChat={singleConversation}
            showCustomerDetails={showCustomerDetails}
            setShowCustomerDetails={setShowCustomerDetails}
          />
        ) : (
          <section className="msgs">
            <div>
              <FlexibleDiv justifyContent="center">
                <img src={EmptyChat} alt="" />
              </FlexibleDiv>
              <Text variant="md" className="heading">
                No ongoing chats
              </Text>
              <Text variant="sm" className="select-text">
                Select a thread to continue a conversation
              </Text>
            </div>
          </section>
        )}
      </ConversationsPageStyles>
    </AuthenticatedLayout>
  );
};

export default ConversationsPage;
