import { IQuickReply } from 'src/types/quickReplies';
import gatewayInstance from './axiosGateway';

export type GetQuickRepliesPayload = {
  page?: number;
  perPage?: number;
  search: string;
};

export type CreateQuickReplyPayload = {
  name: string;
  message: string;
};

export type QuickRepliesRes = {
  current: number;
  total: number;
  pages: number;
  data: IQuickReply[];
};

export const getQuickReplies = async (params: GetQuickRepliesPayload) => {
  const { data } = await gatewayInstance.get('/quick-replies', {
    params
  });

  return data.data as QuickRepliesRes;
};

export const createQuickReply = async (payload: CreateQuickReplyPayload) => {
  const { data } = await gatewayInstance.post('/quick-replies', payload);
  return data;
};

export const editQuickReply = async (id: string, payload: CreateQuickReplyPayload) => {
  const { data } = await gatewayInstance.patch(`/quick-replies/${id}`, payload);
  return data;
};

export const deleteQuickReply = async (id: string) => {
  const { data } = await gatewayInstance.delete(`/quick-replies/${id}`);
  return data;
};
