import gatewayInstance from './axiosGateway';

export type CustomersPayload = {
  perPage?: number;
  page?: number;
  query: string;
  channel?: string;
};

export const getAllCustomers = async (payload: CustomersPayload) => {
  const { data } = await gatewayInstance.get('/customers', {
    params: payload
  });

  return data;
};

export const deleteCustomer = async (id: string) => {
  const { data } = await gatewayInstance.delete(`/customers/delete`, {
    data: {
      customerid: id
    }
  });

  return data;
};

export const updateCustomer = async (id: string, name: string) => {
  const { data } = await gatewayInstance.put(`/customers/update`, {
    customerId: id,
    fullname: name
  });

  return data;
};
