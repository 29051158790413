import React from 'react';
import { Navigate } from 'react-router-dom';

const IndexPage = () => {
  if (!localStorage.getItem('VP_TOKEN')) {
    return <Navigate to="/signin" />;
  }

  return <Navigate to="/dashboard" />;
};

export default IndexPage;
