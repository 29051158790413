import styled from 'styled-components';

const StyledTeam = styled.section`
  .text-capitalize {
    text-transform: capitalize;
  }
  .table-header {
    padding: 20px 24px;
    border: 1px solid #e4e7ec;
    border-radius: 8px 8px 0 0;
  }

  .user-number {
    padding: 2px 8px;
    border-radius: 16px;
    background: var(--primary-50);

    & span {
      color: var(--primary-500);
    }
  }

  .add-user {
    padding: 10px 16px;
    display: inline-flex;
    gap: 8px;
  }

  .button {
    cursor: pointer;
    padding: 9px 16px;
    color: var(--grey-700);
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-radius: 8px;
    background: #ffffff;
    border: 1px solid var(--grey-300);
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  }

  .team-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .actions {
    width: 96px;
  }
`;

export default StyledTeam;
