import React from 'react';
import { CloseIcon, Time, Location, Whatsapp } from 'src/assets/svg';
import FlexibleDiv from '../FlexableDiv';
import { Text } from '../Typography';
import StyledConversationsSidebar from './styled';
import { IAggregatedConversation } from 'src/types/conversation';

type ConvSidebarProps = {
  activeChat: IAggregatedConversation;
  setShowCustomerDetails: (val: boolean) => void;
};

const ConversationsSidebar = ({ setShowCustomerDetails, activeChat }: ConvSidebarProps) => {
  const initials = () => {
    let nameArr = activeChat.customerId.fullname.split(' ');
    return `${nameArr[0][0]}${nameArr[1][0]}`.toUpperCase();
  };

  return (
    <StyledConversationsSidebar>
      <FlexibleDiv flexGap="8px" alignItems="center" className="title-section">
        <div className="close" onClick={() => setShowCustomerDetails(false)}>
          <CloseIcon width={12} height={12} />
        </div>
        <Text variant="md" weight="medium" className="title">
          Customer details
        </Text>
      </FlexibleDiv>
      {activeChat.customerId.channel.toLowerCase() !== 'whatsapp' && (
        <section className="container sidebar-details">
          <section className="detail-sections">
            <FlexibleDiv flexGap="12px" mb="24px">
              <div className="initials">{initials()}</div>
              <div>
                <Text variant="sm" weight="medium" className="name">
                  {activeChat.customerId.fullname}
                </Text>
                <Text variant="sm" className="mail">
                  iamnasirudeen@gmail.com
                </Text>
              </div>
            </FlexibleDiv>
            <FlexibleDiv flexGap="8px" alignItems="center" mb="12px">
              <Time />
              <Text variant="xs" color="var(--grey-500)" mb="0">
                9.30 AM local time
              </Text>
            </FlexibleDiv>
            <FlexibleDiv flexGap="8px" alignItems="center" mb="20px">
              <Location />
              <Text variant="xs" color="var(--grey-500)" mb="0">
                Lagos, Nigeria
              </Text>
            </FlexibleDiv>
            <div className="map">
              <iframe
                title="map"
                width="100%"
                height="100%"
                frameBorder="0"
                scrolling="no"
                marginHeight={0}
                marginWidth={0}
                src={`https://maps.google.com/maps?q=lagos&z=12&output=embed`}
              ></iframe>
            </div>
          </section>
          <section className="detail-sections">
            <Text variant="md" weight="medium" className="title" mb="12px">
              Technology
            </Text>
            <Text variant="xs" className="tech-info">
              IP address:
              <span>102.89.39.89</span>
            </Text>
            <Text variant="xs" className="tech-info">
              OS/Device:
              <span>Mac OS (10.15.7)</span>
            </Text>
            <Text variant="xs" className="tech-info">
              Browser:
              <span>Chrome (104.0.0.0)</span>
            </Text>
            <Text variant="xs" className="tech-info">
              User agent:
              <span>
                Mozilla/5.0 (Macintosh; intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like
                Gecko) Chrome/104.0.0.0 Safari/537.36
              </span>
            </Text>
          </section>
          <section className="detail-sections">
            <Text variant="md" weight="medium" className="title" mb="12px">
              Visited pages
            </Text>
            <div>
              <Text variant="xs" className="tech-info">
                React
              </Text>
              <Text as="a" href="#" variant="xs" weight="medium" color="var(--primary-500)">
                https://meet.google.com/?authuser=1
              </Text>
            </div>
          </section>
        </section>
      )}
      {activeChat.customerId.channel.toLowerCase() === 'whatsapp' && (
        <section className="container sidebar-details">
          <section className="whatsapp-details">
            <FlexibleDiv justifyContent="center" mb="12px">
              <div>
                <img src={activeChat.customerId.profilePicture} alt="" className="profile-image" />
              </div>
            </FlexibleDiv>
            <Text variant="sm" weight="medium" className="whatsapp-name">
              {activeChat.customerId.fullname}
            </Text>
            <Text variant="xs" className="tech-info">
              Channel:
              <span>
                <Whatsapp width={16} height={16} />
              </span>
            </Text>
            <Text variant="xs" className="tech-info">
              Email:
              <span>iamnasirudeen@gmail.com</span>
            </Text>
            <Text variant="xs" className="tech-info">
              Phone number:
              <span>2348012345678</span>
            </Text>
            <Text variant="xs" className="tech-info">
              Gender:
              <span>Male</span>
            </Text>
            <Text variant="xs" className="tech-info">
              Timezone:
              <span>GMT +1</span>
            </Text>
          </section>
        </section>
      )}
    </StyledConversationsSidebar>
  );
};

export default ConversationsSidebar;
