import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Text } from 'src/components/Typography';
import useDebounce from 'src/hooks/useDebounce';
import Button from 'src/components/Button';
import VPCheckbox from '../VPCheckbox';
import FlexibleDiv from '../FlexableDiv';
import { FormInput } from '../Form';
import { CustomersPayload, getAllCustomers } from 'src/network/customers';
import { SendBroadcastPayload } from 'src/network/templates';
import toastError from 'src/utils/toastError';
import { ICustomer } from 'src/types/customers';
import { Chevron } from 'src/assets/svg';
import StyledSelectContacts from './styled';

type SelectContactsProps = {
  totalCustomers: number;
  setTotalCustomers: (val: number) => void;
  setActiveDrawer: (val: string) => void;
  setBroadcastPayload: React.Dispatch<React.SetStateAction<SendBroadcastPayload>>;
};

const SelectContacts = ({
  totalCustomers,
  setActiveDrawer,
  setBroadcastPayload,
  setTotalCustomers
}: SelectContactsProps) => {
  const [search, setSearch] = useState('');
  const [contactList, setContactList] = useState<ICustomer[]>([]);
  const debouncedSearch = useDebounce(search, 500);

  const [checkedList, setCheckedList] = useState<string[] | 'ALL'>([]);
  const [excludeList, setExcludeList] = useState<string[]>([]);

  const [indeterminate, setIndeterminate] = useState(false);

  const [selectContactForm] = Form.useForm();

  const fetchCustomers = async (params: CustomersPayload) => {
    try {
      const res = await getAllCustomers(params);
      if (!totalCustomers) {
        setTotalCustomers(res.data.total as number);
      }
      setContactList(res.data.data);
    } catch (error) {
      toastError(error);
    }
  };

  useEffect(() => {
    fetchCustomers({
      page: 1,
      perPage: 50,
      query: debouncedSearch.trim(),
      channel: 'WHATSAPP'
    });
  }, [debouncedSearch]);

  useEffect(() => {
    if (checkedList === 'ALL') {
      setIndeterminate(excludeList.length > 0 && excludeList.length < contactList.length);
    } else {
      setIndeterminate(checkedList.length > 0 && checkedList.length < contactList.length);
    }
  }, [checkedList, excludeList, contactList]);

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? 'ALL' : []);
    setExcludeList([]);
    setIndeterminate(false);
  };

  const submitSelectedContacts = async (values: any) => {
    const payload = {
      userIds: checkedList,
      excludeUserIds: excludeList
    };

    setBroadcastPayload((prev) => ({ ...prev, ...payload }));
    setActiveDrawer('broadcastSummary');
  };

  const selectedNumbers = () => {
    if (checkedList === 'ALL') {
      return totalCustomers - excludeList?.length;
    } else {
      return checkedList.length;
    }
  };

  return (
    <StyledSelectContacts>
      <div className="header">
        <Text mb="0" variant="sm" className="back" onClick={() => setActiveDrawer('newBroadCast')}>
          <Chevron />
          Back
        </Text>
        <Text variant="md" className="title" mb="0">
          Select target contacts
        </Text>
      </div>
      <FlexibleDiv
        flexDir="column"
        justifyContent="space-between"
        className="main-area select-contacts">
        <section className="upper">
          <FlexibleDiv flexGap="16px" alignItems="center" mb="12px">
            <FlexibleDiv flexGap="6px" alignItems="center">
              <Text variant="sm" weight="medium" mb="0">
                Select all
              </Text>
              <VPCheckbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkedList === 'ALL' ? true : false}
              />
            </FlexibleDiv>
            <FlexibleDiv flexGap="6px" alignItems="center">
              <Text variant="sm" weight="medium" mb="0">
                Selected:
              </Text>
              <Text variant="sm" weight="medium" mb="0" color="var(--primary-500)">
                {`${selectedNumbers()}`} customer(s)
              </Text>
            </FlexibleDiv>
          </FlexibleDiv>
          <>
            <form className="search">
              <FormInput
                placeholder="Search by name or number..."
                onChange={(e) => setSearch(e.currentTarget.value)}
              />
            </form>
            {contactList.length > 0 && (
              <Form
                name="selectContact"
                autoComplete="off"
                layout="vertical"
                requiredMark={false}
                form={selectContactForm}
                onFinish={submitSelectedContacts}>
                <div className="contact-list">
                  {contactList.map((person) => (
                    <VPCheckbox
                      key={person.id}
                      className="contact-checkbox"
                      checked={
                        checkedList === 'ALL'
                          ? !excludeList.includes(person.id)
                          : checkedList.includes(person.id)
                      }
                      onChange={(e) => {
                        if (checkedList === 'ALL') {
                          if (e.target.checked) {
                            setExcludeList((prev) => prev.filter((id) => id !== person.id));
                          } else {
                            setExcludeList((prev) => [...prev, person.id]);
                          }
                        } else {
                          if (e.target.checked) {
                            // @ts-ignore
                            setCheckedList((prev) => [...prev, person.id]);
                          } else {
                            // @ts-ignore
                            setCheckedList((prev) => prev.filter((id) => id !== person.id));
                          }
                        }
                      }}>
                      <div className="checkbox-label">
                        <span className="name">{person.fullname.replace('undefined', '')}</span>
                        <span className="number">{person.platformIdentifier}</span>
                      </div>
                    </VPCheckbox>
                  ))}
                </div>
              </Form>
            )}
          </>
        </section>
        <Button className="btn-con" fullWidth onClick={() => selectContactForm.submit()}>
          Continue
        </Button>
      </FlexibleDiv>
    </StyledSelectContacts>
  );
};

export default SelectContacts;
