import gatewayInstance from './axiosGateway';

export type WhatsappPayload = {
  client: string;
  channelId: string;
  apiKey?: string;
  whatsAppNumber?: string;
};

export const getIntegrationDetails = async () => {
  const { data } = await gatewayInstance.get('integrations/details?type=ALL');

  return data;
};

export const whatsappIntegration = async (payload: WhatsappPayload) => {
  const { data } = await gatewayInstance.post('/integrations/whatsapp', payload);

  return data;
};

export const gupshopWhatsApp = async (payload: { appName: string }) => {
  const { data } = await gatewayInstance.post('/integrations/gupshop', payload);

  return data;
};

export const getWhatsappNumber = async (payload: { apiKey: string }) => {
  const { data } = await gatewayInstance.get('/whatsapp/verifyNumber', {
    params: payload
  });

  return data;
};

export const metaIntegration = async (payload: { accessToken?: string }) => {
  const { data } = await gatewayInstance.post('/integrations/meta', payload);

  return data;
};

export const igIntegration = async (payload: { accessToken?: string }) => {
  const { data } = await gatewayInstance.post('/integrations/instagram', payload);

  return data;
};

export const whatsappRedirectURL = async () => {
  const { data } = await gatewayInstance.post('/whatsapp/redirect-url');

  return data;
};

export const webWidgetIntegration = async (payload: { password: string }) => {
  const { data } = await gatewayInstance.post('/integrations/web-widget', payload);

  return data;
};
