import styled from 'styled-components';

const StyledSendBroadcast = styled.div`
  .header {
    padding: 0 18px 12px;
    border-bottom: 1px solid var(--grey-200);
  }

  .title {
    font-weight: 500;
    letter-spacing: -0.04em;
    margin-bottom: 0;
  }

  .back {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 4px 4px 0;
    color: var(--grey-500);

    svg {
      rotate: 90deg;
    }
  }

  .main-area {
    padding: 26px 18px 0;
  }

  .select-contacts {
    height: calc(100vh - 103px);
  }

  .upper {
    width: 100%;
    margin-bottom: 12.5%;
  }

  .btn-con {
    padding: 8px 0;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  }

  .amount-input {
    display: flex;
    align-items: center;
  }

  .inputField {
    border-radius: 0px 8px 8px 0px;
  }

  .currency {
    align-self: stretch;
    padding: 10px 12px 8px 14px;
    border: 1px solid var(--grey-300);
    border-right: none;
    border-radius: 8px 0px 0px 8px;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: var(--grey-500);
  }
`;

export default StyledSendBroadcast;
