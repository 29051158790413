import styled from 'styled-components';

const StyledQuickReplies = styled.section`
  .new-reply {
    padding: 10px 16px;
    font-weight: 500;
  }

  .top {
    padding: 12px 24px;
    border: 1px solid var(--grey-200);
    border-radius: 8px 8px 0 0;
  }

  .actions {
    width: 96px;
  }
`;

export default StyledQuickReplies;
