import styled from 'styled-components';
import { Switch } from 'antd';

const VPSwitch = styled(Switch)`
  background: transparent;
  border: 2px solid var(--grey-300);
  border-radius: 140px;
  height: 16px;
  min-width: 34px;

  &.ant-switch-checked {
    border: 2px solid var(--primary-400);
  }

  &.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 10px);

    &::before {
      background: var(--primary-500);
    }
  }

  .ant-switch-handle {
    width: 7px;
    height: 7px;
    left: 3px;

    &::before {
      background: var(--grey-500);
      border-radius: 50%;
    }
  }
`;

export default VPSwitch;
