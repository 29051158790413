import React, { useContext, useState } from 'react';
import { WalletDetailsContext } from 'src/routes/BroadcastPage';
import { Text } from 'src/components/Typography';
import { Chevron } from 'src/assets/svg';
import FlexibleDiv from '../FlexableDiv';
import Button from 'src/components/Button';
import { sendBroadcast, SendBroadcastPayload } from 'src/network/templates';
import { currencyFormatter } from 'src/utils/app';
import StyledBroadcastSummary from './styled';
import toastError from 'src/utils/toastError';
import toast from 'src/utils/toasts';

type BroadcastSummaryProps = {
  onClose: () => void;
  totalCustomers: number;
  broadcastPayload: SendBroadcastPayload;
  setActiveDrawer: (val: string) => void;
};

const BroadcastSummary = ({
  onClose,
  totalCustomers,
  setActiveDrawer,
  broadcastPayload
}: BroadcastSummaryProps) => {
  const wallet = useContext(WalletDetailsContext);
  const [loading, setLoading] = useState(false);

  const totalRecipients = () => {
    if (broadcastPayload.userIds === 'ALL') {
      return totalCustomers - broadcastPayload?.excludeUserIds?.length!;
    } else {
      return broadcastPayload?.userIds?.length!;
    }
  };

  return (
    <StyledBroadcastSummary>
      <div className="header">
        <Text
          mb="0"
          variant="sm"
          className="back"
          onClick={() => setActiveDrawer('selectContacts')}>
          <Chevron />
          Back
        </Text>
        <Text variant="md" className="title" mb="0">
          Broadcast summary
        </Text>
      </div>
      <FlexibleDiv
        flexDir="column"
        justifyContent="space-between"
        className="main-area select-contacts">
        <section className="upper">
          <div>
            <div className="summary__flex">
              <Text variant="sm" className="key">
                Broadcast rate
              </Text>
              <Text variant="sm" className="value">
                {`1 broadcast = ${currencyFormatter.format(30)}`}
              </Text>
            </div>
            <div className="summary__flex">
              <Text variant="sm" className="key">
                Recipients
              </Text>
              <Text variant="sm" className="value">
                {totalRecipients()} customer(s)
              </Text>
            </div>
            <div className="summary__flex total">
              <Text variant="sm">Total</Text>
              <Text variant="sm">{currencyFormatter.format(30 * totalRecipients())}</Text>
            </div>
          </div>
          {wallet?.walletDetails?.walletBalance! < 30 * totalRecipients() && (
            <div className="insufficient">
              <Text variant="xs">
                You don’t have enough funds available (
                {currencyFormatter.format(wallet?.walletDetails?.walletBalance!)}) to send this
                broadcast ({currencyFormatter.format(30 * totalRecipients())})
              </Text>
              <Text
                variant="xs"
                className="topUpWallet"
                onClick={() => setActiveDrawer('topUpWalletDrawer')}>
                Top-up wallet
                <Chevron />
              </Text>
            </div>
          )}
        </section>
        <Button
          fullWidth
          loading={loading}
          className="btn-con"
          disabled={wallet?.walletDetails?.walletBalance! < 30 * totalRecipients()}
          onClick={async () => {
            try {
              setLoading(true);
              const res = await sendBroadcast(broadcastPayload);
              wallet?.setWalletDetails(res.data);
              toast('success', 'Broadcast has been sent and is on its way to your customers');
              onClose();
            } catch (err) {
              toastError(err);
            } finally {
              setLoading(false);
            }
          }}>
          Send broadcast
        </Button>
      </FlexibleDiv>
    </StyledBroadcastSummary>
  );
};

export default BroadcastSummary;
