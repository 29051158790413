import { TemplatesRes } from 'src/types/templates';
import gatewayInstance from './axiosGateway';

export type GetTemplatesPayload = {
  page: number;
  perPage: number;
  category?: string;
  name: string;
};

export type SendBroadcastPayload = {
  bodyVariables?: string[];
  headerUrl?: string;
  headerVariables?: string[];
  headerFormat?: string;
  name?: string;
  userIds?: string[] | 'ALL';
  excludeUserIds?: string[];
  language?: string;
};

export const getTemplates = async (params: GetTemplatesPayload) => {
  const { data } = await gatewayInstance.get('/broadcast', {
    params
  });

  return data.data as TemplatesRes;
};

export const sendBroadcast = async (payload: SendBroadcastPayload) => {
  const { data } = await gatewayInstance.post('/broadcast/send', payload);

  return data;
};
