import { Drawer } from 'antd';
import styled from 'styled-components';

type VPDrawerProps = {
  visible: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

const NewDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
    padding-top: 28px;
    padding-bottom: 10px;
  }

  .close-icon {
    cursor: pointer;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: var(--grey-100);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const VPDrawer = ({ visible, onClose, children }: VPDrawerProps) => {
  return (
    <NewDrawer
      width="476px"
      closable={false}
      keyboard={true}
      destroyOnClose={true}
      placement="right"
      visible={visible}
      onClose={onClose}>
      {children}
    </NewDrawer>
  );
};

export default VPDrawer;
