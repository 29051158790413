import styled from 'styled-components';
import { DateTime } from 'luxon';
import generatePicker, { RangePickerProps } from 'antd/es/date-picker/generatePicker';
import luxonGenerateConfig from 'src/utils/luxonGenerateConfig';
import FlexibleDiv from 'src/components/FlexableDiv';
import { Calendar, Chevron } from 'src/assets/svg';

const DatePicker = generatePicker<DateTime>(luxonGenerateConfig);

const { RangePicker } = DatePicker;

const Picker = styled(RangePicker)`
  --antd-wave-shadow-color: var(--primary-500);

  border-radius: 8px;
  padding: 8.5px 11px;
  border: 1px solid var(--grey-300);

  .ant-picker-input {
    width: 108px;
  }

  .ant-picker-range-separator {
    padding: 0;
    padding-right: 20px;
  }

  &:hover,
  .ant-picker-focused {
    border: 1px solid var(--primary-500);
  }

  &.ant-picker-focused {
    box-shadow: 0 0 0 2px #7d5fff20;
  }

  & .ant-picker-active-bar {
    background-color: var(--primary-500);
  }

  .ant-picker-input > input {
    color: var(--grey-500);
    font-size: 0.875rem;
    line-height: 0.875rem;
    letter-spacing: -0.03em;
  }

  & .ant-picker-clear {
    right: 36px;
  }
`;

const VPRangePicker = (props: RangePickerProps<DateTime> & { suffixIcon?: React.ReactNode }) => {
  return (
    <Picker
      prevIcon={<Chevron />}
      nextIcon={<Chevron />}
      superPrevIcon={
        <FlexibleDiv flexDir="column" alignItems="center">
          <Chevron />
          <Chevron />
        </FlexibleDiv>
      }
      superNextIcon={
        <FlexibleDiv flexDir="column" alignItems="center">
          <Chevron />
          <Chevron />
        </FlexibleDiv>
      }
      suffixIcon={<Calendar />}
      format="DD/MM/YYYY"
      {...props}
    />
  );
};

export default VPRangePicker;
